/**
* 被害状況の新規登録・詳細・続報登録画面用の基底モジュール。
* @module app/rescue/_RescuePageBase
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'idis/service/iframe',
    'dojo/topic',
    'dojo/window',
    'dojox/lang/functional/array',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/consts/STORAGE_KEY',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/model/RescueInfo',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'leaflet',
    '../config',
    'app/map/baselayer/BaseLayerPane',
    'esri-leaflet-geocoder',
    'exif-js/exif',
    '../draw/DrawPanel',
    'app/damage/consts/DamageType',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    '../view/form/LayerDirectorySelector',
    '../view/form/OrganizationSelector',
    '../view/form/CustomizableMunicipalitySelector',
    '../view/form/MunicipalitySelector'
], function (module, LayerStore, array, declare, lang, dojo, locale, domClass, domConstruct, domGeometry, domStyle,
    json, on, iframe, topic, winUtils, df, Rest, Menu, MenuItem, popup, TooltipDialog, registry, STORAGE_KEY,
    Locator, Router, IdisMap, UserInfo, RescueInfo, GeoService, Requester, FilesUtils, DialogChain, IdisDialog,
    DrawUtil, _PageBase, Loader, leaflet, config, BaseLayerPane, geocoder, exif, DrawPanel, DamageType, DisasterInfo) {
    // GeoServiceを初期化
    var _geoService = new GeoService({
        url: config.geocode && config.geocode.url
    });

    /**
     * 配信情報新規登録・詳細・続報登録画面画面。
     * @class _RescuePageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/rescue/_RescuePageBase~_RescuePageBase# */ {

            //作図ダイアログ
            drawPanel: null,
            drawClearPanel: null,

            DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
            JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
            JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
            DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
            DRAW_BY_JSON_COLOR: '/app/draw/DrawPanel::drawGeoJSONToLayerWithColor',
            CLEAR_LAYER: '/app/draw/DrawPanel::clearLayer',
            //イベントは破棄しない、全作図モードをOFFにする
            DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
            //mapに取り付けた全てのイベントを破棄する。
            DRAW_EVT_OFF: '/app/draw/DrawPanel::removeDrawAllEvent',
            // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
            DOWNLOAD_4IE: '/app/rescue/MapPage::download4IE',

            /**
            * popupイベントを保持する
            * @private
            */
            _downloadEvts: [],

            /**
             * 背景地図ダイアログに対する参照
             * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
             * @private
             */
            _baseLayerDialog: null,

            /**
             * 部隊活動ID
             * @private
             */
            _rescueId: null,

            /**
             * ID
             * @private
             */
            _Id: null,

            /**
             * 市町区コード
             * @private
             */
            _municipalityCd: null,

            /**
             * 続報番号
             * @private
             */
            _seqNum: null,

            /**
             * 外部管理番号
             * @private
             */
            _extAdmNum: null,

            /**
             * 被害種別
             * @private
             */
            _rescueType: null,

            /**
             * 発生場所 緯度
             * @private
             */
            _rescueAddressPointLat: null,

            /**
             * 発生場所 経度
             * @private
             */
            _rescueAddressPointLng: null,

            // レイヤーコントロール
            _layerControl: null,

            //地図中に提示する被害状況のレイヤー
            layerGroup: null,

            //災害ID         
            _disasterId: null,

            /**
             * constructor
             */
            constructor: function () {
                console.debug('_RescuePageBase:constructor()');

                this.chain = DialogChain.get(this);

                // 作図のポップアップイベントの保持
                this._downloadEvts = [];

                // 災害IDをRescueInfoから取得
                this._rescueId = RescueInfo.getRescueId();
                if (!this._rescueId) {
                    this._rescueId = 1;
                    console.warn('災害IDが設定させていません。');
                }

                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + RescueInfo.getRescueId());

                // URLから被害状況IDを取得
                this._rescueId = Locator.getQuery().rescueId;

                this.layerGroup = [];

                //災害IDをセット
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * startup
             */
            startup: function () {
                console.debug('start startup');

                this.inherited(arguments);
                console.debug('end startup');
            },

            /**
             * leave
             */
            leave: function () {
                //作図イベントを破棄
                topic.publish(this.DRAW_EVT_OFF);
                //一覧画面に移動
                Router.moveTo('report', { municipalityCd: this._municipalityCd });
            },

            /**
             * マップを初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = null;

                // //中心アイコンを非表示にする
                // LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');


                if (latitude && longitude) {
                    latlng = [latitude, longitude];
                } else {
                    latlng = [config.map.latitude, config.map.longitude];
                }

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    maxZoom: 18,
                    minZoom: 9,
                    drawControlTooltips: false
                }
                ).setView(latlng, 9);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function (e) {
                    // 作図ダイアログが閉じられているとき住所取得モードとする
                    if (this.drawPanel._ActiveMode === null) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                    }
                })
                ));

                // 作図パネルを生成
                this.createDrawPanel();
                topic.publish(this.DRAW_INIT_ID, this.map);
                topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function (args) {
                    this.drawJson = args;
                }));

                // 生成したmapのlayerControlを画面にセットする。
                this._layerControl = this.map.layerControl;

                // IE対応
                // イベントを管理する人は各Mapに必要。
                // TODO pub/subの方がよいか？
                if (DrawUtil._isIE()) { DrawUtil._setPopupEvtForMap(this); }
            },


            /**
             * 作図ペインを生成する。
             */
            createDrawPanel: function () {
                if (this.drawPanel === null) {
                    this.own(
                        this.drawPanel = new DrawPanel({
                            map: this.map,
                            'class': 'drawPanel-NonModal',
                            dispType: 'rescue'
                        }));
                }
            },

            /**
             * クリアペインを生成する。
             */
            createClearPanel: function () {
                if (this.drawClearPanel === null) {
                    this.own(
                        this.drawClearPanel = new DrawPanel({
                            map: this.map,
                            'class': 'drawPanel-NonModal',
                            dispType: 'rescue'
                        }),
                        this.drawClearPanel = this.CLEAR_LAYER
                    );
                }
            },

            /**
             * 作図ダイアログを表示する。
             */
            showDrawPanelDialog: function () {
                this.drawPanel.show();
            },

            /**
             * 活動エリア入力モードにする。
             */
            showActivityAreaDialog: function () {
                this.drawPanel.setTargetFunc('drawPanel-diagramButton');
                this.drawPanel.setTargetFunc('drawPanel-polygoneButton');
                this.drawPanel.show();
            },

            /**
             * 地図のクリア
             */
            showClearDialog: function () {
                //this.createClearPanel();
                //this.map.removeLayerById(this._layerControl._Id);
                this.drawPanel.setSecondlyEditArea('deleteAllActiveArea');
            },

            /**
             * 被害情報を地図に描画する
             */
            setDamageReportMarkers: function (items) {
                this.layerGroup = new leaflet.markerClusterGroup({
                    iconCreateFunction: function (cluster) {
                        var markers = cluster.getAllChildMarkers();
                        var humanDamageFlg = false;
                        dojo.some(markers, function (marker) {
                            if (marker.humanDamageFlg === '0') {
                                humanDamageFlg = true;
                                return false;
                            }
                        });
                        var color = 'rgba( 85, 176, 250, 0.7 )';
                        if (humanDamageFlg) {
                            color = 'rgba( 240, 105, 60, 0.7 )';
                        }
                        var radius = Math.min(cluster.getChildCount() * 10, 4 * 10);
                        var html = '';
                        html += '<div class="leaflet-marker-icon marker-cluster ';
                        html += 'marker-cluster-small leaflet-zoom-animated leaflet-clickable"';
                        html += 'style="margin-left: ' + (50 - radius) + 'px; margin-top: ' + (50 - radius) + 'px;';
                        html += 'font-size: ' + radius + 'px;';
                        html += 'line-height: ' + (radius * 2) + 'px;';
                        html += 'background-color: ' + color + ';';
                        html += 'width: ' + (radius * 2) + 'px; height: ' + (radius * 2) + 'px;';
                        html += 'border-radius:' + radius + 'px;';
                        html += '"><span>' + cluster.getChildCount() + '</span></div>';

                        return leaflet.divIcon({
                            html: html,
                            className: 'marker-cluster',
                            iconSize: new leaflet.Point(100, 100)
                        });
                    }
                });

                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    var lat = item.latitude;
                    var lng = item.longitude;
                    var marker = [];

                    if (lat && lng) {
                        // 概況画面では対応状況が2桁で取得されてしまうので、切り出し
                        item.hldStatus = item.hldStatus.slice(0, 1);
                        marker = this.makeDamageMarker(item);
                        //ポップアップ
                        var content = '';
                        if (item.damageType) {
                            content = '<tr><td style="color:#FF0000"><b>被害位置</b></td></tr>';
                            content += '<tr><td></td></tr>';
                            content += '<tr><td>管理番号:</td><td>' + item.admNum + '</td></tr>';
                            content += '<tr><td>被害種別:</td><td>' + DamageType[item.damageType] + '</td></tr>';
                            if (item.damageAddress) {
                                content += '<tr><td>住所:</td><td>' + item.damageAddress + '</td></tr>';
                            }

                            if (item.hldStatus !== null) {
                                var hldStatusMap = {
                                    '0': '確認中',
                                    '1': '対応待ち',
                                    '2': '対応中',
                                    '3': '対応済'
                                };
                                content += '<tr><td>対応状況:</td><td>' + hldStatusMap[item.hldStatus] + '</td></tr>';
                            }

                            if (item.urgencyType !== null) {
                                var urgencyTypeMap = {
                                    '0': '-',
                                    '1': '低',
                                    '2': '中',
                                    '3': '高'
                                };
                                content += '<tr><td>緊急度:</td><td>' + urgencyTypeMap[item.urgencyType] + '</td></tr>';
                            }

                            if (item.humanDamageFlg) {
                                var humanDamage;
                                if (item.humanDamageFlg === '0') {
                                    humanDamage = 'あり';
                                } else {
                                    humanDamage = 'なし';
                                }
                                content += '<tr><td>人的被害:</td><td>' + humanDamage + '</td></tr>';
                            }

                            content += '<tr><td></td></tr>';

                        } else {
                            if (item.description) {
                                content += '<tr><td>' + item.content + '</td></tr>';
                            }
                        }
                        marker.humanDamageFlg = item.humanDamageFlg;
                        if (content) {
                            marker.bindPopup('<table>' + content + '</table>', { maxWidth: 1000, closeButton: false });
                        }
                        this.layerGroup.addLayer(marker);
                    }
                }
            },

            /**
             * 「被害情報レイヤー表示」ボタンによって、地図中のアイコンの表示有無を切り替える
             */
            showDamageReportMarkers: function () {
                if (this.showMarkersFlg) {
                    this.map.removeLayer(this.layerGroup);
                    domStyle.set(this.showMarkersButton, 'background-color', 'rgba(0, 0, 0, 0.7)');
                    this.showMarkersFlg = false;

                } else {
                    var param = '?disasterId=' + this._disasterId;
                    param += '&activeFlg=1&hasNoParentFlg=0';
                    Requester.get('/api/damageReports' + param, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: false
                    }).then(lang.hitch(this, function (data) {
                        var items = data.items;
                        this.setDamageReportMarkers(items);
                        //被害はデフォルト表示
                        this.map.addLayer(this.layerGroup);
                        domStyle.set(this.showMarkersButton, 'background-color', 'rgba(100, 100, 100, 0.7)');
                        this.showMarkersFlg = true;
                    }));
                }
            },

            /**
             * 入力値の妥当性をチェックする。
             */
            validateForm: function (data) {
                if (!data.reportTimestamp || data.reportTimestamp === '') {
                    this.chain.info('報告日時を入力してください。', '入力エラー');
                    return false;
                }
                if (!data.activityStart || data.activityStart === '') {
                    this.chain.info('活動開始日時を入力してください。', '入力エラー');
                    return false;
                }
                return true;
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。（発生場所）
             */
            mapToRescueAddress: function () {
                console.log(this);
                this.mapToAddress(this.activityArea);
                this._rescueAddressPointLat = this.pointLat;
                this._rescueAddressPointLng = this.pointLng;
            },

            /**
             * 住所の位置を地図上にポイントします。（発生場所）
             */
            rescueAddressToMap: function () {
                this.addressToMap(this.activityArea, true);
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。（通達者住所）
             */
            mapToNotifierAddress: function () {
                this.mapToAddress(this.notifierAddress01);
            },

            /**
             * 住所の位置を地図上にポイントします。（通達者住所）
             */
            notifierAddressToMap: function () {
                this.addressToMap(this.notifierAddress01, false);
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。
             */
            mapToAddress: function (address01) {
                if (this.pointLat === '' || this.pointLng === '') {
                    console.debug('not pointed map');
                    this.chain.info('地図が選択されていません。', 'エラー');
                    return;
                }

                _geoService.reverseGeocode(leaflet.latLng({
                    lat: this.pointLat,
                    lng: this.pointLng
                })).then(lang.hitch(this, function (res) {
                    this.addMark(this.pointLat, this.pointLng);
                    address01.set('value', res.address.Address);
                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function (address01, isRescue) {
                console.debug('start geocoding (address: ' +
                    address01.value + ')');

                if (!address01.value) {
                    console.log('not input address');
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                var address = address01.value;
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng);
                        this.map.setView(latlng, 11);
                        if (isRescue) { //被害発生住所を変換する場合のみ、別の変数に緯度経度情報を保持させる
                            this._rescueAddressPointLat = this.pointLat;
                            this._rescueAddressPointLng = this.pointLng;
                        }
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                    console.debug('end reverse geocoding (latitude: ' +
                        this.pointLat + ', longitude: ' + this.pointLng + ')');
                })
                );

            },

            /**
             * 住所の位置から緯度経度を求める。
             */
            addressToLatLng: function (address) {
                console.debug('start geocoding');
                return _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        this._rescueAddressPointLat = results[0].latlng.lat;
                        this._rescueAddressPointLng = results[0].latlng.lng;
                    } else {
                        console.debug('address is not correct');
                        //this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));
            },

            /**
             * マーカーを追加する。
             */
            addMark: function (lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function () {
                // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                if (this.attachFile._files.length === 0 ||
                    !FilesUtils.isAttachFile(this.attachFile)) {
                    return;
                }

                console.log('file change');
                var self = this;

                this.attachFile.set('disabled', false);

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/rescue/uploadFile', {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(function (data) {
                    console.log(data);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.attachFileList.push(data);
                    self.showPreview(data, true);
                }, function (error) {
                    console.log(error);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.chain.infoError(error);
                });

                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 添付ファイルのプレビューを表示する。
             */
            showPreview: function (data, exifFlg) {

                var dataUri = data.attachFilePath.replace('out/', 'data/');
                var fileName = data.attachFileName;
                var fileId = data.AtcFileId;
                var self = this;

                // 画像ファイルの場合
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    var image = new Image();

                    //JPEGファイルの場合、EXIFデータの取得を実行する
                    if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                        var img = null;
                        this.own(on(image, 'load', lang.hitch(this, function (e) {
                            img = e.target;

                            if (exifFlg) {
                                this.getExifData(img, this);
                            }
                        })));
                    }
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview');
                    domConstruct.place(image, this.preview);
                    //メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, self);

                } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview');
                    domConstruct.place(excel, this.preview);
                    //メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.pdf') !== -1) {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview');
                    domConstruct.place(pdf, this.preview);
                    //メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview');
                    domConstruct.place(word, this.preview);
                    //メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, self);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview');
                    domConstruct.place(other, this.preview);
                    //メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, self);
                }
            },

            /**
         * UTMグリッドの表示を切り替える。
         */
            toggleUtmLayers: function () {
                var isActive = this.map.toggleUtmLayers();
                domClass.toggle(this.utmGridButton, 'is-checked', isActive);
            },

            /**
             * JPEGファイルの位置情報を取得する
             */
            getExifData: function (img, self) {
                console.log('getting exif data start');
                exif.getData(img, function () {

                    var latitude = exif.getTag(this, 'GPSLatitude');
                    var longitude = exif.getTag(this, 'GPSLongitude');

                    if (typeof latitude === 'undefined' || typeof longitude === 'undefined') {
                        console.log('GPS data is unavailable.');
                    } else {
                        console.log('GPS data is available.');
                        var f = function (number) {
                            return number[0].numerator + number[1].numerator /
                                (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                        };
                        self.chain.confirm('この画像の位置情報を使用しますか？', function (chain) {
                            // 位置情報を設定する
                            self.pointLat = f(latitude);
                            self.pointLng = f(longitude);
                            // 地図にマークして中心に移動する
                            self.addMark(self.pointLat, self.pointLng, self);
                            self.map.setView([self.pointLat, self.pointLng], 11);
                            //ダイアログを閉じる
                            chain.hide();
                        });
                    }
                });
            },

            /**
             * 添付ファイルのサムネイル上にメニューを作る
             */
            createMenu: function (newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

                //ダウンロード操作用
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', { href: '#' });
                    //クリックでファイル取得処理に入る
                    download.onclick = function () {
                        self.downloadFile(url, fileName);
                    };
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                }

                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                //メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function () {
                    popup.close(tooltip);
                };

                // 画像ファイルの場合のみ'開く'をメニューに追加する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    menu.addChild(new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function () {
                            console.log('file open');
                            window.open(uri);
                        }
                    }));
                }

                menu.addChild(new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function (e) {
                        console.log('file download');
                        console.log(e);
                        //IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                }));

                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function () {
                        console.log('file delete');

                        // 該当ファイルを削除
                        for (var i = 0; i < self.attachFileList.length; i++) {
                            if (self.attachFileList[i].AtcFileId === id) {
                                self.attachFileList.splice(i, 1); //id:3の要素を削除
                            }
                        }

                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }
                }));

                menu.startup();
                //メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function () {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['below-centered']
                    });
                })));
                //画面破棄時に一緒に破棄する
                this.own(tooltip);
            },

            /**
             * 添付ファイルをダウンロードする。
             */
            downloadFile: function (url, name) {
                // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {

                    var arrayBuffer = this.response;

                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    }

                };
                xhr.send();

                return false;

            },

            /**
             * プレビューをクリアする
             **/
            clearPreview: function () {

                var length = this.preview.childNodes.length;
                for (var i = 0; i < length; i++) {
                    domConstruct.destroy(this.preview.childNodes[0]);
                }

                if (this._attachFileList) {
                    this._attachFileList.splice(0, this._attachFileList.length);
                }
            },

            setLayerName: function (obj) {
                var currentDate = new Date();
                var prefix = locale.format(currentDate, {
                    selector: 'date',
                    formatLength: 'long',
                    locale: 'zh'
                });
                obj.layerName = prefix + '被害';
            },

            /**
                 * 背景地図ダイアログを表示する。
                 */
            showBaseLayerDialog: function () {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({ map: this.map })
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            },

            /**
              * 被害情報レイヤー用のマークを追加
              **/
            makeDamageMarker: function (item) {
                var lat = item.latitude;
                var lng = item.longitude;
                var lifeType = {
                    'elec1': item.elecMax,
                    'elec2': item.elecCurr,
                    'water1': item.watRedMax,
                    'water2': item.watRedCurr,
                    'water3': item.watFailMax,
                    'water4': item.watFailCurr,
                    'gas1': item.gusMax,
                    'gas2': item.gusCurr,
                    'phone1': item.telMax,
                    'phone2': item.telCurr
                };
                var lifeTypeNum = {};
                var type;
                var beforeKey = null;
                if (!lat || !lng) {
                    return false;
                }

                //レコードの値から、imgファイル名上の値に変換する
                var urgencyType = '1';
                if (item.urgencyType !== null && item.urgencyType !== '') {
                    urgencyType = item.urgencyType;
                }
                var hldStatus = '01';
                if (item.hldStatus !== null && item.hldStatus !== '') {
                    if (item.hldStatus === '1' || item.hldStatus === '2') {
                        hldStatus = '02';
                    } else if (item.hldStatus === '3') {
                        hldStatus = '03';
                    }
                }
                var humanDamageFlg = item.humanDamageFlg;

                //メインアイコン
                var mainIcon = '/data/images/draw/icon/080.png';
                var html = [];
                var leftPosition = 0;
                if (item.damageType && (item.urgencyType || item.urgencyType === '0') && item.damageTypeList) {
                    for (var key in lifeType) {
                        if (key) {
                            //lifeType[key] に1以上の数字が入っている場合
                            if (!this.isEmpty(lifeType[key])) {
                                //最初のkeyの場合
                                if (key === 'elec1') {
                                    lifeTypeNum[key] = lifeType[key];
                                }
                                // 2つ目以降のkeyの場合
                                // beforeKeyとkeyが同じ && 1つ前のlifeType[beforeKey]に値が入っていない場合
                                else if (key.slice(0, -1) === beforeKey.slice(0, -1) &&
                                    this.isEmpty(lifeType[beforeKey])) {
                                    lifeTypeNum[key] = lifeType[key];
                                }
                                //beforeKeyとkeyが異なる場合
                                else if (key.slice(0, -1) !== beforeKey.slice(0, -1)) {
                                    lifeTypeNum[key] = lifeType[key];
                                }
                            }
                            beforeKey = key;
                        }
                    }
                    array.forEach(item.damageTypeList.split(','), function (damageType, i) {
                        //被害種別がライフラインの場合、被害箇所のiconを設定する
                        if (damageType === '02' && Object.keys(lifeTypeNum).length === 1) {
                            for (var key2 in lifeTypeNum) {
                                if (key2) {
                                    type = key2.slice(0, -1);
                                    mainIcon = '/images/damage/type_' + damageType + '_' +
                                        type + '_urg_' + item.urgencyType + '.png';
                                }
                            }
                        }
                        else {
                            mainIcon = '/images/damage/type_' + damageType + '_urg_' + item.urgencyType + '.png';
                        }
                        leftPosition = 20 * i;
                        html += '<img src="' + mainIcon + '" height="35px" width="35px" style="position:absolute;top:' +
                            0 + 'px;left:' + leftPosition + 'px;">';
                    });

                }
                // if(damageType){
                //     mainIcon = '/images/damage/type_' + damageType + '_urg_' + urgencyType + '.png';
                // }

                //人的被害アイコン
                var humanDamageIcon = '/images/damage/hmDmg_0.png';
                var leftFlgPosition = leftPosition + 18;
                var leftStatusPosition = leftPosition + 20;
                var iconSize = 20;
                if (humanDamageFlg !== null) {
                    humanDamageIcon = '/images/damage/hmDmg_' + humanDamageFlg + '.png';
                    iconSize = (humanDamageFlg === '0') ? 20 : 12;
                }
                html += '<img src="' + humanDamageIcon + '" height="' + iconSize + 'px" width="' + iconSize + 'px"';
                html += ' style="position:absolute;top:15px;left:' + leftFlgPosition + 'px;margin:2px">';

                //対応状況アイコン
                var hldStatusIcon = '/images/damage/status_' + hldStatus + '.png';
                html += '<img src="' + hldStatusIcon + '" height="12px" width="12px"';
                html += ' style="position:absolute;top:-4px;left:' + leftStatusPosition + 'px;">';

                // var damageTypeList = item.damageTypeList.split(',');
                // var anchorX = this.calAnchorX(cnt, damageTypeList);
                // var anchorY = this.calAnchorY(cnt, damageTypeList);

                var divIcon = leaflet.divIcon({
                    html: html,
                    className: 'damage-point',
                    iconSize: [0, 0],
                    iconAnchor: [3, 3]
                });

                var marker = leaflet.marker([lat, lng], {
                    icon: divIcon
                });

                return marker;
            },

            isEmpty: function (str) {
                if (str === void 0) {
                    return true;
                }
                if (str === null) {
                    return true;
                }
                if (str === '') {
                    return true;
                }
                if (str === '0') {
                    return true;
                }
                return false;
            },

            /**
             * 部隊活動概況一覧画面へ遷移する。
             */
            onRescuePageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('rescue');
            }
        });
});
