/**
 * 招集状況詳細画面用モジュール。
 *
 * @module app/convocation/view/mail/ConvoStatusListPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/text!./templates/NotificationListPage.html',
        'idis/view/dialog/DialogChain',
        'idis/view/page/_PageBase',
        'idis/view/dialog/InfoDialog',
        'idis/control/Router',
        'idis/store/IdisRest',
        'idis/service/Requester',
        'idis/control/Locator',
        'idis/view/Loader',
        'dojo/Deferred',
        'dojo/json',
        'idis/model/UserInfo',
        'idis/consts/ACL',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/view/form/EmployeeDivisionSelector',
        'app/convocation/view/mail/NotificationListGrid',
        'app/convocation/view/mail/ConvoDeputyAnswerDialog'
    ], function(module, declare, lang, locale, domStyle, template, DialogChain, _PageBase, InfoDialog,
                 Router, IdisRest, Requester, Locator, Loader, Deferred, JSON, UserInfo, ACL) {
    /**
     * 招集状況詳細画面。
     *
     * @class ConvoStatusListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/convocation/view/mail/ConvoStatusListPage# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        /**
         * グローバル変数の初期化を行うメソッド。
         */
        constructor: function() {
            this.distributionId = Locator.getQuery().mid;
            this.groupCd = Locator.getQuery().gcd;
            this.divisionCd = Locator.getQuery().dcd;
            var url = null;
            if (this.groupCd) {
                url = '/api/convoMails/mails/list/' + this.distributionId + '/' + this.groupCd;
            } else {
                url = '/api/convoMails/mails/list/' + this.distributionId + '/division/' + this.divisionCd;
            }
            // データ格納用オブジェクト
            this.store = new IdisRest({
                // TODO:適切なidPropertyにする
                idProperty: 'distributionHistDetailId',
                target: url
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 件名、本文、配信時刻を設定する
            this.initForm();
            this.initConvoStatusListGrid();
        },

        /**
         * 一覧画面上部のコンテンツを生成するためのメソッド
         */
        initForm: function(){
            var url = null;
            if (this.groupCd) {
                url = '/api/convoMails/mails/' + this.distributionId + '/' + this.groupCd;
            } else {
                url = '/api/convoMails/mails/' + this.distributionId + '/division/' + this.divisionCd;
            }
            Requester.get(url).then(lang.hitch(this,function(data) {
                if (data.autoFlg){
                    this.sender.innerHTML = '自動送信';
                } else {
                    this.sender.innerHTML = '手動送信';
                }
                if (typeof data.distributeTimeStamp !== 'undefined' && data.distributeTimeStamp !== null) {
                    // distributeTimeStampがnullかundefinedではない場合フォーマットを変える
                    var date = locale.parse(data.distributeTimeStamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.distributeTimeStamp.innerText = locale.format(date, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm:ss'
                    });
                } else {
                    this.distributeTimeStamp.innerText = '-';
                }
                this.title.innerHTML = data.distributionTitle;
                this.body.innerHTML = data.distributionBody;

                this.ConvoStatusListGrid.renderHeader();
            }));

            var role = UserInfo.getRoleCd();
            this.msg.hidden = true;
            if (role !== ACL.ADMIN_USER && role !== ACL.SYSMGR_USER) {
                this.msg.hidden = false;
            }
        },

        /**
         * Gridの初期化を行うメソッド
         */
        initConvoStatusListGrid: function() {
            this.ConvoStatusListGrid.set('collection', this.store);
        },

        /**
         * @param evt ボタンクリックイベント
         * パンくずリスト（概況画面）が押された際に呼ばれるメソッド
         */
         onConvoStatusDetailLinkClick : function(evt) {
             // ブラウザーの遷移処理をキャンセル
             evt.preventDefault();
             Router.moveTo('convocation/notificationDetail', {
                 id: this.distributionId,
                 display: (this.groupCd)?'group':'division'
             });
         },

        /**
         * 帳票出力ボタンが押された際に呼ばれるメソッド
         */
        outputListExcel: function() {
            console.log('[帳票出力]ボタンがクリックされました。');

            // 確認ダイアログを表示
            this.chain.confirm('一覧に表示されているデータを出力します。<br>' +
                'よろしいですか？', function(chain) {
                // 確認ダイアログでOKを押した場合
                chain.hide();

                var reqid = 'EMP_DISTRIBUTION_LIST_EX';
                var fileName = 'EmpDistributionList.xlsx';
                var fileNameJp = '招集状況一覧.xlsx';
                var deptCd = '';
                var sectCd = '';
                var role = UserInfo.getRoleCd();
                if (role !== ACL.ADMIN_USER && role !== ACL.SYSMGR_USER) {
                    deptCd = UserInfo.getOrganization().deptCd;
                    sectCd = UserInfo.getOrganization().sectCd;
                }

                // 連絡先出力可否フラグ
                var contactOutputFlg = 0;
                // ログインユーザの役割がシステム管理、または、県・危機管理・管理の場合
                if (role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER) {
                    // 連絡先出力可否フラグを立てる
                    contactOutputFlg = 1;
                }

                var paramList = [
                    {key: 'distId', value: this.distributionId},
                    {key: 'grpId', value: this.groupCd},
                    {key: 'divisionCd', value: this.divisionCd},
                    {key: 'deptCd', value: deptCd},
                    {key: 'sectCd', value: sectCd},
                    // 連絡先出力可否フラグをパラメータに追加
                    {key: 'contactOutputFlg', value: contactOutputFlg}
                ];

                var data4xoblosDownload = {
                    fileName: fileName,
                    reqid: reqid,
                    paramList: paramList
                };

                var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
                        this.infoDialog = new InfoDialog({
                            title : '出力完了',
                            content : '一覧帳票の出力が完了しました。'
                        });
                        this.infoDialog.show();

                    }), lang.hitch(this, function(error) {
                        console.error(error);
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'エラーが発生しました。管理者にお問い合わせください。'
                        });
                        this.infoDialog.show();
                }));

                Loader.wait(promise);
            });
        },

        // 帳票のダウンロード
        download: function(data, fileNameJp) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                // ファイル名を取得
                var baseFileName = fileNameJp;
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放 (iPadでのエラー回避のためsetTimeoutする)
                    setTimeout(function() {
                        window.URL.revokeObjectURL(url);
                        link.remove();
                    }, 200);
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        }
    });
});
