/**
 * 防災体制・報告の登録ダイアログ用モジュール。
 * @module app/disasterprevention/view/DisasterPreventionRegisterPage
 */
define([
    'module',
    'app/config',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionRegisterPage.html',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    './_DisasterPreventionDialogPageBase',
    './model/HqType',
    './model/HqSetFlg',
    'app/common/consts/FunctionCd',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, config, declare, lang, domStyle, template, UserInfo, USER_TYPE,
    _PageBase, HqType, HqSetFlg, FunctionCd) {
    /**
     * 防災体制情報登録画面。
     * @class DisasterPreventionRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionRegisterPage~DisasterPreventionRegisterPage */ {
        // テンプレート文字列
        templateString: template,

        //市町村名
        muniName : null,

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            // 自治体の変更時、体制状況を初期化する
            this.own(this.municipalitySelector.on('change', lang.hitch(this, function() {
                if(this.municipalitySelector.value){
                    this.setStatus();
                }
            })));
            // 災害種別の変更時、体制を初期化する
            this.own(this.disasterTypeSelect.on('change', lang.hitch(this, function() {
                this._disasterType = this.disasterTypeSelect.value;
                if(this.municipalitySelector.value){
                    this.setStatus();
                }
            })));
            // 体制状況の変更時、本部設置情報欄の表示有無を切り替える
            this.own(this.status.on('change', lang.hitch(this, function(status) {
                // 新規作成の場合、直前の状態との比較は不要のため、
                // 変更後の値をそのまま渡して表示を切替える
                if (this._prevStatusStore) {
                    this._setHqInfNode(this._prevStatusStore.get(status));
                }
            })));
        },

        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                // 非表示項目は入力値を削除
                if(domStyle.get(this.hqInfNode, 'display')==='none'){
                    delete value.hqSetFlg;
                    delete value.hqSetTimestamp;
                    delete value.hqAbolishedTimestamp;
                    delete value.hqSetPlace;
                }
                if (domStyle.get(this.hqSetTimestampArea, 'display')==='none') {
                    delete value.hqSetTimestamp;
                    delete value.hqAbolishedTimestamp;
                    delete value.hqSetPlace;
                }
                // NOTE:長崎県案件では添付ファイルは扱わない
                // value.attachFileList = this._attachFileList;
                value.disasterId = this._disasterId;

                //体制状況から自動で入力する値をセット
                var statusCd = this.status.value;
                value.statusName = this._prevStatusStore.get(statusCd).label;
                // 本部設置種別
                value.hqType = this._prevStatusStore.get(statusCd).hqType;
                // 本部名
                var hqName = this._prevStatusStore.get(statusCd).hqName;
                // 解除の場合の本部名
                if(value.hqSetFlg===HqSetFlg.NOT_YET){
                    if(statusCd==='999999'){
                        // 体制の解除->体制名＋解除
                        hqName += ' 解除';
                    }
                } else if (!value.hqSetFlg) {
                    value.hqSetFlg = HqSetFlg.NOT_YET;
                }
                value.hqName = hqName;

                // 配信フラグ
                this._provideFlg = value.provideFlg = this.willProvide(value);
                console.log(value);
                this.emit('post-send', {value: value});

            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * Lアラート配信要否を取得する
         */
        willProvide: function(value) {
            // 振興局は対象外
            if (value.municipalityCd.substring(0,3) === config.municInfo.prefRegCdGudge &&
                value.municipalityCd !== config.municInfo.prefMunicCd) {
                return false;
            }
            // 対策/警戒本部設置の場合のみ配信
            return [HqType.RESPONSE_HQ, HqType.ALERT_HQ].indexOf(value.hqType) > -1 &&
                value.hqSetFlg === HqSetFlg.ESTABLISH;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function(parent) {
            // フォーム初期化
            this.form.reset();
            // 報告日時
            this.reportTimestamp._date.displayedValue = null;
            this.reportTimestamp._time.displayedValue = null;
            // 設置日時
            this.hqSetTimestamp._date.displayedValue = null;
            this.hqSetTimestamp._time.displayedValue = null;

            // 本部体制選択ノード
            // 初期表示時は非表示にする
            // domStyle.set(this.hqInfNode, 'display', 'none');
            // 災害種別
            // 選択している災害名で初期化する
            this._disasterType = parent._disasterType;
            this.disasterTypeSelect.set('value', parent._disasterType);
            // 報告者所属
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
            this.organization.set('value', orgCd);
            // 自治体
            if(parent.municipalityCd) {
                // 一覧画面からの遷移の場合は一覧画面の値を設定し、選択ボタンを非表示
                this.municipalitySelector.set('value', parent.municipalityCd);
                this.municipalitySelector.set('noAllButton', true);
            } else {
                // 値にユーザの所属自治体を設定する(県・市町村 or 振興局)
                this.municipalitySelector.set('value',
                    UserInfo.getMunicipalityCd() || UserInfo.getRegionCd());
                // 代理登録権限がない場合、選択ボタン非表示
                this.municipalitySelector.set('noAllButton', !UserInfo.hasProxyWriteAuthz(FunctionCd.DISASTERPREVENTION));
            }

            this._parent = parent;
            this._attachFileList = [];
            this._provideFlg = false;

            var _userId = UserInfo.getId();
            this.userSelector._setValueAttr(_userId);

            // 体制状況にモデルをセット
            this.setStatus();
        },

        /**
         * 本部設置状況変更イベント
         */
        onChangeHqFlg: function () {
            // 新規作成の場合、直前の状態との比較は不要のため、
            // 変更後の値をそのまま渡して表示を切替える
            var hqSetFlg = this.form.get('value').hqSetFlg;
            this._setHqSetInf(hqSetFlg);
        }
    });
});

