/**
 * <避難所情報登録画面>
 *
 * @module app/shelter/ShelterRegisterPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ShelterRegisterPage.html', // テンプレート文字列
    'dojo/json',
    'dojo/dom-style',
    'idis/view/dialog/InfoDialog',
    'dojo/_base/lang',
    'dojo/promise/all',
    'idis/control/Router',
    'idis/consts/STORAGE_KEY',
    'idis/store/IdisRest',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/map/IdisMap',
    'dojo/on',
    'app/config',
    'leaflet',
    'idis/view/dialog/IdisDialog',
    'idis/util/storage/LocalStorage',
    'app/map/baselayer/BaseLayerPane',
    './_ShelterPageBase',
    'idis/consts/USER_TYPE',
    'app/shelter/consts/CrowdedStatus',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/layout/BorderContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/Textarea',
    'dijit/form/NumberTextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'dijit/layout/AccordionContainer',
    'dojox/form/Uploader',
    'app/view/form/OrganizationSelector',
    'dijit/TitlePane',
    'idis/view/form/TimeInput',
    'idis/view/form/DateTimeInput',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'app/sample/view/SampleGrid',
    'app/view/form/ShelterSelector',
    'app/shelter/ShelterTypeMultiSelector',
    'app/provide/ProvideShelterRegisterDialog'
], function(module, declare, template, json, domStyle, InfoDialog, lang, all, Router, STORAGE_KEY,
        IdisRest, Locator, Loader, DialogChain, DisasterInfo, UserInfo, Requester, IdisMap, on,
        config, leaflet, IdisDialog, LocalStorage, BaseLayerPane, _ShelterPageBase, USER_TYPE, CrowdedStatus) {
    /**
     * 避難所新規登録画面
     *
     * @class ShelterRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ShelterPageBase,
    /** @lends module:app/shelter/ShelterRegisterPage~ShelterRegisterPage# */ {

        // テンプレート文字列
        templateString : template,

        // ルート要素に付与されるCSS
        baseClass : 'idis-Page idis-Page--shelter',

        /**
         * データ格納用オブジェクト
         *
         * @type {module:dstore/Store}
         */
        store : null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * クエリ用市町村コード
         */
        _municipalityCd: null,

        /**
         * formデータ
         */
        _sendData: null,

        /**
         * 背景地図ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _baseLayerDialog: null,

        /**
         * 施設ID
         */
        _facilityId : null,

        _latestStatus: null,

        /**
         * メッセージ表示用ダイアログ
         */
        infoDialog : null,
        constructor : function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty : 'shelterId',
                target : '/api/shelters'

            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            this._municipalityCd = UserInfo.getMunicipalityCds()[0];

            //トップページから遷移したときの選択施設情報を取得
            this._facilityId = Locator.getQuery().facilityId;
            this._scenarioId = Locator.getQuery().scenarioId;
            this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
            this._eventType = Locator.getQuery().eventType;
            this._content = Locator.getQuery().content;
            this._timing = Locator.getQuery().timing;
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);
            //地図を初期化
            this.initMap();
            // 画面を初期化
            this.initPage();
            //トップページ地図からの遷移の場合,遷移元の選択施設情報をセット
            if (this._facilityId && this._facilityId.length === 8){
                this.facilityId.set('value',this._facilityId);
                this.setPageContent(this._facilityId);
            }
            // 要請情報の表示制御
            this.showHideRequestContent();
        },

        /**
         * 画面を初期化する。
         */
        initPage: function() {

            //初期化
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
            this.attachFileList = [];

            // プレビューを初期化
            this.clearPreview();

            //周辺施設を非表示として初期化
            this.showMarkersFlg = true;
            domStyle.set(this.showMarkersButton, 'display', 'none');

            //初期表示では利用不可メッセージは非表示
            domStyle.set(this.notAvailableMsg, 'display', 'none');

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }
        },

        // パンくずリストのボタンを押下したときの挙動
        onShelterAdminPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin');
        },

        onShelterListPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter', {
                municipalityCd : this._municipalityCd
            });
        },

        // 水の使用状況を変更したときに「その他」の入力可否を変更する
        onWaterButtonClick: function() {
            if(this.form.get('value').waterUsageStatus === '2'){
                this.waterUsage.set('disabled',false);
            }else{
                this.waterUsage.set('disabled',true);
            }
        },

        // 避難所を選択したときに避難所情報をセット
        onChangeShelter: function(evt){
            // 避難所が選択されたときのみ変更
            if(evt.value.length === 8){
                this._facilityId=evt.value;
                this.setPageContent(this._facilityId);
                if (Locator.getQuery().facilityId){
                    Locator.pushState('facilityId', this._facilityId);
                }
            }

            Requester.get('/api/facility/' + this._facilityId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                this._municipalityCd = data.districtDto.municipalityCd;
                //「周辺被害表示」ボタンがアクティブになっていたら、一旦閉じる
                if(this.showMarkersFlg){
                    this.showShelterMarkers();
                }
                //周辺被害を再取得
                var param = '?municipalityCd=' + this._municipalityCd;
                //param += '&activeFlg=1&hasNoParentFlg=0';
                Requester.get('/api/facility' + param, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    Requester.get('/api/shelters', {
                        query: {
                            disasterId: this._disasterId,
                            municipalityCd: this._municipalityCd
                        }
                    }).then(lang.hitch(this, function(shelterData) {
                        this.enabledAroundShelterButton = true;
                        var items = data.items;
                        this.setShelterMarkers(items, shelterData.items, this._facilityId);
                        //周辺被害はデフォルト表示
                        this.showShelterMarkers();
                    }), lang.hitch(this, function(error) {
                        this.chain.infoError(error);
                    }));
                }), lang.hitch(this, function(error) {
                    this.chain.infoError(error);
                }));
                //「周辺被害表示」ボタンを表示
                domStyle.set(this.showMarkersButton, 'display', '');

                // 開設済みの混雑状況の設定について、避難所を選択したときは、ダイアログを出さず「空き」扱い
                if (this.form.get('value').status === '1') {
                    this.crowdedStatus.set('value', CrowdedStatus.UNDER_CAPACITY);
                }
            }), function(error) {
                console.log(error);
            });
        },

        getLatestStatus: function(){
            return Requester.get('/api/shelters/last-status', {
                query: {
                    disasterId: this._disasterId,
                    facilityId: this._facilityId
                }
            }).otherwise(function(err) {
                if (err && err.response && err.response.status === 404) {
                    // 見つからなかった場合は避難所名だけ取得
                    return null;
                } else {
                    // それ以外のエラーは最後にまとめる
                    console.error(err);
                    throw null;
                }
            });
        },

        setPageContent: function(){
            if (this._facilityId.length === 8){
                all({
                    facilityInfo: Requester.get('/api/facility/' + this._facilityId),
                    latestStatus: this.getLatestStatus()
                }).then(
                // Requester.get('/api/facility/' + this._facilityId).then(
                    lang.hitch(this, function(result){
                        var data = result.facilityInfo;

                        // この画面で前報がある＝一回開設したあとに閉鎖した避難所
                        this._latestStatus = result.latestStatus;

                        // クリアが選択された場合はundefinedとなるためチェックを実施
                        // 最大収容人数
                        if (data.capacity){
                            this.capacity.innerHTML = data.capacity+ '人';
                            this._capacity = data.capacity;
                        } else {
                            this.capacity.innerHTML = '未設定';
                            this._capacity = '未設定';
                        }

                        this._municipalityCd = data.districtDto.municipalityCd;
                        var shelterCategory = [];

                        // 指定緊急避難場所
                        if(data.edesignatedEvacShFlg==='1'){
                            //this.floodFlg.set('checked',true);
                            shelterCategory.push('指定緊急');
                        }

                        // 指定避難所
                        if(data.designatedEvacShFlg==='1'){
                            //this.floodFlg.set('checked',true);
                            shelterCategory.push('指定');
                        }

                        // 福祉避難所
                        if(data.welfareEvacShFlg==='1'){
                            //this.floodFlg.set('checked',true);
                            shelterCategory.push('福祉');
                        }

                        // 臨時避難所
                        if(data.temporaryEvacShFlg==='1'){
                            //this.floodFlg.set('checked',true);
                            shelterCategory.push('臨時');
                        }

                        // 避難施設（国民保護）
                        if(data.civilProtectionEvacShFlg==='1'){
                            //this.floodFlg.set('checked',true);
                            shelterCategory.push('国民保護');
                        }

                        // その他避難所
                        if(data.otherEvacShFlg==='1'){
                            //this.floodFlg.set('checked',true);
                            shelterCategory.push('その他');
                        }

                        if (shelterCategory.length > 0) {
                            this.shelterType.innerHTML = shelterCategory.join(', ') || '&nbsp;';
                        } else {
                            this.shelterType.innerHTML = '未設定';
                        }

                        var latlng = [data.latitude, data.longitude];
                        if(latlng[0] && latlng[1]) {
                            // マップを初期化
                            this.map.setView(latlng, 14);
                            this.marker && this.map.removeLayer(this.marker);
                            this.marker = leaflet.marker(latlng).addTo(this.map);
                        }

                        // 開設不可の避難所を選択した時の制御
                        if(data.availableFlg==='0'){//利用不可の場合は登録ボタンをdisabled、メッセージを表示
                            domStyle.set(this.submitButton.domNode, 'display', 'none');
                            domStyle.set(this.notAvailableMsg, 'display', '');
                        } else {//利用可の場合は登録ボタン表示、メッセージを非表示
                            domStyle.set(this.submitButton.domNode, 'display', '');
                            domStyle.set(this.notAvailableMsg, 'display', 'none');
                        }
                        if(this._simulateFlg){
                            this._facilityName = data.facilityName;
                        }
                    }));
            } else {
                this._capacity = '';
                this.capacity.innerHTML = '';
                this.shelterType.innerHTML = '  ';
                this.removeMark();
                var latlng = [config.map.latitude, config.map.longitude];
                this.map.setView(latlng, 14);
            }
        },

        /**
        * マップを初期化する。
        */
        initMap: function(lat, lng) {
            //中心アイコンを非表示にする
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');

            // マップの生成
            var latlng = (lat && lng) ? [lat, lng] :
                [config.map.latitude, config.map.longitude];

            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend : false,
                minZoom: 9,
                drawControlTooltips:false}
            ).setView(latlng, 14);

            // destroy時にmapを破棄するよう設定
            this.own(this.map);
            this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                this.pointLat = e.latlng.lat;
                this.pointLng = e.latlng.lng;
            })));

            //初期化
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
        },

        /**
        * マーカーを追加する。
        */
        addMark: function(lat, lng) {
            this.removeMark();
            this.marker = leaflet.marker([lat, lng]).addTo(this.map);
        },

        /**
        * マーカーを削除する。
        */
        removeMark: function() {
            this.map.removeLayer(this.marker);
        },

        // 新規登録ダイアログを表示する
        onSubmit : function() {
            this._sendData = this.form.get('value');

            // APIを呼んで新規登録
            if(this.facilityId.value.length !== 8) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '避難所が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }//必須入力項目の「報告日時」の確認
            else if(!this.reportTime.validate() || !this.reportTime._date.displayedValue ||
                !this.reportTime._time.displayedValue) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '報告日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }//必須入力項目の「開設日時」の確認 （開設準備中でない場合のみ）
            else if( this._sendData.status !== '0' &&
                ( !this.shelterStartTime.validate() || !this.shelterStartTime._date.displayedValue ||
                !this.shelterStartTime._time.displayedValue)) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '開設日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            //閉鎖日時が入力されている場合、その入力値が不正ならエラー
            } else if((this.shelterEndTime._date.displayedValue || this.shelterEndTime._time.displayedValue) &&
            !this.shelterEndTime.validate()) {
                if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '閉鎖日時の入力値が不正です'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }
            //閉鎖時刻が開設日時より手前に設定されている場合、エラー
            else if(this.shelterEndTime._date.displayedValue && this.shelterEndTime._time.displayedValue &&
                //閉鎖日が開設日より前の場合
                (this.shelterEndTime._date.displayedValue < this.shelterStartTime._date.displayedValue ||
                //または閉鎖日と開設日が同日かつ、時間が前の場合
                (this.shelterEndTime._date.displayedValue === this.shelterStartTime._date.displayedValue &&
                this.shelterEndTime._time.displayedValue < this.shelterStartTime._time.displayedValue))){
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '閉鎖日時が開設日時の前に設定されています'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
            }
            // 要請有無がありの場合に要請内容を入力していない場合、エラー
            else if(this.requestFlgActive.checked && !this.requestContent.value) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '要請内容を入力してください'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }
            // 必須入力項目の「混雑状況」の確認
            else if(this._sendData.status === '1' && !this.crowdedStatus.value) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '混雑状況を入力してください'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }
            else if(!this.form.isValid()) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
            }else{

                // 開設準備中の場合、模擬的に開設時間をセットする。
                if(this._sendData.status === '0'){
                    this._sendData.shelterStartTime = new Date().getTime();
                }

                //添付ファイルIDをセット
                if(this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].shelterAtcFileId);
                    }
                    this._sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    this._sendData.attachFileIds = '';
                }
                delete this._sendData.shelterAtcFile;

                this._sendData.disasterId = this._disasterId;
                if(this._simulateFlg){
                    // シミュレーション機能の場合、外部連携はしない。
                    this.registerShelter();
                // } else if(this._sendData.status === '0'){
                //     // 開設準備中の場合、ダイアログ表示はしない
                //     this.sendAndRegister(null);
                // } else {
                //     // 登録時には配信ダイアログを表示する
                //     this.showShelterDialog(this._sendData);
                // }

                // デモ用なのですべてLアラート配信ダイアログを出さない
                } else {
                    // 配信ダイアログ表示はしない
                    this.sendAndRegister(null);
                }
            }

        },

        sendAndRegister: function(shelterInfo){

            var message = shelterInfo ?
                '<h1 style="text-align:center;background-color: orange;">登録を実施すると情報が外部に発信されます。</h1>'
                : '';
            message += shelterInfo ? '避難所の登録及びLアラート等への配信を行います。\nよろしいですか？'
                                : '避難所の登録を行います。\nよろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                if(shelterInfo){
                    this._sendData.twitterFlg = shelterInfo.twitterFlg;
                    this._sendData.twitterMunicFlg = shelterInfo.twitterMunicFlg;
                    this._sendData.yahooFlg = shelterInfo.yahooFlg;
                    this._sendData.prefMailFlg = shelterInfo.prefMailFlg;
                    this._sendData.sendPostForm = shelterInfo.lalertForm;
                }

                var jsonStr = json.stringify(this._sendData);
                var promise =  Requester.post('/api/shelters', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(result) {

                    console.log(result);
                    var dialog = this.shelterRegisterDialog.getChildren()[0];
                    var compMessage = shelterInfo ? '登録・配信が完了しました。' : '登録が完了しました。';
                    chain.info(compMessage, '完了', lang.hitch(this, function(){
                        dialog.closeDialog();
                        Router.moveTo('shelter', {
                            municipalityCd: this._municipalityCd
                        });
                    }));

                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.infoError(error);
                });
                Loader.wait(promise);
            }));
        },


        /**
        * 配信情報(開設避難所情報)ダイアログを表示する。
        */
        showShelterDialog: function(obj) {
            Requester.get('/api/facility/' + obj.facilityId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this,function(item) {
                //非公開の避難所が登録されていた or 災害シミュレーションイベントの場合、配信ダイアログを挟まずに避難所を登録する
                if(!item.publicFlg || item.publicFlg !== '1' || this._simulateFlg){
                    return this.registerShelter();
                }

                var sort = '開設';
                var shelterStartOrEndTime = obj.shelterStartTime;
                if (obj.shelterEndTime !== null) {
                    sort = '閉鎖';
                    shelterStartOrEndTime = obj.shelterEndTime;
                }

                // 日付の形式を、Lアラート用にフォーマットする
                shelterStartOrEndTime = this.formatDate(shelterStartOrEndTime);

                // string型の数値情報を数字に変える
                obj.evaqueeNum = isNaN(obj.evaqueeNum) ? 0 : Number(obj.evaqueeNum);
                obj.evaqueeActNum = isNaN(obj.evaqueeActNum) ? 0 : Number(obj.evaqueeActNum);
                obj.evacHouseholdNum = isNaN(obj.evacHouseholdNum) ? 0 : Number(obj.evacHouseholdNum);
                obj.evacHouseholdActNum = isNaN(obj.evacHouseholdActNum) ? 0 : Number(obj.evacHouseholdActNum);
                obj.carEvaqueeNum = isNaN(obj.carEvaqueeNum) ? 0 : Number(obj.carEvaqueeNum);

                var evacShelterTypeOfDisaster = '';
                var evacShelterTypeOfDisasterList = [];
                if (item.floodShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('洪水');
                }
                if (item.sedimentDisasterShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('崖崩れ、土石流及び地滑り');
                }
                if (item.stormSurgeShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('高潮');
                }
                if (item.earthquakeShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('地震');
                }
                if (item.tsunamiShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('津波');
                }
                if (item.fireShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('大規模な火事');
                }
                if (item.landsideWaterShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('内水氾濫');
                }
                if (item.volcanoShFlg === '1') {
                    evacShelterTypeOfDisasterList.push('火山現象');
                }
                if (evacShelterTypeOfDisasterList && evacShelterTypeOfDisasterList.length !== 0) {
                    evacShelterTypeOfDisaster = evacShelterTypeOfDisasterList.join(',');
                }

                var evacSuitableShelter = '指定なし';
                if(item.edesignatedEvacShFlg === '1' && item.designatedEvacShFlg === '1' ){
                    evacSuitableShelter = '指定緊急避難場所兼避難所';
                } else if(item.edesignatedEvacShFlg === '1'){
                    evacSuitableShelter = '指定緊急避難場所';
                } else if (item.designatedEvacShFlg === '1' ){
                    evacSuitableShelter = '指定避難所';
                } else {
                    evacSuitableShelter = '指定なし';
                }

                var evaqueeData = [{
                    facilityId: obj.facilityId,
                    evacShelterName: item.facilityName,
                    evacShelterNameKana: item.facilityNameKana,
                    evacShelterType: item.facilityTypeName,
                    evacShelterSort: sort,
                    evacShelterLatitude: item.latitude,
                    evacShelterLongitude: item.longitude,
                    address: item.address,
                    capacity: item.capacity,
                    evaqueeNum: obj.evaqueeNum,
                    evaqueeActNum: obj.evaqueeActNum ? obj.evaqueeActNum : 0,
                    evacHouseholdNum: obj.evacHouseholdNum,
                    evacHouseholdActNum: obj.evacHouseholdActNum ? obj.evacHouseholdActNum : 0,
                    carEvaqueeNum: obj.carEvaqueeNum ? obj.carEvaqueeNum : 0,
                    evacShelterDatetime:shelterStartOrEndTime,
                    evacTopical:obj.comment,
                    evacSuitableShelter: evacSuitableShelter,
                    evacShelterTypeOfDisaster:evacShelterTypeOfDisaster,
                    crowdedStatus: obj.crowdedStatus,
                    spaceStatus: CrowdedStatus.getLabel(obj.crowdedStatus)
                }];

                var isPrefMailDefault = false;
                var shelterStartName = '';
                // 登録画面では閉鎖を選択できないため、何もセットされない
                var shelterEndName = '';

                // 準備中(閉鎖扱い)から準備中の場合ステータスが変わらないため配信対象外
                // ※外部連携ダイアログ自体表示されないはずだが一応設定
                if(obj.status === '0' && this._initialStatus === '9') {
                    isPrefMailDefault = true;
                } else if(obj.status !== '0') {
                    // 元が開設扱いではない場合、開設に切り替わることになるので配信対象
                    if(this._initialStatus !== '1' && this._initialStatus !== '3') {
                        isPrefMailDefault = true;
                        // 施設名は新規開設の時のみ表示する
                        shelterStartName = item.facilityName;
                    }
                }
                var args = {};

                args.shelterArgs = {
                    sendCategory : '02',
                    subject: ' ',
                    evacTimestamp: new Date(),
                    municipalityCd: this._municipalityCd,
                    evaqueeData: evaqueeData,
                    // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                    distributionType: '01',
                    isPrefMailDefault: isPrefMailDefault
                };
                args.evacOrderArgs = null;
                args.facilityId = obj.facilityId;
                args.newShelterNames = shelterStartName;
                args.endShelterNames = shelterEndName;
                args.reporterName = this.reporterName.value;
                args.reportTime = this.reportTime._date.displayedValue + ' ' +
                                    this.reportTime._time.displayedValue;

                // ダイアログの最初の子要素が登録画面
                var dialog = this.shelterRegisterDialog.getChildren()[0];

                // ダイアログの画面を初期化する。
                dialog.initDialog(args, false, false, false);

                // ダイアログのsendイベントを受け取れるようにする
                dialog.on('send', lang.hitch(this, function(evt) {
                    return this.sendAndRegister(evt.value);
                }));

                // ダイアログを破棄できないように設定する。
                this.shelterRegisterDialog.set('closable', false);

                // ダイアログを表示する。
                this.shelterRegisterDialog.show();
            }));
        },

        /**
        * 配信情報(開設避難所情報)ダイアログの表示を挟まずに登録する。
        */
        registerShelter: function(){
            switch (this.form.value.status) {
                case '0':
                    this._statusName = '開設準備中';
                    break;
                case '1':
                    if(this.form.value.evaqueeNum > this._capacity) {
                        this._statusName = '定員超過';
                        this.form.value.status = '2';
                    } else {
                        this._statusName = '開設済み';
                    }
                    break;
                default:
            }
            var subSendData = {};
            subSendData.statusName = this._statusName;
            subSendData.facilityName = this._facilityName;
            this._sendData.isFromMobile = this._sendData.fromMobile;
            delete this._sendData.fromMobile;


            var message = '登録します。よろしいですか？';
            var jsonStr = null;
            if(this._simulateFlg){
                jsonStr = json.stringify({
                    shelterPostForm: this._sendData,
                    shelterConversionForm: subSendData,
                    scenarioId: this._scenarioId,
                    eventType: this._eventType,
                    content: this._content,
                    timing: this._timing});
                message = 'イベント登録します。';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.post('/api/simulations/event/', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function() {
                        chain.infoComplete(function() {
                            chain.hide();
                            Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                        });
                    }, function(error) {
                        console.log('error register shelter event');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            } else {
                this.chain.confirm(message, lang.hitch(this, function(chain)  {
                    delete this._sendData.evaqueeActNum;
                    delete this._sendData.evacHouseholdActNum;
                    delete this._sendData.version;
                    delete this._sendData.layerName;
                    delete this._sendData.layerUrl;
                    this._sendData.twitterFlg = false;
                    this._sendData.twitterMunicFlg = false;
                    this._sendData.yahooFlg = false;
                    this._sendData.prefMailFlg = false;
                    this._sendData.sendPostForm = null;


                    jsonStr = json.stringify(this._sendData);
                    var promise = Requester.post('/api/shelters',{
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(data){
                        this._shelterId = data.shelterId;

                        chain.info('登録が完了しました。', '完了', lang.hitch(this, function(){
                            chain.hide();
                            Router.moveTo('shelter', {
                                municipalityCd: this._municipalityCd
                            });
                        }));

                    }), function(error){
                        // 失敗時
                        console.error(error);
                        chain.infoError(error);
                    });
                    Loader.wait(promise);
                }));
            }
        },

        /**
        * 背景地図ダイアログを表示する。
        */
        showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
        * トイレに関する詳細項目を表示する。
        */
        showToiletStatus: function() {
            var toiletStatus = this.form.get('value').toiletDmgFlg;
            if(toiletStatus === '1'){
                domStyle.set(this.toiletTableRow, 'display', 'table-Row');
            } else {
                domStyle.set(this.toiletTableRow, 'display', 'none');
            }
        },

        // キャンセルボタンを押下したら前の画面に戻る
        cancelButtonClick : function() {
            this.leave();
        },

        onOtherButtonClick : function() {
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        }

    });
});
