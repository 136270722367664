/**
 * 被災対応状況の追加ダイアログ。
 * @module app/damage/view/DamageReportActionForm
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/text!./templates/DamageReportActionForm.html',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/on',
    'dojo/request/iframe',
    'idis/util/FilesUtils',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数から参照されないモジュール
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/NumberTextBox',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput'
], function (module, declare, lang, array, domStyle, domClass, domConstruct, json, template,
    DialogChain, _PageBase, Menu, MenuItem, popup, TooltipDialog, on, iframe, FilesUtils, Loader, DisasterInfo) {
    /**
     * 被災対応状況の追加ダイアログ。
     * @class DamageReportActionDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/DamageReportActionDetailPage~DamageReportActionDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            selectedAction: null,

            _hldStatus: null,

            startup: function () {
                this._disasterId = DisasterInfo.getDisasterId();
                this.attachFileList = [];
                this.attachFile.startup();
                // 対応状況にhintをつける
                this.setActionStatusTips();
            },

            constructor: function () {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
            },

            /**
             * 対応状況を新規登録する。
             */
            registerAction: function () {
                try {
                    if (this.actionForm.validate()) {
                        // 入力チェックが成功したら画像情報を詰めて登録イベント発行
                        var form = this.actionForm.get('value');
                        form.attachFiles = json.parse(json.stringify(this.attachFileList));
                        this.emit('register', {
                            value: form
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 対応状況を更新する。
             */
            updateAction: function () {
                try {
                    if (this.actionForm.validate()) {
                        // 入力チェックが成功したら画像情報を詰めて更新イベント発行
                        var form = this.actionForm.get('value');
                        form.attachFiles = json.parse(json.stringify(this.attachFileList));
                        this.emit('update', {
                            value: form
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;

            },

            /**
             * 対応状況を削除する。
             */
            deleteAction: function () {
                try {
                    if (this.actionForm.validate()) {
                        // 入力チェックが成功したら画像情報を詰めて削除イベント発行
                        var form = this.actionForm.get('value');
                        form.attachFiles = json.parse(json.stringify(this.attachFileList));
                        this.emit('delete', {
                            value: form
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },


            /**
             * 対応履歴編集フォームを表示する
             */
            showUpdateActionForm: function (item) {
                delete item.attachFile;
                this.actionForm.reset();
                this.actionForm.set('value', item);
                this.attachFileList = [];
                this.attachFilePreview.innerHTML = '';

                this.formTitle.innerHTML = '対応履歴編集';
                domStyle.set(this.insertActionButtons.domNode, 'display', 'none');
                domStyle.set(this.updateActionButtons.domNode, 'display', '');

                // 添付ファイルを設定
                if (item.attachFiles) {
                    array.forEach(item.attachFiles, function (attachFile) {
                        this.attachFileList.push(attachFile);
                        this.showPreview(attachFile);
                    }, this);
                }

            },

            /**
             * 対応履歴フォームを初期化する
             */
            clearActionForm: function () {
                //[編集中]メッセージを消す
                if (this.selectedAction && this.selectedAction.editingMsg) {
                    domStyle.set(this.selectedAction.editingMsg, 'display', 'none');
                }
                this.actionForm.reset();
                this.hldStatus.set('value', this._hldStatus);
                this.formTitle.innerHTML = '新規対応履歴';
                domStyle.set(this.insertActionButtons.domNode, 'display', '');
                domStyle.set(this.updateActionButtons.domNode, 'display', 'none');

                //画像情報を初期化
                this.attachFileList.length = 0;
                this.attachFilePreview.innerHTML = '';
            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function () {
                // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                if (this.attachFile._files.length === 0 ||
                    !FilesUtils.isAttachFile(this.attachFile)) {
                    return;
                }
                var self = this;

                this.attachFile.set('disabled', false);

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/chronologies/uploadFile', {
                    form: this.actionForm.id,
                    handleAs: 'json'
                }).then(function (data) {
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.attachFileList.push(data);
                    self.showPreview(data);
                }, function (error) {
                    console.log(error);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.chain.infoError(error);
                });

                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 添付ファイルのプレビューを表示する。
             */
            showPreview: function (data) {

                var dataUri = data.attachFilePath.replace('out/', 'data/');
                var fileName = data.attachFileName;
                var fileId = data.chronologyAtcFileId;
                var self = this;

                // 画像ファイルの場合
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    var image = new Image();

                    //JPEGファイルの場合、EXIFデータの取得を実行する
                    if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                        var img = null;
                        this.own(on(image, 'load', lang.hitch(this, function (e) {
                            img = e.target;
                            console.log(img);
                        })));
                    }
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview in-actionForm');
                    domConstruct.place(image, this.attachFilePreview);
                    //メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, self);

                } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview in-actionForm');
                    domConstruct.place(excel, this.attachFilePreview);
                    //メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.pdf') !== -1) {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview in-actionForm');
                    domConstruct.place(pdf, this.attachFilePreview);
                    //メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview in-actionForm');
                    domConstruct.place(word, this.attachFilePreview);
                    //メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, self);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview in-actionForm');
                    domConstruct.place(other, this.attachFilePreview);
                    //メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, self);
                }
            },

            setHldStatus: function (hldStatus) {
                this._hldStatus = hldStatus;
                this.hldStatus.set('value', hldStatus);
                this.hldStatusDisplay.innerHTML = {
                    '0': '確認中',
                    '1': '対応待ち',
                    '2': '対応中',
                    '3': '対応完了'
                }[hldStatus];
            },

            /**
             * 対応状況のtips
             */
            setActionStatusTips: function () {
                var content = '被害情報の対応状況欄（画面左側）内の「対応状況」を選ぶと、<br>' +
                    'こちらの対応履歴の「対応状況」に反映されます。';
                var tips = new TooltipDialog({
                    id: 'tips',
                    style: 'width: 370px; height:10px',
                    content: '<p>' + content + '</p>'
                });
                var hldStatusLabel = this.hldStatusLabel;
                this.own(tips);
                on(hldStatusLabel, 'mouseover', lang.hitch(function () {
                    popup.open({
                        popup: tips,
                        around: hldStatusLabel
                    });
                }));
                on(hldStatusLabel, 'mouseleave', function () {
                    popup.close(tips);
                });
            },

            /**
               * 添付ファイルのサムネイル上にメニューを作る
               */
            createMenu: function (newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                //メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function () {
                    popup.close(tooltip);
                };

                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function () {
                        console.log('file delete');

                        // 該当ファイルを削除
                        for (var i = 0; i < self.attachFileList.length; i++) {
                            if (self.attachFileList[i].chronologyAtcFileId === id) {
                                self.attachFileList.splice(i, 1); //id:3の要素を削除
                            }
                        }
                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }
                }));

                menu.startup();
                //メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function () {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['above-centered']
                    });
                })));
                //画面破棄時に一緒に破棄する
                this.own(tooltip);
            }

        });
});
