/**
 * 被災対応状況の追加ダイアログ。
 * @module app/chronology/view/ChronologyActionForm
 */
define([
    'module',
    'dojo',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/topic',
    'dojo/on',
    'dojo/text!./templates/ChronologyActionForm.html',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/service/Requester',
    'idis/service/iframe',
    'idis/util/FilesUtils',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    // 'idis/view/page/_PageBase',
    './_ChronologyPageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Select',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    './_ChronologyPageBase'
], function(module, dojo, lang, declare, domStyle, domClass, domConstruct, json, topic, on,
     template, Menu, MenuItem, popup, TooltipDialog,  Requester, iframe, FilesUtils, Locator, Loader,
     DialogChain, UserInfo, _ChronologyPageBase) {
    /**
     * 被災対応状況の追加ダイアログ。
     * @class ChronologyActionDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChronologyPageBase,
        /** @lends module:idis/view/page/ChronologyActionDetailPage~ChronologyActionDetailPage# */
        {
            // テンプレート文字列
            templateString: template,
            attachFileList: [],
            chronologyActionId: null,
            selectedAction: null,

            constructor: function() {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
                this._chronologyId = Locator.getQuery().chronologyId;
            },

            /**
             * 対応履歴を新規登録する。
             */
            registerAction: function() {
                try {
                    if (this.actionForm.validate()) {
                        // 入力チェックが成功したらaddイベントを発行
                        // 実際の登録処理はサンプル画面側でaddイベントを監視しているメソッドが実施
                        this.registerChronologyAction();
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * クロノロジ対応履歴を登録する。
             */
            registerChronologyAction: function() {
                var sendData = {
                    chronologyId : this._chronologyId,
                    content : this.content.get('value'),
                    hldStatus : this.hldStatus.get('value'),
                    name : this.name.get('value'),
                    attachFileIds: ''
                };

                // フォームのバリデーションを行う（共通部品）
                if(!this.actionForm.validate()) {
                    return false;
                }
                if(!sendData.content  || sendData.content === ''){
                    this.chain.info('コメントが入力されていません。', 'エラー');
                    return false;
                }

                // 添付ファイルIDをセット
                sendData.attachFileIds = this.setFileIds();
                var jsonStr = json.stringify(sendData);
                //登録確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    var promise = Requester.post('/api/chronologies/action', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function(data) {
                        console.debug('success register Chronology');
                        chain.infoComplete(function() {
                            this.chain.hide();
                            // 画面を更新
                            // location.reload(false);
                            topic.publish(module.id + '::initActionGrid', data);
                            this.preview.innerHTML = '';
                            this.attachFileList = [];
                            this.content.set('value', null);
                        });
                    }, function(error) {
                        console.log('error register Chronology');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
                console.debug('[登録]ボタンがクリックされました。');
            },

            /**
             * 対応状況を更新する。
             */
            updateAction: function() {
                try {
                    if (this.actionForm.validate()) {
                        var sendData = this.actionForm.get('value');
                        if(!sendData.content  || sendData.content === ''){
                            this.chain.info('コメントが入力されていません。', 'エラー');
                            return false;
                        }

                        // 添付ファイルIDをセット
                        sendData.attachFileIds = this.setFileIds();
                        var jsonStr = json.stringify(sendData);
                        //更新確認ダイアログを表示
                        this.chain.confirmPut(function(chain) {
                            var promise = Requester.put('/api/chronologies/action/' + this.chronologyActionId, {
                                data: jsonStr,
                                headers: {'Content-Type': 'application/json; charset=utf-8'},
                                handleAs: 'json',
                                preventCache : true
                            }).then(function(data) {
                                console.debug('success register Chronology');
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    // 画面を更新
                                    // DetailPageに入力内容を送る
                                    topic.publish(module.id + '::update', data);
                                });
                            }, function(error) {
                                console.log('error register Chronology');
                                chain.infoError(error);
                            });
                            //ローダーの表示
                            Loader.wait(promise);
                        });
                        console.debug('[登録]ボタンがクリックされました。');
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            /**
             * 対応状況を削除する。
             */
            deleteAction: function() {
                try {
                    if (this.actionForm.validate()) {
                        //削除確認ダイアログの表示
                        this.chain.confirmDel(function(chain) {
                            var promise = Requester.del('/api/chronologies/action/' + this.chronologyActionId)
                            .then(function() {
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    this.emit('delete', {
                                        // テンプレートHTML内でformに対し
                                        // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                                        value: this.actionForm.get('value')
                                    });
                                });
                            }, function(error) {
                                console.log(error);
                                chain.infoError(error);
                            });

                            //ローダーの表示
                            Loader.wait(promise);
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function() {
                // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                if (this.attachFile._files.length === 0 ||
                    !FilesUtils.isAttachFile(this.attachFile)) {
                    return;
                }
                console.log('file change');
                var self = this;
                this.attachFile.set('disabled', false);
                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/chronologies/uploadFile', {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(function(data) {
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.attachFileList.push(data);
                    self.showPreview(data);
                }, function(error) {
                    console.log(error);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * ファイルIDをセットする
             */
            setFileIds: function() {
                // 添付ファイルIDをセット
                if(this.attachFileList.length>0) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                    }
                    return attachFileIds.join(',');
                } else {
                    return '';
                }
            },

            /**
             * 対応履歴編集フォームを表示する
             */
            showUpdateActionForm: function(item){
              this.actionForm.reset();
              this.actionForm.set('value', item);
              this.chronologyActionId = item.chronologyActionId;
              this.attachFileList = [];
              this.preview.innerHTML = '';

              // 添付ファイルを設定
              if(item.attachFiles) {
                  for(var i=0; i<item.attachFiles.length; i++) {
                      this.attachFileList.push(item.attachFiles[i]);
                      this.showPreview(item.attachFiles[i]);
                  }
              }
              this.formTitle.innerHTML = '対応履歴編集';
              domStyle.set(this.insertActionButtons.domNode, 'display', 'none');
              domStyle.set(this.updateActionButtons.domNode, 'display', '');
            },

            /**
             * 対応履歴フォームを初期化する
             */
            clearActionForm: function(){
                //[編集中]メッセージを消す
                if(this.selectedAction && this.selectedAction.editingMsg){
                    domStyle.set(this.selectedAction.editingMsg, 'display', 'none');
                }
              this.actionForm.reset();
              this.formTitle.innerHTML = '新規対応履歴';
              this.preview.innerHTML = '';
              domStyle.set(this.insertActionButtons.domNode, 'display', '');
              domStyle.set(this.updateActionButtons.domNode, 'display', 'none');
          },

          /**
           * 添付ファイルのプレビューを表示する。
           */
          showPreview: function(data) {
              var dataUri = data.attachFilePath.replace('out/', 'data/');
              var fileName = data.attachFileName;
              var fileId = data.chronologyAtcFileId;
              var self = this;

              // 画像ファイルの場合
              if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
              fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
              fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
              fileName.indexOf('.gif') !== -1) {
                  var image = new Image();

                  image.src = dataUri;
                  domClass.add(image, 'is-showPreview');
                  domConstruct.place(image, this.preview);
                  //メニューの作成
                  this.createMenu(image, dataUri, fileName, fileId, self, false);

              } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                  var excel = new Image();
                  excel.src = 'images/excelicon.png';
                  domClass.add(excel, 'is-showPreview');
                  domConstruct.place(excel, this.preview);
                  //メニューの作成
                  this.createMenu(excel, dataUri, fileName, fileId, self, false);
              } else if (fileName.indexOf('.pdf') !== -1) {
                  var pdf = new Image();
                  pdf.src = 'images/pdficon.png';
                  domClass.add(pdf, 'is-showPreview');
                  domConstruct.place(pdf, this.preview);
                  //メニューの作成
                  this.createMenu(pdf, dataUri, fileName, fileId, self, false);
              } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                  var word = new Image();
                  word.src = 'images/wordicon.png';
                  domClass.add(word, 'is-showPreview');
                  domConstruct.place(word, this.preview);
                  //メニューの作成
                  this.createMenu(word, dataUri, fileName, fileId, self, false);
              } else {
                  var other = new Image();
                  other.src = 'images/othericon.png';
                  domClass.add(other, 'is-showPreview');
                  domConstruct.place(other, this.preview);
                  //メニューの作成
                  this.createMenu(other, dataUri, fileName, fileId, self, false);
              }
          },

          /**
           * 添付ファイルのサムネイル上にメニューを作る
           */
          createMenu: function(newNode, uri, fileName, id, self, pic) {
              var menu = new Menu({
                  targetNodeId: newNode
              });
              menu.set('style', {'border': 'none', 'box-shadow': 'none'});

              //ダウンロード操作用
              var download = null;
              var userAgent = window.navigator.userAgent.toLowerCase();
              if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                  var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                  // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                  // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                  download = domConstruct.create('a', {href: '#'});
                  //クリックでファイル取得処理に入る
                  download.onclick = function() {
                      self.downloadFile(url, fileName);
                  };
              }else{
                  // FF, Chromeの場合、download属性でファイルダウンロード
                  download = domConstruct.create('a', {
                      href: uri,
                      download: fileName
                  });
              }

              // ファイル名とメニューとの境界線をセット
              var contentNode = domConstruct.create('div');
              contentNode.innerHTML = fileName;
              domConstruct.place('<hr color=#b0c4de>', contentNode);
              if (pic){
                  domConstruct.place('<img src="'+ uri + '" width="110">', contentNode);
              }

              //メニューをセット
              domConstruct.place(menu.domNode, contentNode);
              var tooltip = new TooltipDialog({
                  content: contentNode
              });
              //
              tooltip.containerNode.onmouseleave = function() {
                  popup.close(tooltip);
              };

              menu.addChild(new MenuItem({
                  label: '削除',
                  iconClass: 'dijitIconDelete',
                  onClick: function() {
                      // 該当ファイルを削除
                      for(var i=0; i<self.attachFileList.length; i++) {
                          if(self.attachFileList[i].chronologyAtcFileId === id) {
                              self.attachFileList.splice(i,1); //id:3の要素を削除
                          }
                      }
                      // サムネイルとメニューを削除
                      domConstruct.destroy(newNode);
                      popup.close(tooltip);
                  }
              }));

              menu.startup();
              //メニュー表示処理
              this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                  popup.open({
                      popup: tooltip,
                      around: newNode,
                      orient: ['above-centered']
                  });
              })));
              //画面破棄時に一緒に破棄する
              this.own(tooltip);
          }

        });
});
