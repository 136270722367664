/**
 * 観測地域モデル
 * @module app/model/ObservationRegion
 */
define([
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'value',
        data: [
            {
                'value': '130001',
                'label': '東京都全域',
                'selected': true,
                'regionFlg': ''
            },
            {
                'value': '',
                'label': '------地域選択------',
                'disabled': 'true',
                'regionFlg': '1'
            },
            {
                'value': '131001',
                'label': '区部西部',
                'regionFlg': '1'
            },
            {
                'value': '131002',
                'label': '区部東部',
                'regionFlg': '1'
            },
            {
                'value': '132001',
                'label': '多摩南部',
                'regionFlg': '1'
            },
            {
                'value': '132002',
                'label': '多摩西部',
                'regionFlg': '1'
            },
            {
                'value': '132003',
                'label': '多摩北部',
                'regionFlg': '1'
            },
            {
                'value':'133001',
                'label':'島しょ部',
                'regionFlg': '1'
            }
        ]
    });
});
