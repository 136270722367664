/**
* 消防庁被害報告登録画面用モジュール。
* @module app/damage/FDMAReportRegisterPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/FDMAReportRegisterPage.html',
    './_DamageReportPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'app/model/DisasterInfo',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/WordCountTextarea',
], function(module, declare, lang, json, template, _DamageReportPageBase, Locator,
    Router, Requester, DisasterInfo, Loader, DialogChain) {

    /**
    * 消防庁被害報告登録画面。
    * @class DamageReportRegisterPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--fdmareport',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        constructor: function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }

            this.chain = DialogChain.get(this);
        },

        startup: function() {
            this.inherited(arguments);
            this.initRegisterPage();
        },

        initRegisterPage: function() {
            if (Locator.getQuery().deadNum && Locator.getQuery().deadNum !== 'NaN') {
                this.deadNum.set('value', Locator.getQuery().deadNum);
            }
            if (Locator.getQuery().unknownNum && Locator.getQuery().unknownNum !== 'NaN') {
                this.unknownNum.set('value', Locator.getQuery().unknownNum);
            }
            if (Locator.getQuery().seriousNum && Locator.getQuery().seriousNum !== 'NaN') {
                this.seriousNum.set('value', Locator.getQuery().seriousNum);
            }
            if (Locator.getQuery().mildNum && Locator.getQuery().mildNum !== 'NaN') {
                this.mildNum.set('value', Locator.getQuery().mildNum);
            }
            if (Locator.getQuery().fullDestlHm && Locator.getQuery().fullDestlHm !== 'NaN') {
                this.fullDestlHm.set('value', Locator.getQuery().fullDestlHm);
            }
            if (Locator.getQuery().halfDestlHm && Locator.getQuery().halfDestlHm !== 'NaN') {
                this.halfDestlHm.set('value', Locator.getQuery().halfDestlHm);
            }
            if (Locator.getQuery().partDestlHm && Locator.getQuery().partDestlHm !== 'NaN') {
                this.partDestlHm.set('value', Locator.getQuery().partDestlHm);
            }
            if (Locator.getQuery().fldAbvHm && Locator.getQuery().fldAbvHm !== 'NaN') {
                this.fldAbvHm.set('value', Locator.getQuery().fldAbvHm);
            }
            if (Locator.getQuery().fldBlwHm && Locator.getQuery().fldBlwHm !== 'NaN') {
                this.fldBlwHm.set('value', Locator.getQuery().fldBlwHm);
            }
        },

        registerFDMAReport: function() {
            console.debug('[登録]ボタンがクリックされました。');

            var sendData = this.form.get('value');
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // フォームのバリデーションを行う（独自チェック）
            if(!this.validateForm(sendData)) {
                return false;
            }

            sendData.disasterId = this._disasterId;
            var jsonStr = json.stringify(sendData);
            //報告依頼を受けて新しく報告する場合
            //登録確認ダイアログを表示
            this.chain.confirmAdd(lang.hitch(this, function(chain) {
                var promise = Requester.post('/api/fdmaReports', {
                    data: jsonStr
                }).then(function() {
                    console.debug('success register FDMAReport');
                    chain.infoComplete(function(){
                        Router.moveTo('fdmaReport');
                    });

                }, function(error) {
                    console.log(error);
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            }));
        },

        /**
         * 入力値の妥当性をチェックする。
         */
        validateForm: function(data) {
            if (!data.reportTimestamp || data.reportTimestamp === '') {
                this.chain.info('報告日時を登録してください。', '入力エラー');
                return false;
            }
            return true;
        }
    });
});
