/**
* 施設詳細画面用モジュール。
* @module app/facility/FacilityDetailPage
*/
define([
    'module',
    'app/config',
    'app/facility/FacilityDetailConfig',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/FacilityDetailPage.html',
    'dojo/on',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    // 'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/view/page/_PageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/IdisDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/GeoService',
    'idis/service/iframe',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/Loader',
    'app/config',
    'app/map/baselayer/BaseLayerPane',
    'esri-leaflet-geocoder',
    'exif-js/exif',
    'leaflet',
    'dojo/json',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/view/form/CustomizableDistrictSelector',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/CustomizableFacilitiesSelector'
], function(module, appconfig, detailConfig, declare, lang, template, on, domClass, domConstruct, domStyle,
    Menu, MenuItem, popup, TooltipDialog, _PageBase,
    Locator, Router, DialogChain, InfoDialog, ConfirmDialog, IdisDialog,
    IdisMap, UserInfo, IdisRest, GeoService, iframe, Requester, FilesUtils,
    Loader, config, BaseLayerPane, geocoder, exif, leaflet, json) {
        // GeoServiceを初期化
        var _geoService = new GeoService({
            url: config.geocode && config.geocode.url
        });

        /**
        * 施設詳細画面
        * @class FacilityDetailPage
        * @extends module:idis/view/page/_PageBase~_PageBase
        */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
            /** @lends module:app/facility/FacilityDetailPage~FacilityDetailPage# */ {
                // テンプレート文字列
                templateString: template,

                confirmDialog: null,

                // ストア
                facilityStore: null,

                /**
                * 施設ID
                */
                _facilityId: null,

                /**
                * 市町区コード（県）
                */
                MUNICIPALITY_CD_KUMAMOTO_PREF: config.municInfo.prefMunicCd,

                /**
                * 住所 緯度
                */
                _addressLat: null,

                /**
                * 住所 経度
                */
                _addressLng: null,

                /**
                * バージョン
                */
                _version: null,

                detailFlg: null,

                attachFileList: [],

                /**
                 * 住所、緯度経度の変更をチェックするフラグ
                 */
                addressCheckFlg: false, // 住所変更による緯度経度の反映が必要
                latLngCheckFlg: false, // 緯度経度変更による住所の反映が必要

                /**
                 * 詳細画面初期化中フラグ
                 */
                _isInitDetailSet: true,

                constructor: function() {
                    // データ格納用オブジェクト
                    this.facilityStore = new IdisRest({
                        idProperty: 'facilityId',
                        target: '/api/facility'
                    });
                    // ダイアログ連鎖を登録
                    // 引数に与えたウィジェットのthis.ownを呼び出し、
                    // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                    this.chain = DialogChain.get(this);

                    // 市町村コードを取得
                    var municipalityCds = UserInfo.getMunicipalityCds();
                    if(municipalityCds && municipalityCds.length > 0) {
                        this._municipalityCd = municipalityCds[0];
                    } else {
                        this._municipalityCd = config.municInfo.prefMunicCd;
                    }

                    // 施設IDを取得
                    this._facilityId = Locator.getQuery().facilityId;
                    //詳細タブの初期表示を設定する。
                    this.detailFlg = '1';
                },

                /**
                * 施設情報を更新するための準備をする。送るデータの体裁を整える。
                */
                onSubmit: function() {
                    var sendData = this.form.get('value');
                    var districtCd = sendData.districtCd;
                    sendData.version = this._version;

                    //避難所以外では、詳細タブの項目が消えてしまうため、こちらで定義する。
                    if(sendData.facilityTypeId !== '01'){
                        sendData.heliportFlg = this.heliportFlg.value;
                        sendData.elevatorFlg = this.elevatorFlg.value;
                        sendData.roofFlg = this.roofFlg.value;
                        sendData.structure = this.structure.value;
                        sendData.petFlg = this.petFlg.value;

                        sendData.electricityFlg = this.electricityFlg.value;
                        sendData.gasFlg = this.gasFlg.value;
                        sendData.waterFlg = this.waterFlg.value;
                        sendData.phoneFlg = this.phoneFlg.value;
                        sendData.airConditionerFlg = this.airConditionerFlg.value;
                        sendData.pcFlg = this.pcFlg.value;
                        sendData.showerFlg = this.showerFlg.value;
                        sendData.bathFlg = this.bathFlg.value;
                        sendData.cookingFlg = this.cookingFlg.value;
                        sendData.laundryFlg = this.laundryFlg.value;
                        sendData.powerFlg = this.powerFlg.value;
                        sendData.toiletFlg = this.toiletFlg.value;
                        sendData.menToiletNum = this.menToiletNum.value;
                        sendData.womenToiletNum = this.womenToiletNum.value;
                        sendData.multitoiletFlg = this.multitoiletFlg.value;
/*
                        sendData.cookingFlg = sendData.welfareEvacShFlg;
                        sendData.bathFlg = sendData.welfareEvacShFlg;
                        sendData.showerFlg = sendData.welfareEvacShFlg;
                        sendData.laundryFlg = sendData.welfareEvacShFlg;
                        sendData.powerFlg = sendData.welfareEvacShFlg;
*/
                        if(sendData.facilityTypeId !== '02' && sendData.facilityTypeId !== '03'){
                            sendData.vehicleAccessibleFlg = this.vehicleAccessibleFlg.value;
                            sendData.accessibleVehicleCd = this.accessibleVehicleCd.value;
                        }
                    }

                    //添付ファイルIDをセット
                    if(this.attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i=0; i<this.attachFileList.length; i++) {
                            attachFileIds.push(this.attachFileList[i].facilityAtcFileId);
                        }
                        sendData.attachFileIds = attachFileIds.join(',');
                    } else {
                        sendData.attachFileIds = '';
                    }
                    delete sendData.attachFile;

                    if(this.pointLat === '' || this.pointLat === null ||
                    this.pointLng === '' || this.pointLng === null){//地図にピンがなかった場合
                        this._addressLat = null;
                        this._addressLng = null;

                        this.addressToLatLng().then(lang.hitch(this, function(){
                            sendData.latitude = this._addressLat;
                            sendData.longitude = this._addressLng;
                            this.validation(sendData, districtCd);
                        }));

                    } else { //地図にピンがあった場合
                        sendData.latitude = this.pointLat;
                        sendData.longitude = this.pointLng;
                        this.validation(sendData, districtCd);
                    }

                },

                /**
                * 入力内容のチェックを行う。
                */
                validation: function(sendData, districtCd){
                    var designatedEvacShFlg = sendData.designatedEvacShFlg.length;
                    var eDesignatedEvacShFlg = sendData.eDesignatedEvacShFlg.length;
                    var welfareEvacShFlg = sendData.welfareEvacShFlg.length;
                    var temporaryEvacShFlg = sendData.temporaryEvacShFlg.length;
                    var civilProtectionEvacShFlg = sendData.civilProtectionEvacShFlg.length;
                    var otherEvacShFlg = sendData.otherEvacShFlg.length;
                    // sendData.floodShFlg = '1';

                    if(sendData.facilityTypeId === '01' || sendData.facilityTypeId === '02' || sendData.facilityTypeId === '03'){
                        if(districtCd === '' || !this.checkDistrict(districtCd)) {
                            console.debug('地区が正しく選択されていません');
                            if (!this.infoDialog) {
                                this.infoDialog = new InfoDialog({
                                    title : 'エラー',
                                    content : '振興局が選択されているか、<br>地区が正しく選択されていません'
                                });
                            }
                            this.infoDialog.show();
                            this.infoDialog = null;
                            return false;
                        }
                    } else {
                        // 避難所、物資拠点以外は地区をセットしない（画面入力なし）
                        delete sendData.districtCd;
                    }
                    // 物資拠点・備蓄倉庫を選択した上で、管理主体がnullだとエラー
                    if((sendData.facilityTypeId === '02' || sendData.facilityTypeId === '03') &&
                        !sendData.managementEntity) {
                        console.debug('管理主体が正しく選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '管理主体が正しく選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    if((sendData.facilityTypeId === '01') && designatedEvacShFlg === 0 && eDesignatedEvacShFlg === 0 &&
                        welfareEvacShFlg === 0 && temporaryEvacShFlg === 0 && civilProtectionEvacShFlg === 0 && otherEvacShFlg === 0) {
                        console.debug('避難所区分が正しく選択されていません');

                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '避難所区分が正しく選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    // 住所を変更したが地図上でポイントされている位置に反映されていない
                    if (this.addressCheckFlg) {
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '変更した住所を地図に反映してください'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    // 地図上でポイントされている位置を変更したが住所に反映されていない
                    if (this.latLngCheckFlg) {
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '地図でポイントした位置を住所に反映してください'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
/*
                    delete sendData.organizationName;
                    delete sendData.municipalityName;
*/
                    //対象災害の「その他」のチェックそのものは不要なため削除。
/*
                    delete sendData.otherShFlg;
*/
                    //今回不使用の項目に仮の値を挿入しておく。
/*
                    sendData.supportFlg = sendData.cookingFlg;
                    sendData.foreignerFlg = sendData.cookingFlg;
                    sendData.floodFlg = sendData.cookingFlg;
                    sendData.multitoiletFlg = sendData.cookingFlg;
                    sendData.ostomateToiletFlg = sendData.cookingFlg;
                    sendData.japaneseStyleRoomFlg = sendData.cookingFlg;
                    sendData.airConditionerFlg = sendData.cookingFlg;
                    sendData.lpgFlg = sendData.cookingFlg;
                    sendData.cityGasFlg = sendData.cookingFlg;
                    sendData.sewerFlg = sendData.cookingFlg;
                    sendData.tankFlg = sendData.cookingFlg;
                    sendData.slopeFlg = sendData.cookingFlg;
*/
                    console.log(sendData);

                    // フォームのバリデーションを行う（共通部品）
                    if(!this.form.validate()) {
                        return false;
                    }
                    // フォームのバリデーションを行う（独自チェック）
                    if(!this.validateForm(sendData)) {
                        return false;
                    }

                    //住所が有効かどうかチェック
                    if(this._addressLat === null || this._addressLng === null){//住所が無効である場合
                        console.debug('address is not correct');
                        this.chain.confirm('住所から緯度・経度が取得できませんでした。<br>' +
                        '緯度・経度の情報が必要な場合は、キャンセルして地図上で施設の位置をクリックしてください。<br>' +
                        '緯度・経度の情報が不要な場合は、OKボタンを押して登録を完了してください。',
                        function(chain) {
                            Loader.wait(this.facilityStore.put(sendData)).then(function() {
                                // 成功時（PUT結果はグリッドが自動的に反映）
                                chain.infoComplete(function() {
                                    // 施設一覧ページに遷移
                                    Router.moveTo('facility');
                                });
                            }, function(err) {
                                // 失敗時
                                chain.infoError(err);
                            });
                        });

                    } else { //住所が有効である場合
                        this.updateFacility(sendData);
                    }

                },

                /**
                * 施設情報を更新する。
                */
                updateFacility: function(sendData){
                    this.chain.confirmPut(function(chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        Loader.wait(this.facilityStore.put(sendData)).then(function() {
                            // 成功時（PUT結果はグリッドが自動的に反映）
                            chain.infoComplete(function() {
                                // 施設一覧ページに遷移
                                Router.moveTo('facility');
                            });
                        }, function(err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                },

                // 詳細ページ表示時、オンのフラグにチェックを入れるメソッド
                facilityCheck: function(item, page){
                    // if(item.supportFlg === '1'){
                    //     page.supportFlg.set('value', 'on');
                    // }
                    // if(item.foreignerFlg === '1'){
                    //     page.foreignerFlg.set('value', 'on');
                    // }
                    // if(item.floodFlg === '1'){
                    //     page.floodFlg.set('value', 'on');
                    // }
                    // if(item.multitoiletFlg === '1'){
                    //     page.multitoiletFlg.set('value', 'on');
                    // }
                    // if(item.ostomateToiletFlg === '1'){
                    //     page.ostomateToiletFlg.set('value', 'on');
                    // }
                    // if(item.slopeFlg === '1'){
                    //     page.slopeFlg.set('value', 'on');
                    // }
                    // if(item.bathFlg === '1'){
                    //     page.bathFlg.set('value', 'on');
                    // }
                    // if(item.showerFlg === '1'){
                    //     page.showerFlg.set('value', 'on');
                    // }
                    // if(item.japaneseStyleRoomFlg === '1'){
                    //     page.japaneseStyleRoomFlg.set('value', 'on');
                    // }
                    // if(item.cookingFlg === '1'){
                    //     page.cookingFlg.set('value', 'on');
                    // }
                    // if(item.airConditionerFlg === '1'){
                    //     page.airConditionerFlg.set('value', 'on');
                    // }
                    // if(item.laundryFlg === '1'){
                    //     page.laundryFlg.set('value', 'on');
                    // }
                    // if(item.lpgFlg === '1'){
                    //     page.lpgFlg.set('value', 'on');
                    // }
                    // if(item.cityGasFlg === '1'){
                    //     page.cityGasFlg.set('value', 'on');
                    // }
                    // if(item.sewerFlg === '1'){
                    //     page.sewerFlg.set('value', 'on');
                    // }
                    // if(item.tankFlg === '1'){
                    //     page.tankFlg.set('value', 'on');
                    // }
                    // if(item.powerFlg === '1'){
                    //     page.powerFlg.set('value', 'on');
                    // }
                    if(item.floodShFlg === '1'){
                        page.floodShFlg.set('value', 'on');
                    }
                    if(item.sedimentDisasterShFlg === '1'){
                        page.sedimentDisasterShFlg.set('value', 'on');
                    }
                    if(item.stormSurgeShFlg === '1'){
                        page.stormSurgeShFlg.set('value', 'on');
                    }
                    if(item.earthquakeShFlg === '1'){
                        page.earthquakeShFlg.set('value', 'on');
                    }
                    if(item.tsunamiShFlg === '1'){
                        page.tsunamiShFlg.set('value', 'on');
                    }
                    if(item.fireShFlg === '1'){
                        page.fireShFlg.set('value', 'on');
                    }
                    if(item.landsideWaterShFlg === '1'){
                        page.landsideWaterShFlg.set('value', 'on');
                    }
                    if(item.volcanoShFlg === '1'){
                        page.volcanoShFlg.set('value', 'on');
                    }
                    //その他の災害が記載されていたらチェックボックスをonにしておく。
                    if(item.otherShFlg === '1' || item.shNote){
                        page.otherShFlg.set('value', 'on');
                    }
                    //onの場合はその他の記入boxを編集可能状態にする。
                    this.onOtherDisasterButtonClick();
                    //通行不可の場合は車種の初期表示をdisabledにしておく。
                    this.onVehicleButtonClick();
                    if(item.edesignatedEvacShFlg === '1'){
                        page.eDesignatedEvacShFlg.set('value', 'on');
                    }
                    if(item.designatedEvacShFlg === '1'){
                        page.designatedEvacShFlg.set('value', 'on');
                    }
                    if(item.welfareEvacShFlg === '1'){
                        page.welfareEvacShFlg.set('value', 'on');
                    }
                    if(item.temporaryEvacShFlg === '1'){
                        page.temporaryEvacShFlg.set('value', 'on');
                    }
                    if(item.civilProtectionEvacShFlg === '1'){
                        page.civilProtectionEvacShFlg.set('value', 'on');
                    }
                    if(item.otherEvacShFlg === '1'){
                        page.otherEvacShFlg.set('value', 'on');
                    }
                    this._version = item.version;
                },

                /**
                * 施設情報を削除する。
                */
                deleteFacilityButtonClick: function() {
                    this.chain.confirmDel(function(chain) {
                        // OKが押された場合

                        //施設に紐づく避難所を論理削除->開設済み/開設準備中避難所は削除できないよう修正したためコメントアウト
                        // var sendData = {};
                        // sendData.facilityId = Locator.getQuery().facilityId;
                        // var jsonStr = json.stringify(sendData);
                        // console.log(Locator.getQuery().facilityId);
                        // var promise = Requester.put('/api/shelters/delShelter/' +
                        // Locator.getQuery().facilityId.toString(),{
                        //     data: jsonStr,
                        //     headers: {'Content-Type': 'application/json; charset=utf-8'},
                        //     handleAs: 'json',
                        //     preventCache : true
                        // }).then(lang.hitch(this, function(data){
                        //     console.debug('避難所の訂正に成功しました。data:' + data);
                        // }), function(error){
                        //     // 失敗時
                        //     console.error(error);
                        //     chain.infoError(error);
                        // });
                        // Loader.wait(promise);

                        // 施設の論理削除 追加処理と共にローディング表示
                        Loader.wait(this.facilityStore.remove(Locator.getQuery().facilityId+
                            '?version=' + this._version)).then(function() {
                            // 成功時（DELETE結果はグリッドが自動的に反映）
                            chain.infoComplete(function() {
                                // 施設一覧ページに遷移
                                Router.moveTo('facility');
                            });
                        }, function(err) {
                            // 失敗時
                            if (err.response.data) {
                                var jsonData = json.parse(err.response.data);
                                if (jsonData.code && jsonData.code === 'E2001') {
                                    chain.info('避難所として開設準備中または開設されているため、削除できません。', 'エラー');
                                    return;
                                }
                            }
                            chain.infoError(err);
                        });
                    });
                },

                /**
                * マップを初期化する。
                */
                initMap: function(lat, lng) {
                    // マップの生成
                    var latlng = [lat, lng];

                    this.map = new IdisMap(this.mapNode, {
                        config: config.map,
                        keyboard: false, // コメント時に+/-が使用できないため
                        touchExtend : false,
                        minZoom: 9,
                        drawControlTooltips: false}
                    ).setView(latlng, 12);
                    // destroy時にmapを破棄するよう設定
                    this.own(this.map);
                    this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                        // 住所が変更されておらず、緯度経度のみ変更された場合は
                        // 緯度経度変更による反映チェックが必要
                        if (!this.addressCheckFlg) {
                            this.latLngCheckFlg = true;
                        } else {
                            this.latLngCheckFlg = false;
                        }
                        // 住所変更による反映がされたものとする
                        this.addressCheckFlg = false;
                    })));

                    //初期化
                    this.pointLat = '';
                    this.pointLng = '';
                    this.marker = '';

                    // // 地図レイヤーの追加
                    // leaflet.tileLayer(config.map.url, {
                    //     maxZoom: 18
                    // }).addTo(this.map);
                },

                /**
                 * 背景地図ダイアログを表示する。
                 */
                showBaseLayerDialog: function() {
                    if (!this._baseLayerDialog) {
                        // 初回呼び出し時にインスタンス生成
                        this._baseLayerDialog = new IdisDialog({
                            noUnderlay: true,
                            title: '背景地図',
                            content: new BaseLayerPane({map: this.map})
                        });
                        // 画面が破棄された際に連れて行く
                        this.own(this._baseLayerDialog);
                    }
                    this._baseLayerDialog.show();
                },

                /**
                * 地図上でポイントされている位置の住所を設定します。
                */
                mapToAddress: function() {
                    if(this.pointLat === '' || this.pointLng === '') {
                        console.debug('not pointed map');
                        this.chain.info('地図が選択されていません。', 'エラー');
                        return;
                    }

                    _geoService.reverseGeocode(leaflet.latLng({
                        lat: this.pointLat,
                        lng: this.pointLng
                    })).then(lang.hitch(this, function(res) {
                        this._isInitDetailSet = true; // 初期表示時と同様に住所変更でフラグが更新されないようにする
                        this.addMark(this.pointLat, this.pointLng);
                        this.address.set('value', res.address.Address);
                        this._addressLat = this.pointLat;
                        this._addressLng = this.pointLng;
                        // 住所、緯度経度変更による反映がされたものとする
                        this.latLngCheckFlg = false;
                        this.addressCheckFlg = false;
                    }), lang.hitch(this, function() {
                        this.chain.info('住所を取得できませんでした。', 'エラー');
                    }));
                    console.debug('end reverse geocoding (address: ' +
                    this.address.value + ')');
                },

                /**
                * 住所の位置を地図上にポイントします。
                */
                addressToMap: function() {
                    if(!this.address.value) {
                        this.chain.info('住所を入力してください。', 'エラー');
                        return;
                    }
                    //先頭に「県名」の記載を追加して検索する。
                    var address = this.address.value;
                    if(this.address.value.indexOf(appconfig.municInfo.prefName) !== 0){
                        address = appconfig.municInfo.prefName + address;
                    }
                    _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0) {
                            var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                            this.pointLat = results[0].latlng.lat;
                            this.pointLng = results[0].latlng.lng;
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;
                            this.map.setView(latlng, 14);
                            this.addMark(this.pointLat, this.pointLng);
                            // 住所、緯度経度変更による反映がされたものとする
                            this.latLngCheckFlg = false;
                            this.addressCheckFlg = false;
                        } else {
                            console.debug('address is not correct');
                            this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                        }
                    }));
                },

                /**
                * DBに登録されている住所から、緯度経度を割り出す。
                * （＋DBに緯度経度情報が入力されている場合に、地図上にマーカーを追加し、表示する）
                */
                addressToMapInit: function(itemlat, itemlng) {
                    if(!this.address.value) {
                        this.chain.info('住所を入力してください。', 'エラー');
                        return;
                    }
                    var address = appconfig.municInfo.prefName + this.address.value;
                    _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0) {
                            var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;

                            //DBに緯度経度情報が入力されている場合は、DBの情報を元に地図上にマーカーを追加する。
                            //（DBの住所と緯度経度の情報が異なる場合、マーカーは緯度経度情報を元に追加される）
                            if(itemlat !== null && itemlng !== null){
                                this.pointLat = itemlat;
                                this.pointLng = itemlng;
                                latlng = [itemlat, itemlng];
                                this.map.setView(latlng, 14);
                                this.addMark(this.pointLat, this.pointLng);
                            }
                        } else {
                            console.debug('address is not correct');
                            this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                        }
                    }));
                },

                /**
                * フォームに入力されている住所から、緯度・経度を割り出す
                */
                addressToLatLng: function() {
                    var address = appconfig.municInfo.prefName + this.address.value;
                    return _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0){ //住所が有効である場合
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;
                        } else { //住所が無効の場合（現実に存在しない住所が入力された場合）
                            this._addressLat = null;
                            this._addressLng = null;
                        }

                    }));
                },

                /**
                * マーカーを追加する。
                */
                addMark: function(lat, lng) {
                    this.removeMark();
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                },

                /**
                * マーカーを削除する。
                */
                removeMark: function() {
                    if (this.marker) {
                        this.map.removeLayer(this.marker);
                    }
                },


                /**
                * 入力値の妥当性をチェックする。
                */
                validateForm: function(data) {
                    /*
                    if (!data.registerTimestamp || data.registerTimestamp === '') {
                        this.chain.info('発信日時を入力してください。', '入力エラー');
                        return false;
                    }
                    if (!data.senderName || data.senderName === '') {
                        this.chain.info('送信者名を選択してください。', '入力エラー');
                        return false;
                    }
                    if (!data.sourceAddress || data.sourceAddress === '') {
                        this.chain.info('位置情報を入力してください。', '入力エラー');
                        return false;
                    }
                    */
                    console.debug(data);
                    return true;
                },
                // 種別詳細セレクトボックスの初期化を行う
                initFacilityTypeDetailSelectBox: function(){
                    var GOVERNMENT_AGENCY = '05'; // 行政機関
                    var PUBLIC_INSTITUTION = '09'; // 公共機関
                    var MEDICAL_INSTITUTION = '10'; // 医療機関
                    var PRESCHOOL = '11'; // 幼稚園・保育園
                    var SCHOOL = '12'; // 学校
                    var NURSINGHOME = '13'; // 老人ホーム
                    var LIFE_LINE = '14'; // ライフライン
                    var HELIPORT = '15'; // ヘリポート
                    var facilityTypeId = this.facilityTypeSelectBox.value;

                    // セレクトボックスに設定するデータの配列を作成
                    var newOptions = [{
                        label: '未選択',
                        value: ''
                    }];
                    switch(facilityTypeId) {
                    case GOVERNMENT_AGENCY:
                        detailConfig.governmentAgency.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case PUBLIC_INSTITUTION:
                        detailConfig.publicInstitution.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case MEDICAL_INSTITUTION:
                        detailConfig.medicalInstitution.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case PRESCHOOL:
                        detailConfig.preschool.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case SCHOOL:
                        detailConfig.school.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case NURSINGHOME:
                        detailConfig.nursingHome.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case LIFE_LINE:
                        detailConfig.lifeLine.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    case HELIPORT:
                        detailConfig.heliport.details.forEach(function(object){
                                newOptions.push({
                                label: object.label,
                                value: object.value
                                });
                        });
                        this.facilityTypeDetail.set('options',newOptions);
                        break;

                    default:
                        break;
                    }
                },

                // 対象災害で「その他」が押されたときに入力フォームを表示する。
                onOtherDisasterButtonClick: function() {
                    if(this.form.get('value').otherShFlg[0] === 'on'){
                        this.shNote.set('disabled',false);
                    }else{
                        this.shNote.set('disabled',true);
                    }
                },

                // 詳細情報のラジオボタン(トイレ)で「あり」が選択されたときに入力フォームを表示する。
                selectToilet: function() {
                    if(this.form.get('value').toiletFlg === '2'){
                        this.menToiletNum.set('disabled', false);
                        this.womenToiletNum.set('disabled', false);
                        domStyle.set(this.multitoilet, 'display', '');
                    }else{
                        this.menToiletNum.set('disabled', true);
                        this.womenToiletNum.set('disabled', true);
                        domStyle.set(this.multitoilet, 'display', 'none');
                    }
                },

                // 車両の通行可否で「否」が選択された時は最大車種を非表示にする。
                onVehicleButtonClick: function() {
                    if(this.form.get('value').vehicleAccessibleFlg === '1'){
                        this.accessibleVehicleCd.set('disabled',true);
                    }else{
                        this.accessibleVehicleCd.set('disabled',false);
                    }
                },

                // HTML上にウィジェットが設置されてから呼ばれる
                startup: function() {
                    this.inherited(arguments);

                    // 画面を初期化
                    this.initPage();
                    if(UserInfo.getAdminFlg()){
                        this.publicFlgYes.set('readOnly', false);
                        this.publicFlgNo.set('readOnly', false);
                    }
                },

                // DOMノード生成後に呼ばれる
                postCreate: function() {
                    this.inherited(arguments);
                },

                /**
                * 画面を初期化する。
                */
                initPage: function() {
                    //初期化
                    this.pointLat = '';
                    this.pointLng = '';
                    this.marker = '';
                    //初期表示では更新不可メッセージを非表示にする。
/*
                    domStyle.set(this.notEditableMsg, 'display', 'none');
*/
                },

                onFacilityLinkClick:function(evt) {
                    // ブラウザーの遷移処理をキャンセル
                    evt.preventDefault();
                    Router.moveTo('facility');
                },

                // DOMノードを生成するためのメソッド
                buildRendering: function() {
                    this.inherited(arguments);
                    var promise = this.initTree();
                    Loader.wait(promise).then(lang.hitch(this, function(){
                        this.initForm();
                    }));
                },

                // 施設種別セレクトボックスの初期化を行う
                initSelectBox: function(){
                    var promise =
                    Requester.get('/api/facility/category');
                    return Loader.wait(promise).then(lang.hitch(this, function(data) {
                        // セレクトボックスに設定するデータの配列を作成
                        var newOptions = [{
                            label: '&nbsp;',
                            value: null
                        }];
                        // サーバーレスポンスの各要素をselectへ追加
                        data.items.forEach(function(object){
                            newOptions.push({
                                label: object.facilityTypeName,
                                value: object.facilityTypeId
                            });
                        });
                        this.facilityTypeSelectBox.set('options',newOptions);
                    }));
                },


                // リストボックスで避難所が選択された場合は避難所関連の入力フォームを表示する
                showShelterAdmIdInputForm: function(id){
                    // '01'=避難所、'02'=備蓄倉庫、'03'=物資拠点'
                    if(id === '01') {
                        //「利用可否」「開設可否」の記載
                        domStyle.set(this.stocklabel, 'display', '');
                        domStyle.set(this.publicLabel, 'display', '');
                        // 避難所・物資拠点・備蓄倉庫の共通項目を表示
                        domStyle.set(this.folkliftNumInput, 'display', '');
                        domStyle.set(this.vehicleInfo, 'display', '');
                        // 避難所の管理項目
                        domStyle.set(this.shelterCategory, 'display', '');
                        domStyle.set(this.capacityInput, 'display', '');
                        domStyle.set(this.capacityCalcStandardInput, 'display', '');
                        domStyle.set(this.shelterType, 'display', '');
                        // 備蓄倉庫・それ以外の施設の項目
                        domStyle.set(this.municipalitySelector, 'display', 'none');
                        // 地区・種別詳細の項目
                        domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                        domStyle.set(this.districtCd, 'display', '');
                        // 詳細タブを表示する。
                        this.showDetailTab(this.detailFlg);
                        // トイレ関係
                        this.selectToilet();
                    } else if(id === '02' || id === '03'){
                        //「利用可否」「開設可否」の記載
                        domStyle.set(this.stocklabel, 'display', '');
                        domStyle.set(this.publicLabel, 'display', 'none');
                        // 避難所・物資拠点・備蓄倉庫の共通項目を表示
                        domStyle.set(this.folkliftNumInput, 'display', '');
                        domStyle.set(this.vehicleInfo, 'display', '');
                        // 避難所の管理項目
                        domStyle.set(this.shelterCategory, 'display', 'none');
                        domStyle.set(this.capacityInput, 'display', 'none');
                        domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                        domStyle.set(this.shelterType, 'display', 'none');
                        // 備蓄倉庫・それ以外の施設の項目
                        domClass.add(this.municipalitySelector, 'is-required');
                        domStyle.set(this.municipalitySelector, 'display', '');
                        // 地区・種別詳細の項目
                        domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                        domStyle.set(this.districtCd, 'display', '');
                        //詳細タブを非表示にする。
                        this.closeDetailTab(this.detailFlg);
                    } else {
                        //「利用可否」「開設可否」の記載
                        domStyle.set(this.stocklabel, 'display', '');
                        domStyle.set(this.publicLabel, 'display', 'none');
                        // 避難所・物資拠点・備蓄倉庫の共通項目を非表示
                        domStyle.set(this.folkliftNumInput, 'display', 'none');
                        domStyle.set(this.vehicleInfo, 'display', 'none');
                        // 避難所の管理項目を非表示
                        domStyle.set(this.shelterCategory, 'display', 'none');
                        domStyle.set(this.capacityInput, 'display', 'none');
                        domStyle.set(this.capacityCalcStandardInput, 'display', 'none');
                        domStyle.set(this.shelterType, 'display', 'none');
                        // 備蓄倉庫・物資拠点の項目を非表示
                        domClass.remove(this.municipalitySelector, 'is-required');
                        domStyle.set(this.municipalitySelector, 'display', '');
                        // 地区・種別詳細の項目
                        if(id >= '30'){
                            domStyle.set(this.facilityTypeDetailSelectBox, 'display', 'none');
                        } else{
                            domStyle.set(this.facilityTypeDetailSelectBox, 'display', '');
                        }
                        domStyle.set(this.districtSelector, 'display', 'none');
                        // 詳細タブを非表示にする。
                        this.closeDetailTab(this.detailFlg);
                    }
                },

                // 詳細タブを表示にする
                showDetailTab: function(detailFlg){
                    if(detailFlg === '0'){
                        this.tabContainer.addChild(this.mainFacilityInfo);
                        this.detailFlg = '1';
                        this.tabContainer.selectChild(this.mainFacilityInfo);
                    }
                },

                // 詳細タブを非表示にする
                closeDetailTab: function(detailFlg){
                    if(detailFlg === '1'){
                        this.tabContainer.removeChild(this.mainFacilityInfo);
                        this.detailFlg = '0';
                    }
                },

                /**
                * フォームを初期化する。
                */
                initForm: function() {
                    var page = this.form;
                    try{
                        this.initSelectBox().then(lang.hitch(this,function(){
                            return Requester.get('/api/facility/' + this._facilityId);
                        })).then(lang.hitch(this,function(data) {
                            var item = data;
                            console.debug('data=' + data);
                            page.set('value', item);
                            this.facilityTypeSelectBox.set('value',item.facilityTypeId);
                            this.initFacilityTypeDetailSelectBox();
                            this.facilityTypeDetail.set('value',item.facilityTypeDetail);
                            this.managementEntity.set('value',item.managementEntity);
                            this.districtCd.set('value',item.districtDto.districtCd);
                            // チェックボックスのうち、フラグがオンのものはcheckedにして表示する
                            this.facilityCheck(item,this);

                            //DBに登録されている緯度・経度を元に、地図・マーカーをセット
                            //(緯度・経度がnullの場合は、住所からマーカーを追加)
                            if(item.address !== ''){
                                this.addressToMapInit(item.latitude, item.longitude);
                            }

                            if(item.latitude && item.longitude) {
                                // マップを初期化
                                this.initMap(item.latitude, item.longitude);
                            } else {
                                // 市町村を取得
                                var self = this;
                                var promise = Requester.get('/api/municipalities/' + this._municipalityCd, {
                                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                                    handleAs: 'json',
                                    preventCache : true
                                }).then(function(data) {
                                    self._municipalityName = data.municipalityName;
                                    if(self._municipalityCd !== config.municInfo.prefMunicCd) {
                                        self.address.set('value', data.municipalityName);
                                    }

                                    // マップを初期化
                                    self.initMap(data.latitude, data.longitude);
                                }, function(error) {
                                    self.chain.infoError(error);
                                });
                                //ローダーの表示
                                Loader.wait(promise);
                            }

                            // 添付ファイルを設定
                            this.attachFileList = [];
                            if(item.facilityAtcFiles) {
                                for(var i=0; i<item.facilityAtcFiles.length; i++) {
                                    this.attachFileList.push(item.facilityAtcFiles[i]);
                                    this.showPreview(item.facilityAtcFiles[i], false);
                                }
                            }

                            // 避難所用管理項目の表示非表示切り替え
                            this.showShelterAdmIdInputForm(data.facilityTypeId);
                        }));
                    } catch (e) {
                        console.error(e);
                    }
                },

                initTree: function() {
                    return this.districtCd._initModel();
                },

                /**
                 * 添付ファイルをアップロードする。
                 */
                loadAttachFile: function() {
                    // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                    if (this.attachFile._files.length === 0 ||
                        !FilesUtils.isAttachFile(this.attachFile)) {
                        return;
                    }

                    console.log('file change');
                    var self = this;

                    this.attachFile.set('disabled', false);

                    // ファイルがonloadされたときにサーバーに一時保存する
                    var promise = iframe.post('/api/facility/uploadFile', {
                        form: this.form.id,
                        handleAs: 'json'
                    }).then(function(data) {
                        console.log(data);
                        //uploaderをリセット
                        self.attachFile.reset();
                        self.attachFileList.push(data);
                        self.showPreview(data, true);
                    }, function(error) {
                        console.log(error);
                        //uploaderをリセット
                        self.attachFile.reset();
                        self.chain.infoError(error);
                    });

                    //ローダーの表示
                    Loader.wait(promise);
                },

                /**
                 * 添付ファイルのプレビューを表示する。
                 */
                showPreview: function(data, exifFlg) {
                    var dataUri = data.attachFilePath.replace('out', 'data');
                    var fileName = data.attachFileName;
                    var fileId = data.facilityAtcFileId;
                    var self = this;

                    // 画像ファイルの場合
                    if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                        var image = new Image();

                        //JPEGファイルの場合、EXIFデータの取得を実行する
                        if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                            var img = null;
                            this.own(on(image, 'load', lang.hitch(this, function(e) {
                                console.log(e);
                                img = e.target;

                                if(exifFlg) {
                                    this.getExifData(img, this);
                                }
                            })));
                        }
                        image.src = dataUri;
                        domClass.add(image, 'is-showPreview');
                        domConstruct.place(image, this.preview);
                        //メニューの作成
                        this.createMenu(image, dataUri, fileName, fileId, self);

                    } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                        var excel = new Image();
                        excel.src = 'images/excelicon.png';
                        domClass.add(excel, 'is-showPreview');
                        domConstruct.place(excel, this.preview);
                        //メニューの作成
                        this.createMenu(excel, dataUri, fileName, fileId, self);
                    } else if (fileName.indexOf('.pdf') !== -1) {
                        var pdf = new Image();
                        pdf.src = 'images/pdficon.png';
                        domClass.add(pdf, 'is-showPreview');
                        domConstruct.place(pdf, this.preview);
                        //メニューの作成
                        this.createMenu(pdf, dataUri, fileName, fileId, self);
                    } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                        var word = new Image();
                        word.src = 'images/wordicon.png';
                        domClass.add(word, 'is-showPreview');
                        domConstruct.place(word, this.preview);
                        //メニューの作成
                        this.createMenu(word, dataUri, fileName, fileId, self);
                    } else {
                        var other = new Image();
                        other.src = 'images/othericon.png';
                        domClass.add(other, 'is-showPreview');
                        domConstruct.place(other, this.preview);
                        //メニューの作成
                        this.createMenu(other, dataUri, fileName, fileId, self);
                    }
                },

                /**
                 * JPEGファイルの位置情報を取得する
                 */
                getExifData: function(img, self) {
                    console.log('getting exif data start');
                    exif.getData(img, function(){

                        var latitude = exif.getTag(this, 'GPSLatitude');
                        var longitude = exif.getTag(this, 'GPSLongitude');

                        if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                            console.log('GPS data is unavailable.');
                        }else{
                            console.log('GPS data is available.');
                            var f = function(number){
                                return number[0].numerator + number[1].numerator /
                                (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                            };
                            self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                                // 位置情報を設定する
                                self.pointLat = f(latitude);
                                self.pointLng = f(longitude);
                                // 地図にマークして中心に移動する
                                self.addMark(self.pointLat, self.pointLng, self);
                                self.map.setView([self.pointLat, self.pointLng], 11);
                                //ダイアログを閉じる
                                chain.hide();
                            });
                        }
                    });
                },

                /**
                 * 添付ファイルのサムネイル上にメニューを作る
                 */
                createMenu: function(newNode, uri, fileName, id, self) {
                    var menu = new Menu({
                        targetNodeId: newNode
                    });
                    menu.set('style', {'border': 'none', 'box-shadow': 'none'});

                    //ダウンロード操作用
                    var download = null;
                    var userAgent = window.navigator.userAgent.toLowerCase();
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                        // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                        // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                        download = domConstruct.create('a', {href: '#'});
                        //クリックでファイル取得処理に入る
                        download.onclick = function() {
                            self.downloadFile(url, fileName);
                        };
                    }else{
                        // FF, Chromeの場合、download属性でファイルダウンロード
                        download = domConstruct.create('a', {
                            href: uri,
                            download: fileName
                        });
                    }

                    // ファイル名とメニューとの境界線をセット
                    var contentNode = domConstruct.create('div');
                    contentNode.innerHTML = fileName;
                    domConstruct.place('<hr color=#b0c4de>', contentNode);
                    //メニューをセット
                    domConstruct.place(menu.domNode, contentNode);
                    var tooltip = new TooltipDialog({
                        content: contentNode
                    });
                    //
                    tooltip.containerNode.onmouseleave = function() {
                        popup.close(tooltip);
                    };

                    // 画像ファイルの場合のみ'開く'をメニューに追加する
                    if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                        menu.addChild(new MenuItem({
                            label: '開く',
                            iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                            onClick: function() {
                                console.log('file open');
                                window.open(uri);
                            }
                        }));
                    }

                    menu.addChild(new MenuItem({
                        label: 'ダウンロード',
                        iconClass: 'dijitIconSave',
                        onClick: function(e) {
                            console.log('file download');
                            console.log(e);
                            //IE対策
                            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                                download.onclick();
                            } else {
                                download.click();
                            }
                        }
                    }));

                    menu.addChild(new MenuItem({
                        label: '削除',
                        iconClass: 'dijitIconDelete',
                        onClick: function() {
                            console.log('file delete');

                            // 該当ファイルを削除
                            for(var i=0; i<self.attachFileList.length; i++) {
                                if(self.attachFileList[i].facilityAtcFileId === id) {
                                    self.attachFileList.splice(i,1); //id:3の要素を削除
                                }
                            }

                            // サムネイルとメニューを削除
                            domConstruct.destroy(newNode);
                            popup.close(tooltip);
                        }
                    }));

                    menu.startup();
                    //メニュー表示処理
                    this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                        popup.open({
                            popup: tooltip,
                            around: newNode,
                            orient: ['below-centered']
                        });
                    })));
                    //画面破棄時に一緒に破棄する
                    this.own(tooltip);
                },

                /**
                 * 添付ファイルをダウンロードする。
                 */
                downloadFile: function(url, name) {
                    // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                    // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'arraybuffer';
                    xhr.onload = function() {
                        var arrayBuffer = this.response;
                        var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                        // IE10+
                        if(window.navigator.msSaveOrOpenBlob){
                            window.navigator.msSaveOrOpenBlob(blob, name);
                        }
                    };
                    xhr.send();
                    return false;
                },

                /**
                * 地区が選択されていることを検証する
                */
                checkDistrict :function(districtCd) {
                    var result = false;
                    this.districtCd.model.store.get(districtCd).then(
                        lang.hitch(this, function(item){
                            // item.typeが'MDistrict'の場合はツリーの子要素であるため地区として選択可能にする
                            if(item.type === 'MDistrict') {
                                result = true;
                            } else {
                                result = false;
                            }
                        }
                    ));
                    return result;
                },

                onChangeAddress: function() {
                    // 詳細画面の初期表示の時はフラグの更新を行わない
                    if (this._isInitDetailSet) {
                        this._isInitDetailSet = false;
                        return;
                    }

                    // 緯度経度が変更されておらず、住所のみ変更された場合は
                    // 住所変更による反映チェックが必要
                    if (!this.latLngCheckFlg) {
                        this.addressCheckFlg = true;
                    }
                    // 緯度経度変更による反映がされたものとする
                    this.latLngCheckFlg = false;
                }

            }
        );
    }
);
