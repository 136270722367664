/**
* Spectee管理画面用モジュール。
* @module app/spectee/mobile/SpecteeMobileAdminPage
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/text!./templates/SpecteeMobileAdminPage.html',
    'dojo/on',
    'dstore/Memory',
    'dojo/topic',
    'idis/view/page/_PageBase',
    'idis/consts/STORAGE_KEY',
    'idis/util/storage/LocalStorage',
    'idis/util/GeoUtils',
    'idis/map/IdisMap',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/control/Router',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'leaflet',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    './_SpecteeMobilePageBase',
    '../../config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/DateInput',
    'idis/view/form/TimeInput',
    './MobileNoneAdressIconPanel',
    './SpecteeMobileGrid',
    './SpecteeMobileCategoryDialog',
    './SpecteeMobileKeywordDialog',
    './SpecteeMobileDateFromDialog',
    './SpecteeMobileDateToDialog'
], function (module, array, declare, lang, locale, domStyle, domClass, template, on, Memory, topic, _PageBase, STORAGE_KEY, LocalStorage, GeoUtils, IdisMap,
    Loader, InfoDialog, Router, GeoService, Requester, UserInfo, UserType, leaflet, DisasterInfo, DialogChain, _SpecteeMobilePageBase, config) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var specteeFilterStore = {};

    // GeoServiceを初期化
    var _geoService = new GeoService({ url: config.geocode && config.geocode.url });
    /**
    * Spectee情報画面。
    * @class SpecteeMobileAdminPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _SpecteeMobilePageBase,
    /** @lends module:app/spectee/mobile/SpecteeMobileAdminPage~SpecteeMobileAdminPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--spectee',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            store: null,
            /**
             * 災害ID
             */
            _disasterId: null,
            /**
             * 市町コード
             */
            _municipalityCd: null,
            /**
             * 振興局コード
             */
            _regionCd: null,
            /**
             * 位置情報
             * @type {Object}
             */
            _latlng: null,
            /**
             * 国外情報有無
             * @type {Object}
             */
            _overseas: null,
            /**
             * 住所情報なしカウント
             * @type {Object}
             */
            _count: 0,
            /**
             * page種別
             * @type {String}
             */
            pageType: null,
            /**
             * page種別(管理画面)
             * @type {String}
             */
            PAGE_TYPE: 'ADMIN_PAGE',
            /**
             * 使用するgrid名
             * @type {String}
             */
            // GRID_NAME: null,
            /**
             * デフォルトの緯度経度
             */
            INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },
            /**
             * お気に入り情報
             * @type {String}
             */
            IS_FAVORITE: '1',
            /**
             * 処理開始フラグ
             * @type {String}
             */
            FETCH_SPECTEE_INFO_STARTED: false,
            /**
             * 位置情報確定レベル(緯度経度・住所なし)
             * @type {String}
             */
            LATLNG_COMFIRM_LEVEL_NONE: '0',
            /**
             * specteeお気に入り情報の登録・解除権限
             * @type {String}
             */
            SPECTEE_UPDATE_AUTH: '01',
            /**
             * 地図を動的に描画・操作するために発行するトピック
             */
            DRAW_ICON: 'app/spectee/mobile/SpecteeMobileGrid' + '::drawIcon',
            /**
             * 地図上の描画
             */
            drawnNewsIds: [],
            divIconMarkers: [],

            /**
             * constructor
             */
            constructor: function () {
                this.chain = DialogChain.get(this);

                // 災害IDをDisasterInfoから取得
                this._disasterId = localStorage.getItem('disasterId');
                if (!this._disasterId) {
                    this.chain.info('災害IDが設定させていません。', 'エラー');
                    this._disasterId = 1;
                }
                console.debug('現在の災害ID：' + this._disasterId);
                //地図アイコンに関する情報を初期化
                this.drawnNewsIds = [];
                this.divIconMarkers = [];
                this._events = [];
            },

            /**
             * buildRendering
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function () {
                this.inherited(arguments);
                // ページを初期化する
                this.initPage();
                // 地図を初期化する
                // this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng);
                // 所属自治体が初期表示されるようにする。
                if(UserInfo.getMunicipalityCd()) {
                    Requester.get('/api/municipalities/' + UserInfo.getMunicipalityCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        var zoom = 11;
                        if (UserInfo.getMunicipalityCd() === config.municInfo.prefMunicCd){
                            // 長崎県全体を表示する場合、ズーム値は9
                            zoom = 9;
                        }
                        // 地図を初期化
                        this.initMap(item.latitude, item.longitude, zoom);
                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        this.initMap(config.map.latitude, config.map.longitude, 11);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    }));
                } else if(UserInfo.getRegionCd()) {
                    /* Requester.get('/api/regions/' + UserInfo.getRegionCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        // 地図を初期化
                        // 振興局を表示する場合、ズーム値は9
                        this.initMap(item.latitude, item.longitude, 9);
                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        this.initMap(config.map.latitude, config.map.longitude);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    })); */
                    // 地図を初期化
                    // 振興局を表示する場合、ズーム値は9
                    this.initMap(UserInfo.getLatitude(), UserInfo.getLongitude(), 9);
                } else {
                    // 地図を初期化
                    this.initMap(config.map.latitude, config.map.longitude, 11);
                }
                // グリッドを初期化する
                this.initGrid(this.PAGE_TYPE);
                // グリッドが描画された際に地図にも位置情報を描画する
                var drawIconEvent = topic.subscribe(this.DRAW_ICON, lang.hitch(this, function (arg) {
                    this.addMarker(arg);
                }));
                this._events.push(drawIconEvent);
                this.own(drawIconEvent);
                // 現在地ボタン（ヘッダー）押下時のイベント
                this.own(topic.subscribe('app/view/SpecteeHeader::onCurrentPosition',
                lang.hitch(this, function(){
                    // 現在地を取得
                    this.dialogMessage.innerHTML = '現在地を取得中…';
                    this.dialog.show();
                    GeoUtils.getCurrentPosition().then(lang.hitch(this, function(res) {
                        this.dialog.hide();
                        // 成功時は地図を移動する
                        this.map.setView([res.coords.latitude, res.coords.longitude], 14);
                    }), lang.hitch(this, function(err) {
                        this.dialog.hide();
                        // 失敗時はダイアログを出す
                        this.dialogMessage.innerHTML = err.message;
                        this.dialog.show();
                    }));
                })));
            },

            /**
             * postCreate
             * DOM構築後に呼ばれる
             */
            postCreate: function () {
                console.debug('postCreate');
                this.inherited(arguments);
                // フォームに初期値をセットする
                this.initFormValue();
                // 初期表示用にSpecteeAPIにリクエストする
                this.searchSpecteeInfo();
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);
                // 保存済み情報表示ボタン
                this.initFavoriteItems();
                this.map.invalidateSize();
                // 定期処理を開始する
                this.startTimer();
            },

            /**
             * マップを初期化する。
             */
            initMap: function (lat, lng, zoom) {
                // 中心アイコンを非表示にする
                LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');

                // マップの生成
                var latlng = [lat, lng];

                this.map = new IdisMap(this.map, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }).setView(latlng, zoom || 14);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function (e) {
                    this.pointLat = e.latlng.lat;
                    this.pointLng = e.latlng.lng;
                })));
            },

            /**
             * SNSサービス一覧画面（モバイル用）を初期化する。
             */
            initPage: function() {
                // 事象入力ダイアログを初期化
                this.initCategoryDialog();
                this.categoryText.innerHTML = '未登録';
                // キーワード入力ダイアログを初期化
                this.initKeywordDialog();
                this.keywordText.innerHTML = '未登録';
                // 検索対象期間（FROM）入力ダイアログを初期化
                this.initDateFromDialog();
                var currentTime = new Date();
                currentTime.setDate(currentTime.getDate() - 1);
                var hours = currentTime.getHours();
                var minutes = currentTime.getMinutes();
                if (minutes <= 9) {
                    minutes = '0' + minutes;
                }
                this.dateFromText.innerHTML = currentTime.toLocaleDateString() + '&nbsp;' + hours + ':' + minutes ;
                // 検索対象期間（TO）入力ダイアログを初期化
                this.initDateToDialog();
                this.dateToText.innerHTML = '未登録';
            },

            /**
             * 事象入力ダイアログからの戻りイベントの定義
             */
            initCategoryDialog: function() {
                var dialog = this.categoryDialog;
                var page = dialog.getChildren()[0];

                // 「設定する」ボタンが押された場合のイベント
                page.on('category', lang.hitch(this, function(evt) {
                    // ダイアログを閉じる
                    this.categoryDialog.hide();
                    var text = '';
                    if (evt.value.fireFlg.length) {
                        this.fire.set('value', true);
                        if (text.length <= 16) {
                            text += '火災&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.fire.set('value', false);
                    }
                    if (evt.value.lifelineFlg.length) {
                        this.lifeline.set('value', true);
                        if (text.length <= 16) {
                            text += 'ライフライン&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.lifeline.set('value', false);
                    }
                    if (evt.value.weatherFlg.length) {
                        this.weather.set('value', true);
                        if (text.length <= 16) {
                            text += '気象&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.weather.set('value', false);
                    }
                    if (evt.value.accidentFlg.length) {
                        this.accident.set('value', true);
                        if (text.length <= 16) {
                            text += '事故・道路情報&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.accident.set('value', false);
                    }
                    if (evt.value.railwayFlg.length) {
                        this.railway.set('value', true);
                        if (text.length <= 16) {
                            text += '鉄道情報&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.railway.set('value', false);
                    }
                    if (evt.value.incidentFlg.length) {
                        this.incident.set('value', true);
                        if (text.length <= 16) {
                            text += '事件&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.incident.set('value', false);
                    }
                    if (evt.value.otherFlg.length) {
                        this.other.set('value', true);
                        if (text.length <= 16) {
                            text += 'その他&nbsp;&nbsp;';
                        } else {
                            text += '...';
                        }
                    } else {
                        this.other.set('value', false);
                    }
                    if (text){
                        this.categoryText.innerHTML = text;
                    } else {
                        this.categoryText.innerHTML = '未登録';
                    }
                }));
            },

            /**
             * キーワード入力ダイアログからの戻りイベントの定義
             */
            initKeywordDialog: function() {
                var dialog = this.keywordDialog;
                var page = dialog.getChildren()[0];
                // 「設定する」ボタンが押された場合のイベント
                page.on('keyword', lang.hitch(this, function(value) {
                    // ダイアログを閉じる
                    this.keywordDialog.hide();
                    this.keyword.set('value', value.keyword);
                    this.keywordText.innerHTML = (!!value.keyword && value.keyword.length > 0) ?
                        value.keyword : '未登録';
                }));
            },

            /**
             * 検索対象期間（FROM）入力ダイアログからの戻りイベントの定義
             */
            initDateFromDialog: function() {
                var dialog = this.dateFromDialog;
                var page = dialog.getChildren()[0];

                // 「設定する」ボタンが押された場合のイベント
                page.on('datefrom', lang.hitch(this, function(evt) {
                    // ダイアログを閉じる
                    this.dateFromDialog.hide();
                    var timestampFrom = new Date();
                    if (evt.value.timeFrom && evt.value.dateFrom) {
                        timestampFrom.setFullYear(evt.value.dateFrom.getFullYear());
                        timestampFrom.setMonth(evt.value.dateFrom.getMonth());
                        timestampFrom.setDate(evt.value.dateFrom.getDate());
                        timestampFrom.setHours(evt.value.timeFrom.getHours());
                        timestampFrom.setMinutes(evt.value.timeFrom.getMinutes());
                        this.postDateTimeFrom.set('value', timestampFrom);
                        var hours = timestampFrom.getHours();
                        var minutes = timestampFrom.getMinutes();
                        if (minutes <= 9) {
                            minutes = '0'+ minutes;
                        }
                        this.dateFromText.innerHTML = timestampFrom.toLocaleDateString() + '&nbsp;' + hours + ':' + minutes ;
                    } else {
                        this.postDateTimeFrom.set('value', null);
                        this.dateFromText.innerHTML = '未登録';
                    }
                }));
            },

            /**
             * 検索対象期間（TO）入力ダイアログからの戻りイベントの定義
             */
            initDateToDialog: function() {
                var dialog = this.dateToDialog;
                var page = dialog.getChildren()[0];

                // 「設定する」ボタンが押された場合のイベント
                page.on('dateto', lang.hitch(this, function(evt) {
                    // ダイアログを閉じる
                    this.dateToDialog.hide();
                    var timestampTo = new Date();
                    if (evt.value.timeTo && evt.value.dateTo) {
                        timestampTo.setFullYear(evt.value.dateTo.getFullYear());
                        timestampTo.setMonth(evt.value.dateTo.getMonth());
                        timestampTo.setDate(evt.value.dateTo.getDate());
                        timestampTo.setHours(evt.value.timeTo.getHours());
                        timestampTo.setMinutes(evt.value.timeTo.getMinutes());
                        this.postDateTimeTo.set('value', timestampTo);
                        var hours = timestampTo.getHours();
                        var minutes = timestampTo.getMinutes();
                        if (minutes <= 9) {
                            minutes = '0' + minutes;
                        }
                        this.dateToText.innerHTML = timestampTo.toLocaleDateString() + '&nbsp;' + hours + ':' + minutes ;
                    } else {
                        this.postDateTimeTo.set('value', null);
                        this.dateToText.innerHTML = '未登録';
                    }
                }));
            },

            /**
            * グリッドを初期化する。
            * 管理者用gridと市区町村ユーザ用gridを共通で処理できるよう、GRID_NAMEで処理を使い分ける
            */
            initGrid: function () {
                // グリッドの行選択イベント
                this.grid.on('dgrid-select', lang.hitch(this, function (evt) {
                    // クリックイベント発火時のマウスオーバー状態を保持する
                    this.wasMouseOvered = new Object({ 0: this.mouseOvered });
                    // 選択された行データを退避
                    var item = lang.mixin(null, evt.rows[0].data);

                    // 選択行とidを保持する
                    // SNSダイアログ表示後の、select状態を解除する処理に使用する
                    this.gridSelectedId = evt.rows[0].id;
                    this.selectedRow = evt.rows[0];

                    // 地図上にピンを打つため緯度経度情報を取得する
                    var prefecture = null;
                    var city = null;
                    var town = null;
                    var lat = null;
                    var lng = null;
                    var locationInfo = {};

                    // SpecteeAPIと、お気に入り情報取得APIではレスポンスのJSONが異なるため、判定を入れる
                    if (item.locationInfo) {
                            if (item.locationInfo[0]) {
                                locationInfo = item.locationInfo[0];
                                if (locationInfo.geocode) {
                                    lat = locationInfo.geocode.latitude;
                                    lng = locationInfo.geocode.longitude;
                                }
                            }
                        // }
                    }

                    // 取得した住所情報を各変数にセットする
                    if (locationInfo) {
                        prefecture = locationInfo.prefecture;
                        city = locationInfo.city;
                        town = locationInfo.town;
                    }

                    // 地図上にピンを打つ
                    if (lat && lng) {
                        // 緯度経度情報がSpecteeから渡ってきた場合は、その値を使用する
                        this.addMark(lat, lng, item);
                        this.map.setView([lat, lng], this.map.getZoom());
                    } else {
                        // 緯度経度情報がSpecteeから渡ってこなかった場合は、ジオコーディングして地図上にポイントを打つ
                        // 都道府県、市町村、区町丁目情報を引数として渡す
                        this.addressToMap(prefecture, city, town, item);
                    }
                }));
            },

            /**
             * grid情報を最新化する。
             */
            updateGrid: function (data) {
                var gridData = new Memory({
                    data: data,
                    idProperty: 'newsId'
                });

                this._count = 0;
                // 表示されているアイコンを削除する
                this.removeMarkers();
                // グリッドを空にする
                this.grid.set('collection', null);
                // gridに情報を反映する
                this.grid.set('collection', gridData.filter());
                if (gridData.data.length >= 100) {
                    InfoDialog.show('', '検索結果が100件以上あるため、最新100件を表示しています。');
                }
            },

            /**
             * grid描画時、spectee情報の位置情報をもとに地図上にアイコンを生成する
             */
            addMarker: function (arg) {
                // すでに描画されていた場合は戻る。
                if (this.drawnNewsIds.indexOf(arg.newsId) !== -1) {
                    return false;
                }

                if(!arg.locationInfo[0].city){
                    this._count ++;
                    this.noneAdressIconPanel.noneAdressIcon(arg, this._count);
                    this.noneAdressIconPanel.noneAdressCount(this._count);
                } else {
                    var marker = this.makeSpecteeMarker(arg);
                    if (marker) {
                        var self = this;
                        marker.addTo(this.map).on('click', function () {
                            self.clickMarker(arg);
                        });
                        this.divIconMarkers.push(marker);
                        this.drawnNewsIds.push(arg.newsId);
                    }
                }
            },

            /**
             * 地図上のアイコンを削除する
             */
            removeMarkers: function () {
                var map = this.map;
                array.forEach(this.divIconMarkers, function (divIcon) {
                    map.removeLayer(divIcon);
                });

                this.drawnNewsIds.length = 0;
            },

            //地図上のマーカー押下時の挙動
            clickMarker: function (arg) {
                this.grid.clearSelection();
                this.grid.select(arg.newsId);
            },

            /**
             * 日時入力フォームのイベントを定義する。
             */
            setDateTimeInputEvent: function () {
                // 検索対象期間の4つの入力フォーム(日付、日時は別物)のイベントを定義する
                var formArray = [
                    ['postDateTimeFrom', '_date'],
                    ['postDateTimeFrom', '_time'],
                    ['postDateTimeTo', '_date'],
                    ['postDateTimeTo', '_time']
                ];
                array.forEach(formArray, lang.hitch(this, function (form) {
                    // 検索対象期間のフォームイベントを定義する
                    this.own(this[form[0]][form[1]].on('change', lang.hitch(this, function () {
                        // イベント定義時に自動的にSpecteeへのリクエスト処理が走るため
                        // ページ初期表示時のリクエスト処理以降の処理であるかを判定する
                        if (this.FETCH_SPECTEE_INFO_STARTED) {
                            this.searchSpecteeInfo();
                        }
                    })));
                    this[form[0]][form[1]].startup();
                }));
            },

            /**
             * フォームに初期値をセットする。
             */
            initFormValue: function () {
                // ページ遷移する際に検索条件を保管していたかを判定する
                if (Object.keys(specteeFilterStore).length) {
                    // ページ遷移前に保管した検索条件がある場合
                    // 保管した検索条件をArrayに変換する
                    var queryArray =
                        Object.keys(specteeFilterStore).map(function (key) { return specteeFilterStore[key]; });

                    // 保管した検索条件をフォームにセットする
                    Object.keys(specteeFilterStore).forEach(lang.hitch(this, function (value, idx) {
                        if (value) {
                            var form = {};
                            form[value] = queryArray[idx];
                            this.form.set('value', form);
                        }
                    }));
                } else {
                    // ページ遷移前に保管した検索条件がない場合

                    // 都道府県にチェックを付ける
                    var prefFormObj = {};
                    // form名、valueを組み立てる(form名とvalueは同値)
                    var prefFormValue = 'pref' + config.municInfo.prePrefCd;
                    // 都道府県form用オブジェクトに追加する
                    prefFormObj[prefFormValue] = prefFormValue;
                    // 都道府県formのセット処理
                    this.form.set('value', prefFormObj);

                    // 現在より1日前の時刻を取得し、検索対象期間(開始)にセットする
                    var time = new Date();
                    time.setDate(time.getDate() - 1);
                    this.postDateTimeFrom.set('value', time);
                }
            },

            /**
             * Specteeへの定期取得処理を開始する。
             */
            startTimer: function () {
                //関数fetchSpecteeInfo()を60000ミリ秒間隔で呼び出す
                this.specteeTimer =
                    setInterval(lang.hitch(this, function () {
                        if (this._isFavorite !== '1') {
                            // お気に入り情報に絞っている場合は処理しない
                            var query = this.buildSpecteeQuery();
                            this.fetchSpecteeInfo(query);
                        }
                    }), 60000);
            },

            /**
             * Spectee/お気に入り情報の検索を開始する。
             */
            searchSpecteeInfo: function () {
                // 検索条件保管用storeを初期化する
                specteeFilterStore = {};
                var formValue = this.form.get('value');
                // 検索条件を保管する
                specteeFilterStore = lang.mixin(null, formValue);

                // 情報取得を開始する
                if (this._isFavorite === '1') {
                    this.fetchFavoriteInfo();
                } else {
                    var query = this.buildSpecteeQuery();
                    this.fetchSpecteeInfo(query);
                }
            },

            /**
             * 検索条件に合致するSpectee情報を取得する。
             */
            fetchSpecteeInfo: function (query) {
                Requester.get('/api/spectee/', {
                    query: query
                }).then(lang.hitch(this, function (data) {
                    console.log('SpecteeよりSNS情報を取得');
                    // APIから返却されるレスポンスコードを元に処理する
                    if (data.status === '200') {
                        this.lastFetchTime = data.fetchTime;
                        // 住所情報なしゾーンを空にする
                        this.noneAdressIconPanel.initNoneAdressIcon();
                        this.updateGrid(data.data);
                    } else {
                        this.chain.info('SpecteeよりSNS情報を取得できませんでした。一分後に情報を自動取得します。', 'エラー');
                    }

                    // 画面初期表示時の処理が完了した段階でSpectee処理の開始フラグを有効にする
                    // この処理により、画面初期表示時に日時フォームのchangeイベントにより複数回リクエストが飛ぶことを防いでいる
                    if (!this.FETCH_SPECTEE_INFO_STARTED) {
                        this.FETCH_SPECTEE_INFO_STARTED = true;
                    }
                }), lang.hitch(this, function (error) {
                    this.chain.infoError(error);
                }));
            },

            /**
             * Spectee情報取得用のqueryを組み立てる。
             */
            buildSpecteeQuery: function () {
                var formValue = this.form.get('value');

                // フォームの入力値をArrayに変換する
                var formValueArray = Object.keys(formValue).map(function (key) { return formValue[key]; });
                var query = {};
                query.prefIds = '13';
                query.eventIds = null;
                query.targetIds = null;
                query.cityIds = null;

                // 対象・事象IDと市町IDをカンマ区切りの文字列にする
                array.forEach(formValueArray, function (value) {
                    if (/^(event)/.test(value)) {
                        var eveValStr = value[0].replace(/event/, '');
                        query.eventIds = query.eventIds === null ? eveValStr : query.eventIds + ',' + eveValStr;
                    } else if (/^(target)/.test(value)) {
                        var targValStr = value[0].replace(/target/, '');
                        query.targetIds = query.targetIds === null ? targValStr : query.targetIds + ',' + targValStr;
                    } else if (/^(city)/.test(value)) {
                        var cityValStr = value.replace(/city/, '');
                        query.cityIds = query.cityIds === null ? cityValStr : query.cityIds + ',' + cityValStr;
                    }
                });
                query.keyword = this.form.get('value').keyword;

                // 期間検索のqueryを追加する
                var afterDate =
                    this.form.get('value').postDateTimeFrom ? this.form.get('value').postDateTimeFrom.getTime() : 0;
                var beforeDate =
                    this.form.get('value').postDateTimeTo ? this.form.get('value').postDateTimeTo.getTime() : 0;

                // SpecteeAPIでは検索対象期間(開始)に現在時刻以降を指定できないため
                // 現在時刻を超えた場合はアラートを表示し、強制的に現在時刻に変更する
                var now = new Date();
                if (this.form.get('value').postDateTimeFrom > now) {
                    afterDate = now.getTime();
                }
                if (afterDate) {
                    query.afterDate = locale.format(new Date(afterDate), {
                        selector: 'date',
                        datePattern: 'yyyy-MM-dd HH:mm:00'
                    });
                }

                // Spectee APIの仕様上2018年2月27日午前0時0分以前は指定できないため、
                // フォームの値がそれ以前の日時の場合はqueryに追加しない
                var limitDateTime = new Date(2018, 1, 27, 0, 0, 0);
                if (this.form.get('value').postDateTimeTo <= limitDateTime) {
                    beforeDate = now.getTime();
                }
                if (beforeDate) {
                    query.beforeDate = locale.format(new Date(beforeDate), {
                        selector: 'date',
                        datePattern: 'yyyy-MM-dd HH:mm:00'
                    });
                }

                // 最終情報取得日時をセットする
                // この日時と次回情報取得日時までの間に取得された情報が新着情報となる
                query.lastFetchTime = this.lastFetchTime;
                // 災害IDをセットする お気に入り情報に登録済みであるかを判定する際に使用する
                query.disasterId = this._disasterId;
                return query;
            },

            /**
             * お気に入り情報を取得する。
             */
            fetchFavoriteInfo: function () {
                // 災害名が選択されていない場合は処理しない
                if (!this._disasterId) {
                    this.chain.info('災害名を選択してください', 'エラー');
                    return;
                }
                Requester.get('/api/spectee/favoriteCase/', {
                    query: { disasterId: this._disasterId }
                }).then(lang.hitch(this, function (data) {
                    console.log('お気に入り情報を取得');
                    // 配列に詰め直し
                    var list = [];
                    array.forEach(data.items, lang.hitch(this, function (items) {
                        array.forEach(items.data, lang.hitch(this, function (data) {
                            list.push(data);
                        }));
                    }));
                    this.updateGrid(list);
                    // イベント定義時に自動的にSpecteeへのリクエスト処理が走るため
                    // ページ初期表示時のリクエスト処理以降の処理であるかを判定する
                    if (!this.FETCH_SPECTEE_INFO_STARTED) {
                        this.FETCH_SPECTEE_INFO_STARTED = true;
                    }
                }), lang.hitch(this, function (error) {
                    this.chain.infoError(error);
                }));
            },

            /**
             * Spectee情報のマーカーを生成する
             */
            makeSpecteeMarker: function(item){
                var lat = item.locationInfo[0].geocode.latitude;
                var lng = item.locationInfo[0].geocode.longitude;

                // 位置情報が無い時はマーカーを生成しない
                if(!lat || !lng){
                    return false;
                }

                // 事象別アイコン
                var html = [];
                var leftPosition = -12;
                var specteeEvent = 'other';
                if(!this.isEmpty(item.eventIds)){
                    if(item.eventIds.indexOf('200100002')!==-1 || item.eventIds.indexOf('200100003')!==-1 || item.eventIds.indexOf('200100007')!==-1 || item.eventIds.indexOf('200100009')!==-1){
                        specteeEvent = 'railway';
                    } else if (item.eventIds.indexOf('100100')!==-1){
                        specteeEvent = 'weather';
                    } else if (item.eventIds.indexOf('100200')!==-1){
                        specteeEvent = 'fire';
                    } else if (item.eventIds.indexOf('100300')!==-1){
                        specteeEvent = 'accident';
                    } else if (item.eventIds.indexOf('100400')!==-1){
                        specteeEvent = 'incident';
                    } else if (item.eventIds.indexOf('100500')!==-1){
                        specteeEvent = 'lifeline';
                    } else if (item.eventIds.indexOf('100600')!==-1){
                        specteeEvent = 'other';
                    }
                }
                var specteeEventIcon = '/images/spectee/event_' + specteeEvent + '.png';
                html += '<img src="' + specteeEventIcon + '" height="30px" width="30px"';
                html += ' style="position:absolute;top:-15px;left:' + leftPosition + 'px;">';


                var divIcon = leaflet.divIcon({
                    html: html,
                    className: 'damage-point',
                    iconSize: [0, 0],
                    iconAnchor: [3, 3]
                });

                var marker = leaflet.marker([lat, lng],{
                    icon: divIcon
                });

                return marker;
            },

            /**
             * 詳細画面に遷移する。
             */
            moveToDetailPage: function (evt) {
                if (this.marker) {
                    this.removeMark();
                }
                // 事案IDを渡す
                if (evt && evt.item && evt.item.caseId) {
                    var caseId = evt.item.caseId;
                    var favoriteFlg = evt.item.favoriteFlg;
                    // ブラウザーの遷移処理をキャンセル
                    evt.preventDefault();
                    Router.moveTo('spectee/detail', {
                        caseId: caseId,
                        favoriteFlg: favoriteFlg });
                }
            },

            /**
             * 保存済み情報表示ボタンが押下された際の挙動。
             */
            onSearchFavoriteButton: function() {
                // ステータスの変更
                this._isFavorite = !this._isFavorite ? '1' : '';
                // 画面上部のフィールドへ変更内容を反映
                this.initFavoriteItems();
                // 検索実行
                this.searchSpecteeInfo();
            },

            /**
             * 保存済み情報表示ステータスを画面上部のフィールドに反映する。
             */
            initFavoriteItems :function() {
                // 保存済み情報ステータスが'1'の場合、「保存済み情報表示中」
                if (this._isFavorite === '1') {
                    this.favoriteButton.innerHTML='全ての情報を表示';
                    this.favoriteLabel.innerHTML='保存済情報を表示中';
                    /* 詳細検索エリアを非表示 */
                    domStyle.set(this.filterPane, 'display', 'none');
                    domStyle.set(this.showQueryLabel, 'display', 'none');
                    domStyle.set(this.hideQueryLabel, 'display', 'none');
                    /* 市町選択セレクターを変更 */
                    domStyle.set(this.municArea, 'display', 'none');
                    domStyle.set(this.municFavoriteArea, 'display', '');

                } else {
                    // 保存済み情報ステータスが'1'でなければ、「全ての情報を表示」
                    this.favoriteButton.innerHTML='保存済み情報のみ表示';
                    this.favoriteLabel.innerHTML='全ての情報を表示中';
                    domStyle.set(this.showQueryLabel, 'display', '');
                    /* 市町選択セレクターを変更 */
                    domStyle.set(this.municArea, 'display', '');
                    domStyle.set(this.municFavoriteArea, 'display', 'none');
                }
            },

            /**
             * 事象入力ボタンが押下された際の挙動。
             */
            categoryEntryButton: function() {
                // 検索条件をダイアログにセットする
                var dialog = this.categoryDialog;
                var page = dialog.getChildren()[0];
                var fire = this.fire.get('value');
                var lifeline = this.lifeline.get('value');
                var weather = this.weather.get('value');
                var accident = this.accident.get('value');
                var railway = this.railway.get('value');
                var incident = this.incident.get('value');
                var other = this.other.get('value');
                page.setValue(fire, lifeline, weather, accident, railway, incident, other);
                // 事象入力ダイアログを開く
                this.categoryDialog.show();
            },
            /**
             * キーワード入力ボタンが押下された際の挙動。
             */
            keywordEntryButton: function() {
                // 検索条件をダイアログにセットする
                var dialog = this.keywordDialog;
                var page = dialog.getChildren()[0];
                page.setValue(this.keyword.get('value'));
                // キーワード入力ダイアログを開く
                this.keywordDialog.show();
            },
            /**
             * 検索対象期間（FROM）入力ボタンが押下された際の挙動。
             */
            dateFromEntryButton: function() {
                // 検索条件をダイアログにセットする
                var dialog = this.dateFromDialog;
                var page = dialog.getChildren()[0];
                page.setValue(this.postDateTimeFrom.get('value'));
                // キーワード入力ダイアログを開く
                this.dateFromDialog.show();
            },
            /**
             * 検索対象期間（TO）入力ボタンが押下された際の挙動。
             */
            dateToEntryButton: function() {
                // 検索条件をダイアログにセットする
                var dialog = this.dateToDialog;
                var page = dialog.getChildren()[0];
                page.setValue(this.postDateTimeTo.get('value'));
                // キーワード入力ダイアログを開く
                this.dateToDialog.show();
            },

            /**
             * 詳細検索条件欄の表示/非表示
             **/
            onShowQueryButtonClick: function(){
                domStyle.set(this.hideQueryLabel, 'display', '');
                domStyle.set(this.showQueryLabel, 'display', 'none');
                domStyle.set(this.filterPane, 'display', '');
            },

            onHideQueryButtonClick: function(){
                domStyle.set(this.hideQueryLabel, 'display', 'none');
                domStyle.set(this.showQueryLabel, 'display', '');
                domStyle.set(this.filterPane, 'display', 'none');
            },

            /**
             * コンテナをリサイズする。
             */
            containerResize: function(){
                // フォームの高さ固定を一旦解除
                this.form.set('style', {
                    height: null
                });
                // 検索条件ペインの高さ固定を一旦解除
                this.filterPane.set('style', {
                    height: null
                });
                this.mapPane.set('style', {
                    height: null
                });
                // コンテナをリサイズ
                this.container.resize();
                this.mapPane.resize();
            },

            isEmpty: function(str) {
                if (str === void 0) {
                    return true;
                }
                if (str === null) {
                    return true;
                }
                if(str === ''){
                    return true;
                }
                if(str === '0'){
                    return true;
                }
                return false;
            }
        });
});
