define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DrawDialogFileSavePage.html',
    'idis/view/_IdisWidgetBase',
    'idis/view/Loader',
    'dijit/Dialog',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/json',
    'dojo/topic',
    'dojo/dom-style',
    'idis/service/Requester',
    'idis/view/draw/_DrawUtil',
    'idis/view/dialog/InfoDialog',
    'app/consts/PUB_STATUS',
    'app/model/LayerStore',
    // 以下、変数で受けないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    '../view/form/LayerDirectorySelector',
    '../view/form/PubStatusSelector',
    '../view/form/ZoomLevelSelector'
], function(module, declare, template, _IdisWidgetBase, Loader, Dialog, lang, on, json, topic, domStyle,
     Requester, DrawUtil, InfoDialog, PUB_STATUS, LayerStore) {

    var content = declare('_DrawDialogFileSavePageContent', _IdisWidgetBase, {
        templateString : template,
        baseClass: 'drawDialog-Container',
        widgetsInTemplate : true
    });

    var container = declare(module.id.replace(/\//g, '.'), [Dialog], {

        // DrawPanelへのpub / sub
        RQST_SAVE   : '/app/draw/DrawPanel::drawRequestFromSaveDialog',
        RESP_SAVE   : '/app/draw/DrawPanel::drawResponseToSaveDialog',
        RM_EXPT_EVT : '/app/draw/DrawPanel::removeExportEvent',

        //RadioButtonのハンドリング
        _firstClick: true,
        // 自分の状態を管理
        _ActiveMode: 'local',
        //イベント破棄用
        _events: null,

        constructor : function(options){
            lang.mixin(this, options);
            this.title = 'ファイルの保存';
            this.inherited(arguments);
            this.inner = new content();
            this._events = [];
        },

        postCreate : function(){
            this.inherited(arguments);
            this.set('content', this.inner);
        },

        show: function(){
            this.inherited(arguments);
            this._init();
            //イベント定義
            this._setEvents();
        },

        _setEvents: function(){
            var saveSwitchEvt = on(this.inner.saveSwitch, 'click', lang.hitch(this, function(e){
                // Dialogの表示制御
                // if (!this._firstClick) {
                //     this._firstClick = true;
                //     return;
                // } else {
                    var target = e.target.value === undefined ?
                                 e.target.children[0].children[0].value :
                                 e.target.value;
                    // Local
                    if (target === '0') {
                        this.inner.toLocal.style.display = '';
                        this.inner.toServer1.style.display = 'none';
                        this.inner.toServer2.style.display = 'none';
                        this.inner.sip4dFlgArea.style.display = 'none';
                        this.inner.sip4dFlg.setValue(false);
                        domStyle.set(this.inner.saveDialogCommit1.domNode, 'display', 'none');
                        domStyle.set(this.inner.saveDialogCommit2.domNode, 'display', '');
                        this.inner.saveDialogCommit2.style.display = '';
                        this._ActiveMode = 'local';
                     //server
                    } else if (target === '1') {
                        this.inner.toLocal.style.display = 'none';
                        this.inner.toServer1.style.display = '';
                        this.inner.toServer2.style.display = '';
                        this.inner.sip4dFlgArea.style.display = '';
                        domStyle.set(this.inner.saveDialogCommit1.domNode, 'display', '');
                        domStyle.set(this.inner.saveDialogCommit2.domNode, 'display', 'none');
                        this._ActiveMode = 'server';
                    }
                //     this._firstClick = false;
                // }
            }));

            // ok
            var commit1Evt = on(this.inner.saveDialogCommit1, 'click', lang.hitch(this, function(){
                if(this.validate()){
                    // SaveDialogで行う処理はDrawPanelとのpub/subで結果を受け取ってから
                    // 拡張子によって処理する元データが異なるためDrawPanelにリクエストする必要がある。
                    topic.publish(this.RQST_SAVE, this.inner.fileExtension.get('value'));
                }
            }));
            var commit2Evt = on(this.inner.saveDialogCommit2, 'click', lang.hitch(this, function(){
                if(this.validate()){
                    topic.publish(this.RQST_SAVE, this.inner.fileExtension.get('value'));
                }
            }));

            // okが押されたときの処理
            var saveClickEvt = topic.subscribe(this.RESP_SAVE, lang.hitch(this, function(object){
                var fileName = this.inner.fileName.get('value');
                var dotIdx   = fileName.indexOf('.');
                if (dotIdx !== -1) {fileName = fileName.slice(0, dotIdx);}
                if (this._ActiveMode === 'local') {
                    var content = null;
                    var extension = '';
                    switch (this.inner.fileExtension.get('value')) {
                        case 'KML':// String
                            content = object;
                            extension = '.kml';
                            break;
                        case 'GeoJSON': // json
                            content = json.stringify(object);
                            extension = '.geojson';
                            break;
                    }
                    DrawUtil._doDownload(content, fileName, extension);
                    this.hide();
                } else {
                    var drawObj = {};
                    drawObj.parentLayerId = this.inner.parentLayerId.value;
                    drawObj.pubStatus = this.inner.pubStatus.get('value');
                    drawObj.minZoom   = this.inner.minZoom.get('value');
                    drawObj.layerName = fileName;
                    drawObj.tanjson   = [json.stringify(object)];
                    delete object.features[0].properties._borderColor;
                    drawObj.newFolder = '';
                    drawObj.sip4dFlg = this.inner.sip4dFlg.get('value');
                    if (drawObj.sip4dFlg === 'on' && !this.sip4dValidate(object)) {
                        return;
                    }
                    if (this.inner.newFolderFlg.value === 'on') {
                        drawObj.newFolder = this.inner.newFolder.value;
                    }

                    var promise = Requester.post('/api/draw/registerJson', {
                      data: drawObj
                    }).then(lang.hitch(this, function(data) {
                      console.log('success upload json');
                      LayerStore.refresh(data);
                      InfoDialog.show('完了', '登録完了しました。');
                      this.hide();
                    }), function(err) {
                      console.log(err);
                      InfoDialog.show('失敗', '作図ファイルの登録に失敗しました。<br>' + 'error: ' + err.message );
                    });
                    Loader.wait(promise);
                }
            }));

            // cancel
            var cancelEvt = on(this.inner.saveDialogCancel, 'click', lang.hitch(this, function(){
                this.hide();
            }));

            // ダイアログの「×」ボタン
            var cancelEvent02 = on(this.closeButtonNode, 'click', lang.hitch(this, function(){
                this._removeEvents();
            }));

            // ダイアログがクローズされたら当該作図オブジェクトに対するイベントを破棄
            var hideEvt = on(this, 'hide', lang.hitch(this, function(){
                this._events.push(hideEvt);
                this._removeEvents();
            }));

            //新規フォルダ作成にチェックされた場合
            var newFolderEvt = on(this.inner.newFolderFlg, 'change', lang.hitch(this, function(evt) {
              if(evt) {
                //チェックされた場合は新規フォルダ名入力欄を表示
                this.inner.newFolder.set('style', {'display': ''});
              } else {
                //チェックを外した場合は新規フォルダ名入力欄を非表示
                this.inner.newFolder.set('style', {'display': 'none'});
                this.inner.newFolder.set('value', '');
              }
            }));

            // ファイル形式でkmlが選ばれたら、注意書きを表示する。
            var kmlCautionEvt = on(this.inner.fileExtension, 'change', lang.hitch(this, function(){
                this.inner.drawSaveCaution.style.display = this.inner.fileExtension.value === 'KML'? '' : 'none';
            }));

            this._events.push(
                saveSwitchEvt, saveClickEvt, kmlCautionEvt,
                commit1Evt, commit2Evt, newFolderEvt,
                cancelEvt, cancelEvent02, hideEvt
            );

            // DrawPanelから呼び出されるイベント削除
            var removeEvt = topic.subscribe(this.RM_EXPT_EVT, lang.hitch(this, function(){
                this._removeEvents();
                removeEvt.remove();
            }));
        },

        _init: function(){
            // 開かれたときにlocal向けに初期化
            this.inner.saveSwitch.getChildren()[0].checked = true;
            this.inner.saveSwitch.getChildren()[1].checked = false;
            this.inner.toLocal.style.display = '';
            this.inner.toServer1.style.display = 'none';
            this.inner.toServer2.style.display = 'none';
            this.inner.sip4dFlgArea.style.display = 'none';
            this.inner.sip4dFlg.setValue(false);
            domStyle.set(this.inner.saveDialogCommit1.domNode, 'display', 'none');
            this._ActiveMode = 'local';
            this.inner.fileName.set('value', '');
            this.inner.fileExtension.set('value', '');
            // 公開範囲セレクトボックスを作成する
            this.inner.pubStatus.createSelector(PUB_STATUS.ALL);
            // defaultZoomLevelを8とする
            this.inner.minZoom.createSelector(8);
        },

        validate: function(){
            if (!this.inner.fileName.value) {
                InfoDialog.show('入力エラー', '報告名を入力してください。');
                return false;
            }
            if (this._ActiveMode === 'local') {
                if (!this.inner.fileExtension.value) {
                    InfoDialog.show('入力エラー', 'ファイル形式を入力してください。');
                    return false;
                }
            }
            if (this._ActiveMode === 'server') {
                if (!this.inner.parentLayerId.value) {
                    InfoDialog.show('入力エラー', '保存先を指定してください。');
                    return false;
                }
                if (this.inner.newFolderFlg.checked && this.inner.newFolder.value === '') {
                    InfoDialog.show('入力エラー', '新規フォルダ名を入力してください。');
                    return false;
                }
                if (!this.inner.pubStatus.value) {
                    InfoDialog.show('入力エラー', '公開範囲を選択してください。');
                    return false;
                }
            }
            return true;
        },

        _removeEvents: function(){
            this._events.forEach(function(event){
                event.remove();
            });
        },

        sip4dValidate: function(object){
            var validateFlg = true;
            var featureType = object.features[0].geometry.type;
            object.features.forEach(function(feature) {
                //作図の種別が複数の場合と円・付箋が作図されていたらはじく
                if (feature.geometry.type !== featureType ){
                    //線分と矢印の場合があるので条件追加
                    if( (feature.geometry.type === "LineString" && featureType === "MultiLineString") || (feature.geometry.type === "MultiLineString" && featureType === "LineString") ){
                        ;
                    } else { 
                        InfoDialog.show('入力エラー', 'SPI4Dに連携する際はアイコン、線、多角形は混ぜないでください。'); 
                        validateFlg = false;
                    }
                } else if ( validateFlg === true && (feature.properties._markerType  === "CircleMarker" || feature.properties._markerType  === "DivIcon"))  {
                    InfoDialog.show('入力エラー', '円と付箋はSPI4Dに連携できません。');
                    validateFlg = false;  
                } 
            });
            return validateFlg;
        }
    });

    return container;
});
