/**
* 被部隊活動画面用モジュール
* @module app/rescue/RescueDetailPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/text!./templates/RescueDetailPage.html',
    'dojo/topic',
    'dojo/window',
    'dojo/date/locale',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'leaflet',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/config',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/Loader',
    './_RescuePageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    'app/evacorder/EvacOrderMunicipalitySelector'
], function (module, LayerStore, declare, lang, ItemFileWriteStore, domClass, domConstruct, domGeometry,
    domStyle, json, on, iframe, template, topic, winUtils, locale, Rest, Menu, MenuItem, popup, TooltipDialog,
    registry, leaflet, DisasterInfo, Locator, Router, IdisMap, UserInfo, config, USER_TYPE, Requester, DialogChain,
    IdisDialog, DrawUtil, Loader, _RescuePageBase) {
    /**
    * 部隊活動新規登録画面。
    * @class RescueDetailPage
    * @extends module:app/rescue/_RescuePageBase~_RescuePageBase
    */
    return declare(module.id.replace(/\//g, '.'), _RescuePageBase,
        /** @lends module:app/rescue/RescueDetailPage~RescueDertailPage# */ {
            // テンプレート文字列
            templateString: template,
            store: null,
            /**
             * 災害ID
             */
            _disasterId: null,

            _rescueId: null,

            // 部隊種別
            _rescueType: null,

            // 続報番号一覧
            seqNumStore: null,

            // 続報番号
            _seqNum: null,

            constructor: function () {
                // ベースクラスのコンストラクタを呼ぶ
                this.inherited(arguments);

                this._rescueId = Locator.getQuery().rescueId;

            },

            /**
             * startup
             */
            startup: function () {
                console.debug('start startup');

                this.inherited(arguments);

                //this.initMap();

                // 選択中の災害ID取得
                this._disasterId = DisasterInfo.getDisasterId();
                if(!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }

                // 詳細画面を初期化
                this.initDetailPage(true);

                //続報セレクタに変更イベントを設定
                this.seqNumSelect.on('change', lang.hitch(this, function (evt) {
                    this.onSeqNumChange(evt);
                }));

                //this.addActivityArea();
            },

            /**
             * マップを初期化する。
             */
            initMapDetail: function (latitude, longitude) {
                if (latitude === null) {
                    latitude = config.map.latitude;
                }
                if (longitude === null) {
                    longitude = config.map.longitude;
                }
                var latlng = [latitude, longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    maxZoom: 18,
                    minZoom: 9,
                    drawControlTooltips: false
                }
                ).setView(latlng, 9);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);

                // this._subscribe();
                //
                // 生成したmapのlayerControlを画面にセットする。
                this._layerControl = this.map.layerControl;

                // 作図パネルを生成
                this.createDrawPanel();
                topic.publish(this.DRAW_INIT_ID, this.map);
                topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function (args) {
                    this.drawJson = args;
                }));
            },


            /**
             * 詳細画面を初期化する。
             */
            initDetailPage: function (isFirstTime) {
		        //被害情報レイヤーを非表示として初期化
                this.showMarkersFlg = false;
                // 市町村を取得
                var self = this;
                var promise = Requester.get('/api/rescue/' + this._rescueId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (data) {
                    // var item = data.items[0];
                    var item = data;
                    this.form.set('value', item);

                    this._rescueType = item.rescueType;
                    this._seqNum = item.seqNum;

                    this.admNum.innerHTML = item.admNum;
                    this.activeFlg=item.activeFlg;
                    //this.seqNum.innerHTML = item.seqNum;
                    this.rescueType.innerHTML = {
                        '1': '自衛隊',
                        '2': '警察',
                        '3': '消防',
                        '9': 'その他'
                    }[item.rescueType];

                    //取得日時はミリ秒に変換してから代入する
                    //変換前にYYYY/MM/DD hh:mm:ss形式にする
                    var reportReplace = new Date(item.reportTimestamp.replace(/-/g, '/'));
                    this.reportTimestamp.set('value', reportReplace.getTime());
                    var startReplace = new Date(item.activityStart.replace(/-/g, '/'));
                    this.activityStart.set('value', startReplace.getTime());
                    if (item.activityEnd) {
                        var endReplace = new Date(item.activityEnd.replace(/-/g, '/'));
                        this.activityEnd.set('value', endReplace.getTime());
                    }

                    // 地図情報の整形
                    //item.rescueGeoInfo = item.rescueGeoInfo.replace(/'/g, '"');
                    //this.geojson = json.parse(item.rescueGeoInfo);

                    //this.initMap();

                    this.attachFileList = [];
                    // 添付ファイルを設定
                    if (item.attachFiles) {
                        for (var i = 0; i < item.attachFiles.length; i++) {
                            self.attachFileList.push(item.attachFiles[i]);
                            self.showPreview(item.attachFiles[i], false);
                        }
                    }

                    //(初回のみ)続報リストを取得・続報セレクタにセット
                    if (isFirstTime) {
                        Requester.get('/api/rescue/' + item.admNum + '/rescueInfos', {
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function (data) {
                            console.log(data);
                            var selectData = [];
                            for (var i = 0; i < data.total; i++) {
                                selectData.push({
                                    rescueId: data.items[i].rescueId,
                                    seqNum: String(data.items[i].seqNum),
                                    seqNumStr: '第' + String(data.items[i].seqNum) + '報'
                                });
                            }

                            var selectContent = {
                                identifier: 'rescueId',
                                label: 'seqNumStr',
                                items: selectData
                            };

                            var rescueItemFileWriteStore = new ItemFileWriteStore({
                                data: selectContent
                            });
                            self.seqNumStore = rescueItemFileWriteStore;
                            self.seqNumSelect.set('sortByLabel', false);
                            self.seqNumSelect.set('store', rescueItemFileWriteStore);
                            self.seqNumSelect.set('value', self._rescueId);

                            // if(self.initSeqNumSelectFlg) {
                            //     self.seqNumSelect.on('change', function(evt) {
                            //         self.onSeqNumChange(evt);});
                            //     self.initSeqNumSelectFlg = false;
                            // }                            
                        }, function (err) {
                            console.log(err);
                        });
                    }
                    // 災害IDが選択中の災害と不一致の場合、更新不可エラーダイアログを表示
                    // （監視ページの別災害レイヤーのポップアップから遷移時発生）
                    if (Number(item.disasterId) !== Number(this._disasterId)) {
                        domStyle.set(this.deleteButton.domNode, 'display', 'none');
                        domStyle.set(this.updateButton.domNode, 'display', 'none');
                        domStyle.set(this.registerButton.domNode, 'display', 'none');
                        this.chain.info('現在選択中の災害に紐づかない情報のため、編集・更新ができません。<br>' +
                            'この情報を編集・更新する場合、監視ページに戻り、災害名を変更してください。', 'エラー');

                    // if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                    //     // 関係機関ユーザの場合は続投登録、訂正、削除ボタンを非表示にする
                    //     domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    //     domStyle.set(this.updateButton.domNode, 'display', 'none');
                    //     domStyle.set(this.registerButton.domNode, 'display', 'none');
                    } else {
                        // 関係機関ユーザ以外で、最新報でなければ続報登録、訂正、削除ボタンを非表示にする
                        if (item.activeFlg === '1') {
                            domStyle.set(this.deleteButton.domNode, 'display', '');
                            domStyle.set(this.updateButton.domNode, 'display', '');
                            domStyle.set(this.registerButton.domNode, 'display', '');
                        } else {
                            domStyle.set(this.deleteButton.domNode, 'display', 'none');
                            domStyle.set(this.updateButton.domNode, 'display', 'none');
                            domStyle.set(this.registerButton.domNode, 'display', 'none');
                        }
                    }

                    // 地図を初期化
                    self.initMap(data.actLatitude, data.actLongitude);
                    if(item.actLatitude && item.actLongitude){
                        self._rescueAddressPointLat = item.actLatitude;
                        self._rescueAddressPointLng = item.actLongitude;
                        self.addMark(item.actLatitude, item.actLongitude);
                    }

                    // 作図jsonの読み込み
                    // out/layer/data/[layerId]/rescue
                    var drawFileUrl = 'data/rescue/' + item.disasterId + '/' +
                        'draws-' + item.admNum + '-' + item.seqNum + '.geojson';
                    //テスト用
                    //var drawFileUrl = 'data/layer/data/10004/rescue.geojson';

                    Requester.get(drawFileUrl, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true

                    }).then(function (data) {
                        topic.publish(self.DRAW_BY_JSON_COLOR, data, item);

                    }, item);
                    //セレクタへの値のセットは毎回実施
                    self.seqNumSelect.set('value', self._rescueId);


                }), function (error) {
                    self.chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
            * 過去報を表示する。
            */
            onSeqNumChange: function (evt) {
                if (this._rescueId !== String(evt)) {
                    this._rescueId = String(evt);
                    this.initDetailPage(false);
                    //this.initDamageReportActionGrid();
                }
            },

            /**
             * 部隊活動の続報を登録する。
             */
            registerRescue: function () {
                var sendData = this.form.get('value');

                // 作図をJSON化する
                topic.publish(this.JSONIZE_RQST);

                if (!sendData.layerName) {
                    this.setLayerName(sendData);
                }

                if (sendData.activityArea || sendData.rescueAddress02) {
                    sendData.actLatitude = this._rescueAddressPointLat;
                    sendData.actLongitude = this._rescueAddressPointLng;
                } else {
                    sendData.actLatitude = '';
                    sendData.actLongitude = '';
                }

                sendData.disasterId = this._disasterId;
                sendData.rescueType = this._rescueType;

                // 続報登録の場合はrescueIdを渡す
                sendData.rescueId = this._rescueId;
                if (this._extAdmNum) {
                    sendData.extAdmNum = this._extAdmNum;
                }

                // if (this.fixFlg.value === '0') {
                //     sendData.fixFlg = '1';
                // } else {
                //     sendData.fixFlg = '0';
                // }

                // 詳細データをセット
                //this.cleanSendData(sendData); _DamageReportRegisterPageBase.jsにある
                //this.setJSONFromForm(sendData); _DamageReportRegisterPageBase.jsにある

                //添付ファイルIDをセット
                if (this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for (var i = 0; i < this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].rescueAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }

                delete sendData.attachFile;
                delete sendData.organizationName;

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm(sendData)) {
                    return false;
                }

                // // フォームのバリデーションを行う（市町村の入力）
                // if (!this.municipalityCd.get('value')) {
                //     return false;
                // }

                var jsonStr = json.stringify(sendData);

                var self = this;
                //登録確認ダイアログを表示
                this.chain.confirmAdd(function (chain) {
                    var promise = Requester.post('/api/rescue', {
                        data: jsonStr,
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function (data) {
                        console.debug('success register Rescue');
                        // 作図情報を登録
                        // var jsonData = [];
                        // jsonData.push(json.stringify(self.drawJson));
                        // console.log(jsonData);
                        var param = '?rescueId=' + data.rescueId;
                        return Requester.post('/api/rescue/uploadjson' + param, {
                            data: self.drawJson,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success upload json');
                            chain.infoComplete(function () {
                                //ツリーの更新
                                LayerStore.refreshAll();
                                // 一覧画面に移動
                                Router.moveTo('rescue', { rescueId: this._rescueId });
                            });
                        }, function (err) {
                            console.log(err);
                            chain.info('作図ファイルの登録に失敗しました。', 'エラー', function () {
                                // 一覧画面に移動
                                Router.moveTo('rescue', { rescueId: this._rescueId });
                            });
                        });
                    }, function (error) {
                        console.log('error register rescuerescue');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
                console.debug('[登録]ボタンがクリックされました。');
            },

            deleteRescue: function () {
                //削除確認ダイアログの表示
                this.chain.confirmDel(function (chain) {
                    var promise = Requester.del('/api/rescue/' + this._rescueId, {
                        query: {
                            version: this.form.value.version
                        }
                    }).then(function (data) {
                        console.log(data);
                        chain.infoComplete(function () {
                            // 一覧画面に移動
                            Router.moveTo('rescue');
                        });
                    }, function (error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });

                console.debug('[削除]ボタンがクリックされました。');
            },

            updateRescue: function () {
                var sendData = this.form.get('value');

                // 作図をJSON化する
                topic.publish(this.JSONIZE_RQST);

                if (!sendData.layerName) {
                    this.setLayerName(sendData);
                }

                if (sendData.activityArea || sendData.rescueAddress02) {
                    sendData.actLatitude = this._rescueAddressPointLat;
                    sendData.actLongitude = this._rescueAddressPointLng;
                } else {
                    sendData.actLatitude = '';
                    sendData.actLongitude = '';
                }

                sendData.disasterId = this._disasterId;
                sendData.rescueType = this._rescueType;
                //sendData.seqNum = this._seqNum;
                sendData.rescueId = this._rescueId;
                if (this._extAdmNum) {
                    sendData.extAdmNum = this._extAdmNum;
                }

                // if (this.fixFlg.value === '0') {
                //     sendData.fixFlg = '1';
                // } else {
                //     sendData.fixFlg = '0';
                // }

                // 詳細データをセット
                //this.cleanSendData(sendData); _DamageReportRegisterPageBase.jsにある
                //this.setJSONFromForm(sendData); _DamageReportRegisterPageBase.jsにある

                //添付ファイルIDをセット
                if (this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for (var i = 0; i < this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].rescueAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }

                delete sendData.attachFile;
                delete sendData.organizationName;

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm(sendData)) {
                    return false;
                }

                // // フォームのバリデーションを行う（市町村の入力）
                // if (!this.municipalityCd.get('value')) {
                //     return false;
                // }

                var jsonStr = json.stringify(sendData);

                var self = this;
                //登録確認ダイアログを表示
                this.chain.confirmAdd(function (chain) {
                    var promise = Requester.put('/api/rescue/' + this._rescueId, {
                        data: jsonStr,
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function (data) {
                        console.debug('success register Rescue');
                        // 作図情報を登録
                        // var jsonData = [];
                        // jsonData.push(json.stringify(self.drawJson));
                        // console.log(jsonData);
                        var param = '?rescueId=' + data.rescueId;
                        return Requester.post('/api/rescue/uploadjson' + param, {
                            data: self.drawJson,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function () {
                            console.debug('success upload json');
                            chain.infoComplete(function () {
                                //ツリーの更新
                                LayerStore.refreshAll();
                                // 一覧画面に移動
                                Router.moveTo('rescue', { rescueId: this._rescueId });
                            });
                        }, function (err) {
                            console.log(err);
                            chain.info('作図ファイルの登録に失敗しました。', 'エラー', function () {
                                // 一覧画面に移動
                                Router.moveTo('rescue', { rescueId: this._rescueId });
                            });
                        });
                    }, function (error) {
                        console.log('error register rescuerescue');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
                console.debug('[登録]ボタンがクリックされました。');
            },

            setLayerName: function (obj) {
                var currentDate = new Date();
                var prefix = locale.format(currentDate, {
                    selector: 'date',
                    formatLength: 'long',
                    locale: 'zh'
                });
                obj.layerName = prefix + '部隊';
            },

            /**
             * 活動エリアを表示する
             */
            addActivityArea: function () {
                var url = '/data/kumamoto.geojson';
                Requester.get(url, {
                    preventCache: false
                }).then(lang.hitch(this, function () {
                    var featureLayer = leaflet.geoJson(this.geojson);
                    featureLayer.addTo(this.map);
                }));
            }
        });
});
