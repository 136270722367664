/**
 * レイヤ属性情報の一覧表示を行う。
 * @module app/map/detail/LayerProp
 */
define([
    'app/config',
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/promise/all',
    'dstore/Memory',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'app/map/detail/consts/LayerPropColumnMap',
    'leaflet'
], function(config, module, array, declare, lang, all, Memory,
    GeoService, Requester, Loader, InfoDialog, LayerPropColumnMap, L) {
    // GeoServiceを初期化
    var _geoService = new GeoService({url: config.geocode && config.geocode.url});

    /**
     * TanJSONに変換した際に保持する属性名を示す接頭辞。
     * @private
     */
    var _KEEP_PREFIX = 'idis_';

    return declare(null, {
        /**
         * レイヤ属性データ格納用オブジェクト
         * @type {module:store/Memory}
         */
        layerPropStore: null,

        requestList: [],

        constructor: function() {
            this.layerPropStore = null;
            this.requestList = [];
        },

        /**
         * 受け取ったitem内のレイヤIDとレイヤURLを元に、Geojsonファイルを取得して、
         * 取得したファイル内の各Featureの「緯度経度」と「属性」をlayerPropStoreに格納する。
         *
         * ただし、全てのFeatureは同じ属性群を持つ前提とし、
         * 最初のFeatureにおける属性群のkeyと、
         * LayerPropColumnMap.PROP_TO_COLOMN_MAPで定義された辞書をマッチングして
         * layerPropStoreに格納する属性名を決定する。
         */
        initLayerPropStore: function(item) {
            this.layerPropStore = new Memory({
                data: []
            });
            // 受け取ったitem内のレイヤIDとレイヤURLを元に、Geojsonファイルを取得する。
            return Requester.get('/data/layer/data/' + item.id + '/' + item.layerUrl)
            .then(lang.hitch(this, function(data) {
                return all(this.createLayerPropRecords(data)).then(lang.hitch(this, function(result) {
                    result.forEach(lang.hitch(this, function(record) {
                        this.layerPropStore.addSync(record);
                    }));
                }));
            }), function(err) {
                console.log(err);
                InfoDialog.show('詳細情報', '表示データがありません。');
            });
        },

        createLayerPropRecords: function(data) {
            var promises = [];
            // 各Featureは同じ属性群を持つ前提で、一つ目のFeatureの属性の中から
            // LayerPropGridの各カラム名に対応する属性名を取得する。
            // 戻り値であるmappedPropertiesは、LayerPropGridの各カラム名(name, address, comment)に
            // 対応する、Geojsonの属性名を持つ。
            // GeojsonがLayerPropGridのカラム名「name」に対応する項目を持たない場合は、
            // 1から順に裁判した番号を「name」として付与する。
            var num = 0;
            var mappedProperties = this.mapProperties(data.features[0].properties);
            data.features.forEach(lang.hitch(this, function(feature) {
                var type = feature.geometry.type;
                var lat;
                var lng;

                if (lang.isArray(feature.geometry.coordinates[0])) {
                    // featureのgeometry属性が配列の場合は、その地物の中心座標を用いる。
                    // 「Polygon」と「Polyline」の場合に、この処理を行う想定。
                    var center = L.geoJson(feature).getBounds().getCenter();
                    lat = center.lat;
                    lng = center.lng;
                } else {
                    // featureのgeometry属性が配列でない場合は、その緯度経度を使う。
                    // 「Point」と「付箋(作図データ)」の場合に、この処理を行う想定。
                    lat = feature.geometry.coordinates[1];
                    lng = feature.geometry.coordinates[0];
                }

                if (!lat) {
                    lat = 'fuck';
                }

                var name = mappedProperties.name ? feature.properties[mappedProperties.name] : null;
                var comment = mappedProperties.comment ? feature.properties[mappedProperties.comment] : null;
                var address;

                if (mappedProperties.address &&
                    feature.properties.hasOwnProperty(mappedProperties.address) &&
                    feature.properties[mappedProperties.address]) {
                    // LayerPropGridのカラム「address」に相当する属性をGeojsonが持っており、その値が真値の場合
                    address = feature.properties[mappedProperties.address];
                    num++;
                    name = name ? name : num;
                    var record = {
                        lat: lat,
                        lng: lng,
                        type: type,
                        name: name,
                        address: address,
                        comment: comment
                    };
                    // 各Featureの「緯度経度」と「属性」をもつオブジェクト(record)を
                    // layerPropStoreに格納する
                    promises.push(record);
                } else {
                    // LayerPropGridのカラム「address」に相当する住所がないためreverseGeocodeして住所を取得する
                    promises.push(this.mapToAddress(lat, lng).always(lang.hitch(this, function(result) {
                        num++;
                        name = name ? name : num;
                        var record = {
                            lat: lat,
                            lng: lng,
                            type: type,
                            name: name,
                            address: result,
                            comment: comment
                        };
                        return record;
                    })));
                }
            }));
            return promises;
        },

        /**
         * 受け取った属性群の中から、LayerPropGridの各カラム名(name, address, comment)に対応する属性名を
         * 取り出して返す。
         * 属性名とカラム名は、LayerPropColumnMap.PROP_TO_COLOMN_MAPで対応づける。
         * 受け取った属性群の中で、'idis_'の接頭辞を持つものについては、'idis_'を外した上で対応関係を検証する。
         * 受け取った属性群の名称を小文字にした上で対応関係を検証する。
         */
        mapProperties: function(properties) {
            var result = {};
            for (var property in properties) {
                if (properties.hasOwnProperty(property)) {
                    var propertyOrg = property;
                    if (property.indexOf(_KEEP_PREFIX) === 0 &&
                    property.split(_KEEP_PREFIX).length > 1) {
                        property = property.split(_KEEP_PREFIX)[1];
                    }
                    if (!property) {
                        continue;
                    } else {
                        property = property.toLowerCase();
                    }
                    // 属性名に対応するカラム名を取得
                    var coloumn = LayerPropColumnMap.PROP_TO_COLOMN_MAP[property];

                    if (!coloumn) {
                        continue;
                    } else if (coloumn === 'name') {
                        result.name = propertyOrg;
                    } else if (coloumn === 'address') {
                        result.address = propertyOrg;
                    } else if (coloumn === 'comment') {
                        result.comment = propertyOrg;
                    }
                }
            }
            return result;
        },

        /**
         * 渡された緯度経度から住所を取得して返します。
         */
        mapToAddress: function(lat, lng) {
            var obj = _geoService.reverseGeocodeWithXHR(L.latLng({
                lat: lat,
                lng: lng
            }));
            this.requestList.push(obj.xhr);

            return obj.promise.then(lang.hitch(this, function(res) {
                return res.address.Address;
            }), lang.hitch(this, function() {
                // 失敗時はnullを返す
                return null;
            }));
        }
    });
});
