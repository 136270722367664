define([
    'module',
    'app/config',
    'app/map/legend/LegendPane',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/promise/all',
    'leaflet',
    'idis/control/Router',
    'idis/error/InvalidArgumentException',
    'idis/map/IdisMap',
    'idis/service/Requester',
    'idis/util/DateUtils',
    'idis/view/dialog/IdisDialog',
    'idis/view/page/_PageBase'
], function(module, config, LegendPane,
    array, declare, lang,
    aspect, dom, domStyle, all, L,
    Router, InvalidArgumentException, IdisMap, Requester, DateUtils,
    IdisDialog, _PageBase) {
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        /**
         * ベースレイヤーURL
        */
        // 市町村
        POLYGON_BASE_LAYER_URL: '/data/layer/data/lalert/area/data.geojson',
        // 火山
        POINT_BASE_LAYER_URL: '/data/master/observatory/volcano.geojson',

        /** 降灰予報レイヤーID */ 
        ASH_LAYER_ID: '63',

        /** 表示中レイヤーリスト */ 
        _layers: {},

        /**
         * 降灰予報レイヤーを表示
         */
        toggleAshLayer: function(items) {
            this.resetLayer(this.ASH_LAYER_ID);
            // 地区毎のマップを作成
            var map = {};
            array.forEach(items, function(data) {
                if (!map[data.areaICode]) {
                    map[data.areaICode] = [];
                }
                map[data.areaICode].push(data);
            });

            // レイヤーに必要な情報を取得
            var lalerts = array.map(Object.keys (map), function(areaCd) {
                var maxForecastCode;
                var maxEndTime;
                array.forEach(map[areaCd], function(data) {
                    if(!maxForecastCode && !maxEndTime ||
                        maxEndTime <= data.endTime &&
                        maxForecastCode <= data.vwCode) {
                            maxForecastCode = data.vwCode;
                            maxEndTime = data.endTime;
                    }
                });
                var warnings = array.map(map[areaCd], function(warning) {
                    return {
                        volcanoName: '三宅島',  // TODO: データから取得する（長崎は対象火山は１つなので一旦固定）
                        forecastCode: warning.vwCode,
                        forecast: warning.vwName,
                        endTime: DateUtils.format(warning.endTime)
                    };
                });
                return {
                    areasCode: areaCd,
                    maxForecastCode: maxForecastCode,
                    warnings: warnings
                };
            });
            var layerData = { lalerts : lalerts };
            // 火山別警報レイヤーを追加
            this.addGeoJSONLayerWithStatus(layerData,
                this.ASH_LAYER_ID, this.POLYGON_BASE_LAYER_URL);
        },

        /**
         * 処理名：状況データを付与したレイヤー追加:1つのGeoJSON
         * 処理概要：1つのGeoJSONで表される地物に対して状況データを組み合わせたレイヤーを追加する。
         *
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {String} geojsonUrl 地物を表すGeoJSONのURL
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addGeoJSONLayerWithStatus: function(layerData, layerId, baseLayerUrl) {
            return all({
                data: layerData,

                // ベースとなるgeojsonファイルはキャッシュを効かせる
                baseLayer: Requester.get(baseLayerUrl, {preventCache: false}),

                // layerInfoがstyleを持っている場合は取得
                style: this._getStyle(layerId)

            }).then(lang.hitch(this, function(result) {

                // styleオブジェクトに状況データを埋め込む
                if (result.data) {
                    result.style.geojsonOptions.status = result.data;
                }

                // そのままだと style()などで 'lAlert' が見えないので、bindしてthisをこのオブジェクトに向かせる
                if (result.style.geojsonOptions.style) {
                    result.style.geojsonOptions.style =
                        result.style.geojsonOptions.style.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.filter) {
                    result.style.geojsonOptions.filter =
                        result.style.geojsonOptions.filter.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.pointToLayer) {
                    result.style.geojsonOptions.pointToLayer =
                        result.style.geojsonOptions.pointToLayer.bind(result.style.geojsonOptions);
                }

                var options = result.style && result.style.geojsonOptions;
                var layer = L.geoJson(result.baseLayer, options);

                // 塗り潰しパターンを用いる場合はレイヤー追加・削除時に地図に反映する
                var patternData = options.patternData;
                if (patternData) {
                    // 関数の場合は引数なしで実行した結果を使う
                    if (lang.isFunction(patternData)) {
                        patternData = options.patternData();
                    }
                    // レイヤー追加時にパターンを追加
                    aspect.before(layer, 'onAdd', function(map) {
                        array.forEach(patternData, function(pattern) {
                            pattern.addTo(map);
                        });
                    });
                    // レイヤー削除時にパターンを除去
                    aspect.before(layer, 'onRemove', function(map) {
                        array.forEach(patternData, function(pattern) {
                            pattern.removeFrom(map);
                        });
                    });
                }

                layer.addTo(this.map);
                this._layers[layerId] = layer;
            }), function (params) {
                console.log(params);
            });
        },

        resetLayer: function(layerId) {
            if (this._layers[layerId]) {
                this.map.removeLayer(this._layers[layerId]);
            }
        },

        /**
         * 指定されたURLにあるstyle.jsを読み込み、結果をPromiseとして返す。
         * @param {string} url
         * @returns {Promise<Object>} スタイルオブジェクトを返すPromise
         */
        _getStyle: function(layerId) {
            var url = ['/data/layer/data/', layerId, '/style.js'].join('');
            // 引数チェック
            if (!url) {
                throw new InvalidArgumentException(module.id + '#_getStyle: urlが不正です: url="' + url + '"');
            }
            // 一旦テキスト形式で取得してから`eval`する
            return Requester.get(url, {
                handleAs: 'text'
            }).then(lang.hitch(this, function(data) {
                if (!data) {
                    throw new Error(module.id + '#_getStyle: style.jsの書式が不正です: url=' + url);
                }
                return eval('(' + data + ')'); //jshint ignore: line
            }));
        },

        /**
         * マップを初期化する。
         */
        initMap: function() {
            // mapの生成
            var latlng = [config.map.latitude, config.map.longitude];
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false,
                drawControlTooltips: false
            }).setView(latlng, 9);

            // destroy時にmapを破棄するよう設定
            this.own(this.map);
        },

        /**
         * 左Paneのレイアウトを調整する
         *
         * 左上のテーブルの内容は最初は空のため, heightが0になる
         * この状態で下のgridが描画されるのでコンテンツが重なってしまう
         * テーブル内容が 'setContent' で作られた後にテーブルの高さをContentPaneにセットして
         * 再配置するのがこのfunctionの目的
         */
        layout: function() {
            // tableの高さを測る
            var tableHeight = domStyle.get(dom.byId('HeaderTable'), 'height');

            // 高さをcontentpaneに伝える
            domStyle.set(this.headerTablePane, 'height', tableHeight);

            // 左側のBorderContainerを再配置
            this.leftPane.layout();
        },

        /**
         * 凡例ダイアログを表示する。
         */
        _toggleLegendDialog: function(layerIds) {
            if (!this._legendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._legendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new LegendPane({ layerIds: layerIds })
                });
                // 画面が破棄された際に連れて行く
                this.own(this._legendDialog);
            }
            if (this._legendDialog.open) {
                this._legendDialog.hide();
            } else {
                this._legendDialog.show();
            }
        },

        /*
         * 一覧ボタンが押されたときに呼び出される。
         * 一覧画面に遷移する。
        */
        onVolcanoPageLinkClick: function(evt) {
            evt.preventDefault();
            Router.moveTo('volcano');
        }
    });
});
