/**
 * マニュアルダウンロード画面用モジュール。
 * @module app/view/page/ManualDownloadPage
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/text!./templates/ManualDownloadPage.html',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function(module, declare, array, lang, template, domStyle, UserInfo, _PageBase) {
    /**
     * マニュアルダウンロード画面
     * @class ManualDownloadPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/view/page/stationsPage~stationsPage# */ {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        /**
         * マニュアルファイルPATH
         */
        FAIL_PATH: '/data/manual/',
        /**
         * DOMノードを生成する前の処理。
         */
        buildRendering: function() {
            this.inherited(arguments);
            // geojsonダウンロード用リンク集DOMノード生成
            // this.setLinkContainer();
            // 管理者向け資料の表示制御
            this.setDomStyle();
        },

        setDomStyle: function(){
            if(UserInfo.isSysAdmin()){
                domStyle.set(this.adminManualDom, 'display', '');
            } else {
                domStyle.set(this.adminManualDom, 'display', 'none');
            }
        },

        /**
         * geojsonダウンロード用リンク集のDOMノードを構築する。
         */
        setLinkContainer: function() {
            this.inherited(arguments);
            var html = [];
            array.forEach(this.linkList, function(linkGroup) {
                // 各リンクのグループ
                html.push('<section><h2>');
                html.push(linkGroup.name);
                html.push('</h2><div><ul>');
                array.forEach(linkGroup.children, function(item) {
                    // 各リンク要素
                    html.push('<li><a href="');
                    // IEの場合は固有の関数でダウンロード処理を実施
                    html.push(item.href);
                    html.push('" target="_blank">');
                    html.push(item.name);
                    html.push('</a></li>');
                });
                html.push('</ul></div></section>');
            }, this);
            this.linkContainer.innerHTML = html.join('');
        },

        /**
         * 管理者用マニュアルダウンロードボタンがクリックされた時の挙動。
         */
        onAdminDlButton: function() {
            var failname = 'Manual_for_Administrator.pdf';
            window.open(this.FAIL_PATH + failname, '総合防災情報システム操作マニュアル');
        },

        /**
         * 一般ユーザ用マニュアルダウンロードボタンがクリックされた時の挙動。
         */
        onAllUserDlButton: function() {
            var failname = 'Manual_for_User.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '総合防災情報システム操作マニュアル');
        },

        /**
         * 研修会動画ダウンロードボタンがクリックされた時の挙動。
         */
        onPlayVideoButton: function() {
            var failname = 'kenshu.mp4';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '総合防災情報システム操作研修会資料');
        },

        /**
         * システム操作研修会資料がクリックされた時の挙動。
         */
        onWarkshopDlButton: function() {
            var failname = 'bousai-guide.pdf';

            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '総合防災情報システム操作研修会資料');
        }

    });
});
