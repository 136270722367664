/**
 * 利用者更新用ダイアログモジュール。
 * @module app/user/UserDetailDialog
 */
define([
	'module',
	'leaflet',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/dom-style',
	'dojo/text!./templates/UserDetailDialog.html',
	'./_UserDialogBase',
	'idis/model/UserInfo',
	'idis/view/Loader',
	'idis/service/Requester',
	'app/config',
	'app/common/consts/FunctionCd',
	'idis/consts/ACL',
	'idis/service/GeoService',
	// 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
	'idis/view/form/WordCountTextarea'
], function (module, leaflet, declare, lang, domStyle, template, _UserDialogBase,
	UserInfo, Loader, Requester, config, FunctionCd, ACL, GeoService) {

	return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
		{
			// テンプレート文字列
			templateString: template,

			/**
			 * 緯度経度・座標変換用
			 * @type {Object}
			 */
			_geoService: null,

			constructor: function () {
				// GeoServiceを初期化
				this._geoService = new GeoService();
			},

			/**
			 * buildRendering
			 */
			buildRendering: function () {
				this.inherited(arguments);
				// 市町セレクタを「県なし」にセット
				this.municipalityCd.noPrefecture();
			},

			/**
			 * ロールコード
			 */
			_roleCd: null,

			/**
			 * ロール変更可否フラグ
			 */
			_isRoleCdFixed: null,

			/**
			 * 市町コード
			 */
			_municipalityCd: null,

			/**
			 * 振興局コード
			 */
			_regionCd: null,

			// バージョン
			_version: null,

			/**
			 * フォームを初期化する。
			 */
			initForm: function (object) {
				this._userId = object.userId;
				this._roleCd = null;
				this._municipalityCd = null;
				this._regionCd = null;
				var self = this;
				var page = this.form;
				this.initSelectBox()
					.then(lang.hitch(this, function () {
						return Requester.get('/api/users/' + this._userId);
					})).then(lang.hitch(this, function (data) {
						var item = data;
						delete item.userPassword;
						page.set('value', item);
						this._roleCd = item.roleCd;
						this._municipalityCd = item.municipalityCd;
						this._regionCd = item.regionCd;
						// this._facilityId = item.facilityId;
						// if (item.roleCd === UserInfo.ROLE_CD_SHELTER) {
						// 	this._facilityTypeId = '01';
						// }
						// if (item.roleCd === UserInfo.ROLE_CD_TMP_FACILITY) {
						// 	this._facilityTypeId = '04';
						// }
						// 更新対象ユーザがシステム管理者の場合、Select要素にシステム管理者のロールセット
						// （Select要素にシステム管理者のロールがないため）
						if (item.roleCd === 'R01001'){
							var newOptionsSysAdm = self.roleCdSelect.get('options');
								newOptionsSysAdm.push({
								label: 'システム管理',
								value: 'R01001'
							});
							self.roleCdSelect.set('options', newOptionsSysAdm);
						}
						self.roleCdSelect.set('value', item.roleCd);
						self._version = item.version;
					// }))
					// .then(lang.hitch(this, function () {
					// 	return this.buildFacilityOption();
					// }))
					// .then(lang.hitch(this, function () {
					// 	this.facilitySelect.set('value', this._facilityId);
					}));
				// システム管理者以外はログインユーザ・パスワードの領域および削除ボタンを非表示
				// if (UserInfo.getRoleCd() !== 'R01001') {
				// 	domStyle.set(this.userPasswordArea, 'display', 'none');
				// 	domStyle.set(this.deleteButton.domNode, 'display', 'none');
				// }
				// 物資調達の機能権限がない場合は物資ユーザ・パスワードの領域を非表示
				if (!UserInfo.hasAuthz(FunctionCd.BUSSHI)) {
					domStyle.set(this.supplyUserArea, 'display', 'none');
				}
			},

			/**
			 * 利用者を更新する。
			 */
			onSubmit: function () {
				try {
					console.debug('[更新]ボタンがクリックされました。');

					// フォームのバリデーションを実施
					if (!this.form.validate()) {
						return false;
					}

					// 固有のバリデーションを実施
					if (!this.validate()) {
						return false;
					}

					var value = this.form.get('value');
					value.userId = this._userId;

					// 利用者がシステム管理以外、または更新対象ユーザがシステム管理の場合
					// アカウント種類（役割）のSelect要素が非活性で画面から値を取得できないため、
					// 初期表示時に格納しておいた値を、ここでサーバ送信用項目に設定する
					if (this._isRoleCdFixed || this._roleCd ===  'R01001') {
						value.roleCd = this._roleCd;
					}

					// セレクタが非活性の場合、画面から値を取得できないため、格納しておいた値を設定
					value.municipalityCd = this._municipalityCd;
					value.regionCd = this._regionCd;

					value.version = this._version;

					delete value.passwordConfirm;

					this.emit('update', { value: value });
				} catch (e) {
					console.error(e);
				}
				return false;
			},


			onDelete: function () {
				console.debug('[削除]ボタンがクリックされました。');
				var value = this.form.get('value');
				value.userId = this._userId;
				value.version = this._version;
				this.emit('remove', { value: value });
			},

			/**
			 * ダイアログを初期化する。（更新時）
			 */
			initDialog: function (object) {
				this.reset();
				this.initForm(object);
				this.userId.innerHTML = this._userId;
				// 利用者が変更可能な場合はSelect要素、不可な場合は固定文字列を表示
				this._isRoleCdFixed = UserInfo.getRoleCd() !== 'R01001';
				this.roleCdSelect.set('disabled', this._isRoleCdFixed);
				this.municipalityCd.set('disabled', this._isRoleCdFixed);
				this.regionCd.set('disabled', this._isRoleCdFixed);
				// this.organizationSelector.set('disabled', this._isRoleCdFixed);
				this.organizationSelector.restoreRefresh();
			},

			// 役割セレクトボックスの初期化を行う
			initSelectBox: function () {
				var promise = Requester.get('/api/roles');
				return Loader.wait(promise).then(lang.hitch(this, function (data) {
					// セレクトボックスに設定するデータの配列を作成
					var newOptions = [];
					// サーバーレスポンスの各要素をselectへ追加
					if (UserInfo.getRoleCd() === ACL.ADMIN_USER){
						data.items.forEach(function (object) {
							newOptions.push({
								label: object.roleName,
								value: object.roleCd
							});
						});
					} else {
						// システム管理者以外の場合は、操作ユーザと同じuser_typeを選択肢にセット
						data.items.forEach(function (object) {
							if(object.userType === UserInfo.getUserType()){
								newOptions.push({
									label: object.roleName,
									value: object.roleCd
								});
							}
						});
					}
					this.roleCdSelect.set('options', newOptions);
				}));
			},

			// 役割のセレクトボックスの値が変更された場合の処理
			onRoleCdSelectChanged: function (roleCd) {
				// this.organizationSelector.restoreRefresh();
				var canRefresh = this.organizationSelector.canRefresh();
				if(this._roleCd){
					if(roleCd !== this._roleCd){
						this._roleCd = roleCd;
					}
				}
				if (roleCd.match(/^R01[0-9]*/)) {
					// 県ユーザ処理
					if (canRefresh) {
						this.organizationSelector.stopRefresh();
					}
					this.municipalityCd.set('value', config.municInfo.prefMunicCd);
					domStyle.set(this.municipalityCdBlock, 'display', 'none');
					this.regionCd.set('value', '');
					domStyle.set(this.regionCdBlock, 'display', 'none');
					if (canRefresh) {
						this.organizationSelector.restoreRefresh();
					}
				}
				else if (roleCd.match(/^R02[0-9]*/)) {
					// 振興局ユーザ処理
					if (canRefresh) {
						this.organizationSelector.stopRefresh();
					}
					this.municipalityCd.set('value', '');
					domStyle.set(this.municipalityCdBlock, 'display', 'none');
					domStyle.set(this.regionCdBlock, 'display', '');
					if (canRefresh) {
						this.organizationSelector.restoreRefresh();
					}

				}
				else if (roleCd.match(/^R03[0-9]*/)) {
					// 市町村ユーザ処理
					if (canRefresh) {
						this.organizationSelector.stopRefresh();
					}
					if (this.municipalityCd.get('value') === config.municInfo.prefMunicCd) {
						this.municipalityCd.set('value', '');
					}
					domStyle.set(this.municipalityCdBlock, 'display', '');
					this.regionCd.set('value', '');
					domStyle.set(this.regionCdBlock, 'display', 'none');
					if (canRefresh) {
						this.organizationSelector.restoreRefresh();
					}
				}
				else if (roleCd.match(/^R04[0-9]*/)) {
					// 関係機関ユーザ処理
					if (canRefresh) {
						this.organizationSelector.stopRefresh();
					}
					// （仮）関係機関のmunicipalityCdを設定
					this.municipalityCd.set('value', config.municInfo.prefMunicCd);
					// this.municipalityCd.set('value', '');
					domStyle.set(this.municipalityCdBlock, 'display', 'none');
					this.regionCd.set('value', '');
					domStyle.set(this.regionCdBlock, 'display', 'none');
					if (canRefresh) {
						this.organizationSelector.restoreRefresh();
					}
				}

				// 更新対象ユーザがシステム管理者の場合はSelect要素に固定文字列を表示
				if(this._roleCd === 'R01001'){
					this.roleCdSelect.set('disabled', true);
					this.municipalityCd.set('disabled', true);
					this.organizationSelector.set('disabled', true);
				}
				// if (roleCd === UserInfo.ROLE_CD_SHELTER) {
				// 	this._facilityTypeId = '01';
				// 	this.manageFacility.style.display = '';
				// } else if (roleCd === UserInfo.ROLE_CD_TMP_FACILITY) {
				// 	this._facilityTypeId = '04';
				// 	this.manageFacility.style.display = '';
				// } else {
				// 	this._facilityTypeId = null;
				// 	this.manageFacility.style.display = 'none';
				// }
				// this.buildFacilityOption();
			},

			// 市町村セレクタの値が変更するときの動作
			onMunicipalityCdChanged: function (municipalityCd){
				this._municipalityCd = municipalityCd.value;
				this.organizationSelector.setMunicipalityCd(municipalityCd.value);
			},
			// 振興局セレクタの値が変更するときの動作
			onRegionCdChanged: function(regionCd) {
				this._regionCd = regionCd.value;
				this.organizationSelector.setRegionCd(regionCd.value);
			}
		});
});
